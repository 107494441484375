import client from "./client";

const endpoint = '/companies';
const newEndpoint = '/companies_new';
const roiEndpoint = '/companies_roi';

const getCompanies = () => {
    return client.get(newEndpoint)
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            }
        });
}

const getCompany = (client_id) => {
    return client.get(endpoint + '/' + client_id)
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            } else if (error.response.status == 402) {
                window.location = '/subscribe';
            }
        });
}

const deleteCompany = (client_id) => client.delete(endpoint + '/' + client_id)
    .catch(function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            return error.response;
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
        } else {
            // Something happened in setting up the request that triggered an Error
        }
    });

const addCompany = (theClient) => {
    return client.post(endpoint, JSON.stringify(theClient))
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            }
        });
};

const updateCompany = (id, theClient) => {
    return client.put(endpoint + '/' + id, JSON.stringify(theClient))
        .then((response) => {
            console.log(response)
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            } else if (error.response.status == 402) {
                window.location = '/subscribe';
            }
        });
};

const getCompaniesCount = () => {
    return client.get(endpoint  + '/count')
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            }
        });
}

const getROICompanies = () => {
    return client.get(roiEndpoint)
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            }
        });
}

export default {
    getCompanies,
    getCompany,
    deleteCompany,
    addCompany,
    updateCompany,
    getCompaniesCount,
    getROICompanies
};
