import React, {useState, useEffect} from 'react';
import {
    MDBTable,
    MDBTableBody,
    MDBBtn,
    MDBIcon,
    MDBContainer,
    MDBTooltip,
    MDBProgress,
    MDBProgressBar, MDBCol, MDBRow,
} from 'mdb-react-ui-kit';
import _ from 'lodash';
import {useHistory, useLocation} from "react-router-dom";
import {
    GroupingState,
    IntegratedGrouping,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableGroupRow,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import '@icon/open-iconic/open-iconic.css';

import teamsApi from '../api/teams'
import PageHeader from "../components/PageHeader";
import NavBar from "../components/navigation/NavBar";

const Teams = () => {

    const user = JSON.parse(localStorage.getItem('user'));

    const [isLoading, setIsLoading] = useState(false);

    const [teams, setTeams] = useState({
        columns: [
            { title: 'Company', name: 'company_name' },
            { title: 'Team', name: 'team_name' },
            { title: 'ID', name: 'id' },
            { title: 'Description', name: 'description' },
            { title: 'Status', name: 'status', sort: true },
            { title: 'Participants', name: 'participants' },
            { title: 'Surveys', name: 'surveys' },
            { title: 'Reports Available', name: 'reports' },
        ],
        rows: [],
    });

    const [tableColumnExtensions] = useState([
        { columnName: 'team_name', wordWrapEnabled: true, width: "auto" },
        { columnName: 'description', wordWrapEnabled: true, width: "auto" },
        { columnName: 'participants', wordWrapEnabled: true, width: 200 },
        { columnName: 'surveys', wordWrapEnabled: true, width: 200 },
        { columnName: 'reports', wordWrapEnabled: true, width: 220 },
    ]);
    const [defaultHiddenColumnNames] = useState(['id', 'status']);

    const [companies, setCompanies] = useState([]);

    // history stack
    const history = useHistory()
    const queryPage = useLocation().search.match(/page=([0-9]+)/, '')
    const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1)
    const [page, setPage] = useState(currentPage)

    let expandedColumns = [];

    useEffect(() => {
        loadTeams();
    }, []);

    const loadTeams = async () => {
        const response = await teamsApi.getTeams();
        let teamsData = _.orderBy(_.sortBy(_.sortBy(response.data.teams, [function(o) { return o.company_name; }]), [function(o) { return o.name; }]), [function(o) { return o.status; }], ['desc']);

        let tmpCompanies = [];

        _.each( teamsData, ( team, key ) => {

            tmpCompanies.push(team.company_name);
            // setCompanies(companies => [...companies, participant.company_name]);

            let teamsCol = [];
            let surveysCol = [];
            let reportsCol = [];

                teamsCol.push(
                    <>
                        <MDBRow>
                            <MDBCol size={9} className='mb-2'><p>Team Members: </p></MDBCol>
                            <MDBCol size={3} className='mb-2'><p>{team.team_member_count}</p></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size={9} className='mb-2'><p>Team Leaders: </p></MDBCol>
                            <MDBCol size={3} className='mb-2'><p>{team.team_leader_count}</p></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size={9} className='mb-2'><p>Stakeholders: </p></MDBCol>
                            <MDBCol size={3} className='mb-2'><p>{team.stakeholder_count}</p></MDBCol>
                        </MDBRow>
                    </>
                );
                surveysCol.push(
                    <>
                        <MDBRow>
                            <MDBCol size={9} className='mb-2'><p>Built, not sent: </p></MDBCol>
                            <MDBCol size={3} className='mb-2'><p>{team.not_sent_survey_count}</p></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size={9} className='mb-2'><p>In progress: </p></MDBCol>
                            <MDBCol size={3} className='mb-2'><p>{team.in_progress_survey_count}</p></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size={9} className='mb-2'><p>Completed: </p></MDBCol>
                            <MDBCol size={3} className='mb-2'><p>{team.completed_survey_count}</p></MDBCol>
                        </MDBRow>
                    </>
                );
                reportsCol.push(
                    <>
                        <MDBRow>
                            <MDBCol size={9} className='mb-2'><p>Diagnostic Reports: </p></MDBCol>
                            <MDBCol size={3} className='mb-2'><p>{team.diagnostic_report_count}</p></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size={9} className='mb-2'><p>Team Meeting Presentations: </p></MDBCol>
                            <MDBCol size={3} className='mb-2'><p>{team.tmp_report_count}</p></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size={9} className='mb-2'><p>Stakeholder & Team Overview: </p></MDBCol>
                            <MDBCol size={3} className='mb-2'><p>{team.stakeholder_report_count}</p></MDBCol>
                        </MDBRow>
                        <MDBRow>
                            <MDBCol size={9} className='mb-2'><p>Engagement Review: </p></MDBCol>
                            <MDBCol size={3} className='mb-2'><p>{team.engagement_report_count}</p></MDBCol>
                        </MDBRow>
                    </>
                );

            setTeams((teams) => ({
                columns: teams.columns,
                rows: [
                    ...teams.rows,
                    {
                        company_name: team.company_name,
                        team_name: <p>{team.name}</p>,
                        id: team.id,
                        description: team.description,
                        status: team.status,
                        participants:teamsCol,
                        surveys: surveysCol,
                        reports: reportsCol,
                    }
                ]
            })
            );

            expandedColumns.push(team.company_name);
        } );

        setCompanies(_.sortBy(_.uniq(tmpCompanies), [function(o) { return o; }]));
    }

    useEffect(() => {
        console.log(expandedColumns);
    }, [expandedColumns]);


    const TableRow = ({ row, ...restProps }) => (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            onClick={() => row.status>0 && history.push(`/teams/${row.id}`)}
            style={{
                cursor: 'pointer',
            }}
            className={row.status===0 ? "inactive" : undefined}
        />
    );

    const ParticipantsCell = ({ value, style, ...restProps }) => (
        <Table.Cell
            {...restProps}
        >
            <div
                style={{
                    fontWeight: 400,
                    lineHeight: 1,
                    whiteSpace: "pre-wrap",
                    ...style,
                }}
            >{value}</div>
        </Table.Cell>
    );

    const Cell = (props) => {
        const { column } = props;
        if (column.name === 'participants') {
            return <ParticipantsCell {...props} />;
        } else if (column.name === 'surveys') {
            return <ParticipantsCell {...props} />;
        } else if (column.name === 'reports') {
            return <ParticipantsCell {...props} />;
        }
        return <Table.Cell {...props} />;
    };

    return (
        <>
            <div className='text-left'>
                <NavBar context="teams" />
                <PageHeader title='Teams' />
                <MDBContainer className="px-5 mt-5">
                    <div className='team'>
                        {expandedColumns &&
                        <Grid
                            rows={teams.rows}
                            columns={teams.columns}
                        >
                                <GroupingState
                                    grouping={[
                                        {columnName: 'company_name'},
                                    ]}
                                    defaultExpandedGroups={expandedColumns}
                                />
                            <IntegratedGrouping />
                            <Table
                                rowComponent={TableRow}
                                columnExtensions={tableColumnExtensions}
                                cellComponent={Cell}
                            />
                            <TableHeaderRow />
                            <TableColumnVisibility
                                defaultHiddenColumnNames={defaultHiddenColumnNames}
                            />
                            <TableGroupRow
                                showColumnsWhenGrouped={false}
                                className='team'
                            />
                        </Grid>
                        }

                    </div>
                    {/*<MDBDatatable*/}
                    {/*    data={participants}*/}
                    {/*    onRowClick={(row) => history.push(`/participants/${row.id}`)}*/}
                    {/*    isLoading={isLoading}*/}
                    {/*    className="team"*/}
                    {/*/>*/}
                    <div className="d-flex justify-content-end add-button">
                        <MDBTooltip tag='span' wrapperClass='d-inline-block px-2' placement='left' title='Add a team'>
                            <MDBBtn floating tag='a' href='/teams/add'>
                                <MDBIcon fas icon="plus team" />
                            </MDBBtn>
                        </MDBTooltip>
                    </div>
                </MDBContainer>
            </div>
        </>
    );
}

export default Teams;
