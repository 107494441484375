import React, {useEffect, useState} from 'react';
import {
    MDBContainer,
    MDBInputGroup,
    MDBIcon,
    MDBBtn,
    MDBSwitch,
    MDBCheckbox,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import md5 from "md5";

import authApi from "../api/auth"
import { ReactComponent as Logo } from '../assets/img/ccmi-graphic-logo-color.svg';
import CountriesDropdown from "../components/CountriesDropdown";


function equalTo(ref, msg) {
    return Yup.mixed().test({
        name: 'equalTo',
        exclusive: false,
        message: msg || '${path} must be the same as ${reference}',
        params: {
            reference: ref.path,
        },
        test: function(value) {
            return value === this.resolve(ref);
        },
    });
}
Yup.addMethod(Yup.string, 'equalTo', equalTo);

const loginSchema = Yup.object().shape({
    firstname: Yup.string().required("Enter your first name"),
    lastname: Yup.string().required("Enter your last name"),
    email: Yup.string().email().required("Enter your email address"),
    password: Yup.string().required("Enter your password"),
    passwordConfirmation: Yup.string().equalTo(Yup.ref('password'), 'Passwords must match').required('Required'),
    access_token: Yup.string().required("Enter a valid access token"),
    processing_consent: Yup.bool().oneOf([true],"You must consent in order to use the Perill service").required("required"),
    coaching_org: Yup.string().required("Please provide the name of your coaching organisation"),
    country: Yup.string().required("Please select the primary country in which you work"),
});

const Register = () => {

    const initialValues = {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        passwordConfirmation: "",
        access_token: "",
        processing_consent: 1,
        marketing_consent: 0,
        coaching_org: "",
        country: "",
    };
    const md5=require("md5");

    const [topModal, setTopModal] = useState(false);
    const toggleShow = () => setTopModal(!topModal);

    const [errorModal, setErrorModal] = useState(false);
    const toggleShowError = () => setErrorModal(!errorModal);

    const goToLogin = () => {
        window.location.href = "/login";
    }

    // const validateSwitch = (e: any) => {
    //     values.processing_consent = (values.processing_consent === true) ? 1 : 0;
    //     values.marketing_consent = (values.marketing_consent === true) ? 1 : 0;
    // };

    useEffect(() => {

    }, []);

    return (
        <>
            <div className="container flex-column">
                <div className="row justify-content-center">
                    <div className="col-xl-5 col-md-8">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={loginSchema}
                            onSubmit={ async (values) => {
                                values.password = md5(values.password);
                                values.passwordConfirmation = null;
                                // values.processing_consent = (values.processing_consent === true) ? 1 : 0;
                                // values.marketing_consent = (values.marketing_consent === true) ? 1 : 0;
                                const response = await authApi.registerUser(values);
                                if (response.status === 200) {
                                    toggleShow();
                                } else if (response.status === 400) {
                                    values.password = '';
                                    values.passwordConfirmation = '';
                                    toggleShowError();
                                }
                            }}
                        >
                            {(formik) => {
                                const { errors, touched, isValid, dirty } = formik;
                                return (
                                    <Form
                                        // className="bg-white  rounded-5 shadow-5-strong login-register-form"
                                    >
                                        <div className="login-register-header">
                                            <a href='https://clutterbuck-cmi.com/' target='_blank'>
                                                <Logo style={{height: '5rem', width: '5rem'}}/>
                                            </a>
                                            <h1>Create account</h1>
                                            <p className="">Sign up to get started on your PERILL journey.</p>
                                        </div>
                                        {/*<div className="modal-divider"></div>*/}
                                        <div className="p-5">
                                            {/*<MDBInputGroup textBefore={<MDBIcon fas icon="signature" />} className="mb-4">*/}
                                                <Field
                                                    type="text"
                                                    placeholder="First name"
                                                    autoComplete=""
                                                    id="firstname"
                                                    name="firstname"
                                                    className={
                                                        errors.firstname && touched.firstname ? "form-control mb-3 is-invalid" : "form-control mb-3"
                                                    }
                                                    onBlur={() => formik.setFieldTouched('firstname')}
                                                />
                                                <ErrorMessage name="firstname" component="span" className="error" />
                                                <Field
                                                    type="text"
                                                    placeholder="Last name"
                                                    autoComplete=""
                                                    id="lastname"
                                                    name="lastname"
                                                    className={
                                                        errors.lastname && touched.lastname ? "form-control mb-3 is-invalid" : "form-control mb-3"
                                                    }
                                                    onBlur={() => formik.setFieldTouched('lastname')}
                                                />
                                                <ErrorMessage name="lastname" component="span" className="error" />
                                            {/*</MDBInputGroup>*/}
                                            {/*<MDBInputGroup textBefore={<MDBIcon fas icon="at" />} className="mb-3">*/}
                                                <Field
                                                    type="text"
                                                    placeholder="Your email address"
                                                    autoComplete="email"
                                                    id="email"
                                                    name="email"
                                                    className={
                                                        errors.email && touched.email ? "form-control mb-3 is-invalid" : "form-control mb-3"
                                                    }
                                                    onBlur={() => formik.setFieldTouched('email')}
                                                />
                                                <ErrorMessage name="email" component="span" className="error" />
                                            {/*</MDBInputGroup>*/}
                                            {/*<MDBInputGroup textBefore={<MDBIcon fas icon="key" />} className="mb-4">*/}
                                                <Field
                                                    type="password"
                                                    placeholder="Password"
                                                    autoComplete="current-password"
                                                    id="password"
                                                    name="password"
                                                    className={
                                                        errors.password && touched.password ? "form-control mb-3 is-invalid" : "form-control mb-3"
                                                    }
                                                    onBlur={() => formik.setFieldTouched('password')}
                                                />
                                                <ErrorMessage name="password" component="span" className="error" />
                                                <Field
                                                    type="password"
                                                    placeholder="Confirm Password"
                                                    id="passwordConfirmation"
                                                    name="passwordConfirmation"
                                                    className={
                                                        errors.passwordConfirmation && touched.passwordConfirmation ? "form-control mb-3 is-invalid" : "form-control mb-3"
                                                    }
                                                    onBlur={() => formik.setFieldTouched('passwordConfirmation')}
                                                />
                                                <ErrorMessage name="passwordConfirmation" component="span" className="error" />
                                            {/*</MDBInputGroup>*/}
                                            {/*<MDBInputGroup textBefore={<MDBIcon fas icon="lock" />} className="mb-3">*/}
                                                <Field
                                                    type="text"
                                                    placeholder="Access Token"
                                                    id="access_token"
                                                    name="access_token"
                                                    className={
                                                        errors.access_code && touched.access_code ? "form-control mb-3 is-invalid" : "form-control mb-3"
                                                    }
                                                    onBlur={() => formik.setFieldTouched('access_code')}
                                                />
                                                <ErrorMessage name="access_code" component="span" className="error" />
                                            {/*</MDBInputGroup>*/}
                                            <hr className='mt-4'/>

                                            <MDBInputGroup className="mb-4">
                                                <MDBSwitch
                                                    defaultChecked
                                                    id='processing_consent'
                                                    name='processing_consent'
                                                    label={<span>I have read and accepted the CCMI's <a href="https://clutterbuck-cmi.com/terms-of-use/" target='_blank'>Terms of Use</a> and <a href="https://perill.clutterbuckdiagnosticsforcoaches.com/privacy" target='_blank'>Privacy Policy</a>.</span>}
                                                    onChange={(e) => {
                                                        formik.values.processing_consent = ((e.target.checked === true) ? 1 : 0);
                                                        e.target.checked = ((formik.values.processing_consent) ? true : false);
                                                        formik.setFieldTouched('processing_consent');
                                                    }}
                                                    onBlur={() => formik.setFieldTouched('processing_consent')}
                                                />
                                                <ErrorMessage name="processing_consent" component="div" className="error" />
                                                <MDBSwitch
                                                    id='marketing_consent'
                                                    name='marketing_consent'
                                                    label={<span>Get the latest news, blogs and thought leadership on Coaching, Mentoring and Leadership from Professor David Clutterbuck and the <a href="https://clutterbuck-cmi.com/" target='_blank'>CCMI Network</a>. Delivered directly to you via email every few months.</span>}
                                                    onChange={(e) => {
                                                        formik.values.marketing_consent = ((e.target.checked === true) ? 1 : 0);
                                                        e.target.checked = ((formik.values.marketing_consent) ? true : false);
                                                        formik.setFieldTouched('marketing_consent');
                                                    }}
                                                    onBlur={() => formik.setFieldTouched('marketing_consent')}
                                                />
                                            </MDBInputGroup>

                                            <hr className='mt-4'/>
                                            {/*<MDBInputGroup textBefore={<MDBIcon far icon="building" />} className="mb-3">*/}
                                                <Field
                                                    type="text"
                                                    id="coaching_org"
                                                    name="coaching_org"
                                                    placeholder="Coaching Organisation"
                                                    className={
                                                        errors.name && touched.name ? "mb-3 form-control is-invalid" : "mb-3 form-control"
                                                    }
                                                />
                                            {/*</MDBInputGroup>*/}
                                            <ErrorMessage name="coaching_org" component="span" className="error mb-3" />
                                            <Field
                                                as="select"
                                                name="country"
                                                id="country"
                                                placeholder="Primary country in which you work"
                                                className='mt-5 mb-3'
                                                selected_country={"GB"}
                                                component={CountriesDropdown}
                                            />
                                            <MDBBtn
                                                type="submit"
                                                className={!(dirty && isValid) ? "formButton disabled-btn" : "formButton"}
                                                disabled={!(dirty && isValid)}
                                            >Register</MDBBtn>
                                            <div className="login-register-footer">
                                                <p>Already have an account with CCMI? <a href='/login/'>Sign in here</a>.</p>
                                                <p>CCMI <a href='https://perill.clutterbuckdiagnosticsforcoaches.com/privacy' target='_blank'>Privacy</a> Policy</p>
                                                <a href='https://docs.google.com/document/d/1ZDBnQgV3Ahkh8k1OChqrTf3MvYT83XyS4RpFWHFdEoM' target='_blank'>FAQ's / Self Help Guide</a>
                                            </div>
                                        </div>
                                    </Form>
                                );
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
            <MDBModal
                animationDirection='top'
                show={topModal}
                tabIndex='-1'
                // setShow={setTopModal}
                staticBackdrop
            >
                <MDBModalDialog centered size="sm">
                    <MDBModalContent>
                        <MDBModalBody className='p-4'>
                            <div className='d-flex flex-column justify-content-center align-items-center text-center my-3'>
                                <Logo style={{ height: '5rem', width: '5rem' }} className="mb-3" />
                                <h1 className="mb-3">Thanks for signing up to<br />PERILL DIAGNOSTICS FOR TEAMS.</h1>
                                <p>Please check your inbox (and junk mail folder). CCMI has sent an email to verify your email address.
                                    You’ll need to do that before you can log in.</p>
                                {/*<p className='mb-4'>If you have not received this email or would like this email resent, <a href="#">click here</a>.</p>*/}
                                <MDBBtn size='sm' className='ms-2 p1' onClick={goToLogin}>
                                    Continue to Login
                                </MDBBtn>
                            </div>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
            <MDBModal
                animationDirection='top'
                show={errorModal}
                tabIndex='-1'
                // setShow={setTopModal}
                staticBackdrop
            >
                <MDBModalDialog centered size="sm">
                    <MDBModalContent>
                        <MDBModalBody className='p-4'>
                            <div className='d-flex flex-column justify-content-center align-items-center text-center my-3'>
                                <Logo style={{ height: '5rem', width: '5rem' }} className="mb-3" />
                                <h3 className="mb-3">We are unable to register this account. Some common reasons are:</h3>
                                <p>The PERILL app is in BETA and by invite only. Your access code may be invalid or has already been used.</p>
                                <p>This account already exists but you have not yet verified your email address.
                                    Please check your inbox and junk folder for an email from CCMI (@clutterbuck-cmi.com),
                                    and where appropriate add this to your safe list.
                                    {/*Click here to request the verification email again (LINK).*/}
                                </p>
                                <p>If you're still experiencing issues please email us at <a href='mailto:diagnostics@clutterbuck-cmi.com'>diagnostics@clutterbuck-cmi.com</a>,
                                    from the email account you're trying to register with.</p>
                                <MDBBtn size='sm' className='ms-2 p1' onClick={toggleShowError}>
                                    Ok
                                </MDBBtn>
                            </div>
                        </MDBModalBody>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}

export default Register;
