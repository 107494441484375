import axios from 'axios';
import authHeader from './auth-header';

const baseUrl = process.env.REACT_APP_API_URL;

export default axios.create({
    baseURL: baseUrl,
    headers:
        authHeader()
});
