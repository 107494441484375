import React, { useState, useRef, useEffect } from 'react';
import { MDBRating, MDBRatingElement } from 'mdb-react-ui-kit';

import {useFormikContext, useField} from "formik";

export default function ScoreSelector(props) {

    const {
        id,
        range,
        initialValue,
        label,
        color,
        size,
    } = props;

    const { errors, setFieldValue, touched, values } = useFormikContext();

    const [eventValue, setEventValue] = useState(initialValue);

    const handleChange = async (e) => {
        // console.log(id);
    };

    useEffect(() => {
        setFieldValue(id, eventValue);
        setFieldValue(stepCount => ({...stepCount, id }))
        sessionStorage.setItem("answers", values);
    }, [eventValue]);

    const createRange = () => {
        let content = [];
        for (let i = 0; i < range; i++) {
            content.push(
                <MDBRatingElement
                    key={i+1}
                    itemId={i+1}
                    icon='star'
                    onClick={handleChange}
                    color={color}
                    size={size}
                />
            );
        }
        return content;
    };

    return (
        <>
        <p className='score-selector-label' style={{color: color}}>{label}</p>
        <MDBRating
            defaultValue={eventValue}
            onChange={(value) => {
                setEventValue(value);
            }}
        >
            {createRange()}
        </MDBRating>
        </>
    );
}
