import React, {useState, useEffect} from 'react';
import _ from 'lodash';
import {useFormikContext} from "formik";
import Select from 'react-select';

import countries from "../assets/json/countries.json"

const CountriesDropdown = (props) => {

    const { errors, setFieldValue, touched, values } = useFormikContext();

    const {selected_country} = props;

    const [currentCountry, setCurrentCountry] = useState([]);

    const countryObj = countries.countries;
    const countryArr = Object.entries(countryObj).map(([key, value]) => {
        return {
            label: value,
            value: key
        };
    });

    useEffect(() => {
        populateSelect();
    }, []);

    const populateSelect = async () =>  {
        _.each( countryArr, ( country, key ) => {
            if (country.value === selected_country) {
                setFieldValue('country', country.value);
                setCurrentCountry(country);
            }
        });
    }

    const handleChange = (e) => {
        e && setFieldValue('country', e.value);
    };

    return (
        // teamOptions &&
        <div key={currentCountry && currentCountry} >
        <Select
            options={countryArr}
            className='selectable mb-3'
            placeholder='Select a country'
            defaultValue={currentCountry}
            formatOptionLabel={option => <div className="needsclick">{option.label}</div>}
            // onBlur={handleBlur}
            onChange={handleChange}
        />
        </div>
    );
}

export default CountriesDropdown
