import client from "./client";

const endpoint = '/dashboard';
const newEndpoint = '/companies_new';


//const getDashboardCompanies = () => client.get(endpoint);

const getDashboardCompanies = () => {
    return client.get(newEndpoint)
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                localStorage.removeItem("user");
                window.location = '/login';
            }
        });
};

export default {
    getDashboardCompanies,
};


