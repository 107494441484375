import React, {useEffect, useState, useRef} from 'react';
import {
    MDBRow,
    MDBCol,
    MDBStepper,
    MDBStepperForm,
    MDBStepperStep,
    MDBBtn,
    MDBToast,
    MDBAlert,
    MDBIcon,
    MDBValidationItem,
    MDBInput,
} from 'mdb-react-ui-kit';
import {ErrorMessage, Field, Form, useFormik, FormikProvider} from "formik";
import _ from "lodash";
import Lottie from "lottie-react";

import ScoreSelector from "../components/ScoreSelector"
import ScoreRangeSelector from "./ScoreRangeSelector";
import md5 from "md5";
import questionnaireApi from "../api/questionnaire";
import {ReactComponent as Logo} from "../assets/img/ccmi-graphic-logo-color.svg";
import teamsApi from "../api/teams";
import DropZone from "./DropZone";
import colors from "../config/colors";
import Animation from "../assets/lottie/89255-report.json";

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // An function that increment 👆🏻 the previous state like here
    // is better than directly setting `value + 1`
}

export default function QuestionnaireStepper(props) {

    const forceUpdate = useForceUpdate();

    const responses_data = JSON.parse(sessionStorage.getItem('responses'));

    const uuid = props.uuid;
    const setSurveyComplete = props.setSurveyComplete;
    const questionTotal = props.questionTotal;

    //toasts
    const wrapperRef = useRef(null);
    const triggerContainer = useRef(null);
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    const [pillars, setPillars] = useState([
        'Purpose and Motivation',
        'External Processes, Systems and Structures',
        'Relationships',
        'Internal Processes, Systems and Structures',
        'Learning',
        'Leadership'
    ]);

    const [toNext, setToNext] = useState(false);
    const [startValues, setStartValues] = useState({});
    const [currentPillar, setCurrentPillar] = useState('p1');
    const [pillarsCompleted, setPillarsCompleted] = useState([]);

    const [btnStepper, setBtnStepper] = useState(1);
    const [prevBtnStepper, setPrevBtnStepper] = useState(0);

    const [loading, setLoading] = useState(false);

    const [invalidStep, setInvalidStep] = useState(false);

    const totalQs = responses_data.questionnaire_questions.length;

    let postData = {
        responses: []
    };

    let initialValues = {
        // uuid: props.uuid,
        q1_score: "",
        q1_confidence: "",
        q2_score: "",
        q2_confidence: "",
        q3_score: "",
        q3_confidence: "",
        q4_score: "",
        q4_confidence: "",
        q5_score: "",
        q5_confidence: "",
        q6_score: "",
        q6_confidence: "",
        q7_score: "",
        q7_confidence: "",
        q8_score: "",
        q8_confidence: "",
        q9_score: "",
        q9_confidence: "",
        q10_score: "",
        q10_confidence: "",
        q11_score: "",
        q11_confidence: "",
        q12_score: "",
        q12_confidence: "",
        q13_score: "",
        q13_confidence: "",
        q14_score: "",
        q14_confidence: "",
        q15_score: "",
        q15_confidence: "",
        q16_score: "",
        q16_confidence: "",
        q17_score: "",
        q17_confidence: "",
        q18_score: "",
        q18_confidence: "",
        q19_score: "",
        q19_confidence: "",
        q20_score: "",
        q20_confidence: "",
        q21_score: "",
        q21_confidence: "",
        q22_score: "",
        q22_confidence: "",
        q23_score: "",
        q23_confidence: "",
        q24_score: "",
        q24_confidence: "",
        q25_score: "",
        q25_confidence: "",
        q26_score: "",
        q26_confidence: "",
        q27_score: "",
        q27_confidence: "",
        q28_score: "",
        q28_confidence: "",
        q29_score: "",
        q29_confidence: "",
        q30_score: "",
        q30_confidence: "",
        q31_score: "",
        q31_confidence: "",
        q32_score: "",
        q32_confidence: "",
        q33_score: "",
        q33_confidence: "",
        q34_score: "",
        q34_confidence: "",
        q35_score: "",
        q35_confidence: "",
        q36_score: "",
        q36_confidence: "",
        q37_score: "",
        q37_confidence: "",
        q38_score: "",
        q38_confidence: "",
        q39_score: "",
        q39_confidence: "",
        q40_score: "",
        q40_confidence: "",
        q41_score: "",
        q41_confidence: "",
        q42_score: "",
        q42_confidence: "",
        q43_score: "",
        q43_confidence: "",
        q44_score: "",
        q44_confidence: "",
        q45_score: "",
        q45_confidence: "",
        q46_score: "",
        q46_confidence: "",
        q47_score: "",
        q47_confidence: "",
        q48_score: "",
        q48_confidence: "",
        q49_score: "",
        q49_confidence: "",
        q50_score: "",
        q50_confidence: "",
        q51_score: "",
        q51_confidence: "",
        q52_score: "",
        q52_confidence: "",
        q53_score: "",
        q53_confidence: "",
        q54_score: "",
        q54_confidence: "",
        q55_score: "",
        q55_confidence: "",
        q56_score: "",
        q56_confidence: "",
        q57_score: "",
        q57_confidence: "",
        q58_score: "",
        q58_confidence: "",
        q59_score: "",
        q59_confidence: "",
        q60_score: "",
        q60_confidence: "",
        q61_score: "",
        q61_confidence: "",
        q62_score: "",
        q62_confidence: "",
        q63_score: "",
        q63_confidence: "",
        q64_score: "",
        q64_confidence: "",
        q65_score: "",
        q65_confidence: "",
        q66_score: "",
        q66_confidence: "",
        q67_score: "",
        q67_confidence: "",
        q68_score: "",
        q68_confidence: "",
        q69_score: "",
        q69_confidence: "",
        q70_score: "",
        q70_confidence: "",
        q71_score: "",
        q71_confidence: "",
        q72_score: "",
        q72_confidence: "",
        q73_score: "",
        q73_confidence: "",
        q74_score: "",
        q74_confidence: "",
        q75_score: "",
        q75_confidence: "",
        q76_score: "",
        q76_confidence: "",
        q77_score: "",
        q77_confidence: "",
        q78_score: "",
        q78_confidence: "",
        q79_score: "",
        q79_confidence: "",
        q80_score: "",
        q80_confidence: "",
        q81_score: "",
        q81_confidence: "",
        q82_score: "",
        q82_confidence: "",
        q83_score: "",
        q83_confidence: "",
        q84_score: "",
        q84_confidence: "",
        q85_score: "",
        q85_confidence: "",
        q86_score: "",
        q86_confidence: "",
        q87_score: "",
        q87_confidence: "",
        q88_score: "",
        q88_confidence: "",
        q89_score: "",
        q89_confidence: "",
        q90_score: "",
        q90_confidence: "",
        q91_score: "",
        q91_confidence: "",
        q92_score: "",
        q92_confidence: "",
        q93_score: "",
        q93_confidence: "",
        q94_score: "",
        q94_confidence: "",
        q95_score: "",
        q95_confidence: "",
        q96_score: "",
        q96_confidence: "",
        q97_score: "",
        q97_confidence: "",
        q98_score: "",
        q98_confidence: "",
        q99_score: "",
        q99_confidence: "",
        q100_score: "",
        q100_confidence: "",
        q101_score: "",
        q101_confidence: "",
        q102_score: "",
        q102_confidence: "",
        q103_score: "",
        q103_confidence: "",
        q104_score: "",
        q104_confidence: "",
        q105_score: "",
        q105_confidence: "",
        q106_score: "",
        q106_confidence: "",
        q107_score: "",
        q107_confidence: "",
        q108_score: "",
        q108_confidence: "",
        q109_score: "",
        q109_confidence: "",
        q110_score: "",
        q110_confidence: "",
        q111_score: "",
        q111_confidence: "",
        q112_score: "",
        q112_confidence: "",
        q113_score: "",
        q113_confidence: "",
        q114_score: "",
        q114_confidence: "",
        q115_score: "",
        q115_confidence: "",
        q116_score: "",
        q116_confidence: "",
        q117_score: "",
        q117_confidence: "",
        q118_score: "",
        q118_confidence: "",
        q119_score: "",
        q119_confidence: "",
        q120_score: "",
        q120_confidence: "",
    };

    const scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        // console.log(responses_data);
        // console.log("Question total: " + questionTotal);
        loadResponses();
    }, []);

    useEffect(() => {
        // console.log('btnStepper: ' + btnStepper);
        if (btnStepper >= 7) {
            setSurveyComplete(true);
        }

    }, [btnStepper]);

    // useEffect(() => {
    //     console.log('prevBtnStepper: ' + prevBtnStepper);
    // }, [prevBtnStepper]);

    // useEffect(() => {
    //     console.log(startValues);
    // }, [startValues]);

    // useEffect(() => {
    //     console.log(sessionStorage.answers);
    // }, [sessionStorage.answers]);

    // useEffect(() => {
    //     console.log(totalQs);
    // }, [totalQs]);

    useEffect(() => {
        console.log("invalidStep: " + invalidStep);
    }, [invalidStep]);

    const loadResponses = async () => {
        // await setLoading(true);

        _.each( responses_data.questionnaire_questions, ( response, key ) => {
            const scoreFieldName = 'q' + response.question_id + '_score';
            // initialValues[scoreFieldName] = response.score;
            setStartValues(startValues => ({...startValues, [scoreFieldName]: response.score }));

            const confFieldName = 'q' + response.question_id + '_confidence';
            // initialValues[confFieldName] = response.confidence;
            setStartValues(startValues => ({...startValues, [confFieldName]: response.confidence }));
        });

        // await setLoading(false);
    }

    const formik = useFormik({
        initialValues: startValues,
        onSubmit: async (values) => {
            // await setLoading(true);
            // console.log(values);
            // console.log(values.stepCount);
            // get values and parse fieldnames to get question_id
            // populate postData.responses object with question_id, score and confidence
            for (let i = 0; i < 120; i++) {
                const scoreFieldName = 'q' + (i+1) + '_score';
                const score = values[scoreFieldName]
                const confFieldName = 'q' + (i+1) + '_confidence';
                const conf = values[confFieldName];

                // get session storage - we need to manipulate and save back
                const sessionResponses = JSON.parse(sessionStorage.getItem('responses'));
                if (score && conf && sessionResponses.questionnaire_questions) {
                    sessionResponses.questionnaire_questions[i].score = score;
                    sessionResponses.questionnaire_questions[i].confidence = conf;
                    sessionStorage.setItem("responses", JSON.stringify(sessionResponses));
                }

                // add nodes to be updated to payload
                if (score && conf) {
                    const obj = {
                        question_id: i + 1,
                        score: score,
                        confidence: conf
                    }
                    postData.responses.push(obj);
                }
            }

            // if (_.some(values, _.isNull)) {
            // console.log("size of values: " + _.size(_.filter(values, _.isNull)));
            // console.log("calc: " + ((btnStepper-1) * (questionTotal * 2) / 6));
            // if (_.size(_.filter(values, _.isNull)) < ((btnStepper-1) * (questionTotal * 2) / 6)) {
            //     alert("It looks like you've missed something! Please check all questions have been answered.");
            // } else {
            // btnStepper !== 7 && setPrevBtnStepper(btnStepper);
            // btnStepper <= 6 && setBtnStepper(btnStepper + 1);
            // scrollToTop();
            // console.log(postData.responses);
            // console.log(JSON.parse(sessionStorage.getItem('responses')));
            questionnaireApi.questionnaireResponses(uuid, postData);
            // await setLoading(false);

            // }
            // }

        }
    });

    const { errors, touched, isValid, dirty, values } = formik;

    const validate = value => {
        if (value === null) {
            console.log("failed validation")
        }
    };

    return (

        <FormikProvider value={formik}>
            <Form className="bg-white">
            {loading &&
                <div className="report-generating" style={{backgroundColor: '#FFFFFF', position: "absolute", zIndex: 20, left:0, top:0, width: '100%'}}>
                    <Lottie
                        loop
                        autoplay
                        animationData={Animation}
                        className='animation'
                    />
                    <h1>please wait while we load the next pillar</h1>
                </div>
            }

                <MDBAlert show className='w-100 mt-3' color='info'>
                    <MDBRow>
                        <MDBCol size='4' sm='2' className='d-flex flex-shrink-1 align-items-center'>
                            <MDBIcon fas icon="info-circle" size="5x" fixed />
                        </MDBCol>
                        <MDBCol size='8' sm='10' className='d-flex flex-grow-1 align-items-center'>
                            <h6 className='lh-lg'>For each statement, please score them from 1 ( Strongly disagree) to 10 (Strongly agree) and then rate your confidence level in the score that you have given from 1 (Not confident) to 5 (Very confident).</h6>
                        </MDBCol>
                    </MDBRow>
                </MDBAlert>

                <div className="p-0 p-lg-2 p-xl-5 m-3 stepper-container">
                    <div className='sticky-footer'>
                        <div className='prev-next-container m-5'>
                            <MDBBtn
                                rounded
                                type="submit"
                                // onClick={scrollToTop}
                                ref={prevRef}
                            >
                                Previous Pillar
                            </MDBBtn>
                            <MDBBtn
                                rounded
                                outline
                                type="submit"
                                ref={triggerContainer}
                            >Save your progress</MDBBtn>
                            <MDBBtn
                                rounded
                                ref={nextRef}
                                // onClick={scrollToTop}
                                type="submit"
                            >
                                {btnStepper === 6 ?
                                    <span className='btn-primary'>Complete Survey</span>
                                    :
                                    <span className='btn-primary'>Next Pillar</span>
                                }
                            </MDBBtn>
                        </div>
                    </div>

                    <div className='content'>
                        <MDBStepper
                            linear
                            disableHeadSteps
                            externalNext={nextRef}
                            externalPrev={prevRef}
                            className={`questionnaire-stepper ${totalQs === 120 && `full-survey`}`}
                            onChange={(id) => {
                                // console.log('onChangeStep ' + id);
                                setBtnStepper(id);
                            }}
                            onInvalid={() => {
                                // console.log('onInvalid')
                                setInvalidStep(true);
                                //alert("It looks like you've missed something! Please check all questions have been answered.");
                                return(null);
                            }}
                            onValid={(id) => {
                                // console.log('onValid')
                                setInvalidStep(false);
                                // scrollToTop();
                            }}
                            id='stepper'
                        >
                            { pillars.map((row, key) => {
                                return (
                                    <MDBStepperStep
                                        key={key + 1}
                                        itemId={key + 1}
                                        headIcon={key + 1}
                                        headClassName={`p${key + 1}`}
                                        // headText={`Pillar ${key + 1}`}
                                        className="col-md-2"
                                        customValidation={(input: HTMLInputElement) => {
                                            // console.log(input);
                                            const inputLength = input.value.length;
                                            // console.log(inputLength);
                                            if (inputLength < 1) {
                                                input.setCustomValidity('Invalid');
                                            } else {
                                                input.setCustomValidity('');
                                            }
                                            return inputLength >= 1;
                                        }}
                                    >
                                        <div className={`text-end mb-3 p${key + 1}`}>
                                            Questions {(btnStepper*(totalQs/6))-((totalQs/6)-1)} to {btnStepper*(totalQs/6)} of {totalQs}
                                        </div>

                                        <h1 className="mb-5 step-heading" style={{fontSize:"2em", textAlign:"center"}}>{row}</h1>
                                        {
                                            _.filter(responses_data.questionnaire_questions, ['pillar_id', key + 1]).map((question, qkey) => {
                                                return (
                                                    <div className="qrow" key={qkey}>
                                                        <MDBRow className='question-row'>
                                                            <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row' key={`q${qkey}`}>
                                                                <MDBCol size='1' className='flex-shrink-1'>
                                                                    <p>{question.question_number}</p>
                                                                </MDBCol>
                                                                <MDBCol size='5' className='w-30 flex-grow-1 question'>
                                                                    <p>{question.question}</p>
                                                                </MDBCol>
                                                            </MDBCol>
                                                            <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-5 ps-md-1' key={`s${qkey}`}>
                                                                <MDBCol xs='12' lg='6' className='me-5 me-md-3 me-lg-5'>
                                                                    <MDBValidationItem invalid feedback='invalid' className='border-0' >
                                                                        <input
                                                                            name={'q' + question.question_id + '_score'}
                                                                            id={'q' + question.question_id + '_score'}
                                                                            type='hidden'
                                                                            className='border-0'
                                                                            required
                                                                            defaultValue={question.score}
                                                                            // value={question.score}
                                                                        />
                                                                    </MDBValidationItem>
                                                                    {/*<Field*/}
                                                                    {/*        required*/}
                                                                    {/*        validate={validate}*/}
                                                                    {/*        initialValue={question.score}*/}
                                                                    {/*        id={'q' + question.question_id + '_score'}*/}
                                                                    {/*        range="10"*/}
                                                                    {/*        className="score-rating"*/}
                                                                    {/*        label="score"*/}
                                                                    {/*        color={colors[`p${key+1}`]}*/}
                                                                    {/*        component={ScoreSelector}*/}
                                                                    {/*        onChange={(e) => {*/}
                                                                    {/*            console.log(e)*/}
                                                                    {/*        }}*/}
                                                                    {/*    />*/}
                                                                    <ScoreSelector
                                                                        initialValue={question.score}
                                                                        id={'q' + question.question_id + '_score'}
                                                                        range="10"
                                                                        className="score-rating"
                                                                        label="score"
                                                                        color={colors[`p${key+1}`]}
                                                                    />
                                                                </MDBCol>
                                                                <MDBCol xs='12' lg='6' className='mb-5'>
                                                                    <MDBValidationItem invalid feedback='invalid' className='border-0' >
                                                                        <input
                                                                            name={'q' + question.question_id + '_confidence'}
                                                                            id={'q' + question.question_id + '_confidence'}
                                                                            type='hidden'
                                                                            className='border-0'
                                                                            required
                                                                            defaultValue={question.confidence}
                                                                            // value={question.confidence}
                                                                        />
                                                                    </MDBValidationItem>
                                                                    {/*<Field*/}
                                                                    {/*    required*/}
                                                                    {/*    initialValue={question.confidence}*/}
                                                                    {/*    id={'q' + question.question_id + '_confidence'}*/}
                                                                    {/*    range="5"*/}
                                                                    {/*    label="confidence"*/}
                                                                    {/*    color={colors[`p${key+1}`]}*/}
                                                                    {/*    component={ScoreSelector}*/}
                                                                    {/*    onChange={() => {*/}
                                                                    {/*        // setSponsorLogoImageUpdated(sponsorLogoImageUpdated => !sponsorLogoImageUpdated);*/}
                                                                    {/*    }}*/}
                                                                    {/*/>*/}
                                                                    <ScoreSelector
                                                                        initialValue={question.confidence}
                                                                        id={'q' + question.question_id + '_confidence'}
                                                                        range="5"
                                                                        label="confidence"
                                                                        color={colors[`p${key+1}`]}
                                                                    />
                                                                </MDBCol>
                                                            </MDBCol>
                                                        </MDBRow>
                                                    </div>
                                                )
                                            })}
                                    </MDBStepperStep>
                                )
                            })
                            }
                        </MDBStepper>

                        <MDBAlert show={invalidStep}  className='w-100 mt-3' color='danger'>
                            <MDBRow>
                                <MDBCol size="2" className='d-flex flex-shrink-1'>
                                    <MDBIcon fas icon="exclamation-triangle" size="5x" fixed />
                                </MDBCol>
                                <MDBCol size='10' className='d-flex flex-grow-1 align-items-center'>
                                    <h6 className='lh-lg'>It looks like you've missed something! Please check all questions have been answered.</h6>
                                </MDBCol>
                            </MDBRow>
                        </MDBAlert>


                        <MDBToast
                            autohide
                            position='bottom-center'
                            delay={3000}
                            className={`toasty p${btnStepper}`}
                            triggerRef={triggerContainer}
                            bodyContent='Progress saved'
                        />
                    </div>
                </div>
            </Form>
        </FormikProvider>

    );
}
