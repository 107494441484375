import React, {useState, useEffect} from 'react';
import {
    MDBContainer,
    MDBInputGroup,
    MDBIcon,
    MDBBtn, MDBSwitch
} from 'mdb-react-ui-kit';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import authApi from "../api/auth"
import {ReactComponent as Logo} from "../assets/img/ccmi-graphic-logo-color.svg";

const resetSchema = Yup.object().shape({
    email: Yup.string().email().required("Enter your email address"),
});

const ResetPassword = (props) => {

    const [success, setSuccess] = useState(0);

    const initialValues = {
        email: "",
    };

    return (
        <div id="intro" className="bg-image shadow-2-strong">
            <div className="mask d-flex align-items-center h-100" style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-5 col-md-8">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={resetSchema}
                                onSubmit={(values) => {
                                     authApi.resetPassword(values);
                                     setSuccess(1);
                                    //authApi.loginDummyUser();
                                }}
                            >
                                {(formik) => {
                                    const { errors, touched, isValid, dirty } = formik;
                                    return (
                                        <Form className="bg-white  rounded-5 shadow-5-strong login-register-form">
                                            <div className="login-register-header">
                                                <Logo style={{ height: '5rem', width: '5rem' }} />
                                                <h1>Reset Password</h1>
                                                <p className="">Please enter your email address below to reset your password.</p>
                                            </div>
                                            <div className="modal-divider"></div>
                                            {!success &&
                                                <div className="p-5">
                                                    {/*<MDBInputGroup textBefore={<MDBIcon fas icon="at"/>} className="mb-3">*/}
                                                        <Field
                                                            type="text"
                                                            placeholder="Your email address"
                                                            autoComplete="email"
                                                            id="email"
                                                            name="email"
                                                            className={
                                                                errors.email && touched.email ? "form-control mb-3 is-invalid" : "form-control mb-3"
                                                            }
                                                        />
                                                        <ErrorMessage name="email" component="div" className="error"/>
                                                    {/*</MDBInputGroup>*/}
                                                    <MDBBtn
                                                        type="submit"
                                                        className={!(dirty && isValid) ? "formButton disabled-btn" : "formButton"}
                                                        disabled={!(dirty && isValid)}
                                                    >Reset password</MDBBtn>
                                                    <div className="login-register-footer">
                                                        <p>Don't have a CCMI account? <a href='/register/'>Register
                                                            here</a>.</p>
                                                    </div>
                                                </div>
                                            }
                                            {success &&
                                                <div className="p-5">
                                                    <p>Thank you. We've send an email to {formik.values.email} with a link to complete your password reset.</p>
                                                </div>
                                            }
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
