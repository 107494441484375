import client from "./client";

const loginEndpoint = '/login';
const registerEndpoint = '/register';
const resetEndpoint = '/reset';
const passwordResetEndpoint = '/password_reset';
const resendVerificationEndpoint = '/resendverification';
const usersEndpoint = "/users";

const registerUser = (payload) => client.post(registerEndpoint, JSON.stringify(payload))
    .catch(function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            return error.response;
        } else if (error.request) {
            // The request was made but no response was received
        } else {
            // Something happened in setting up the request that triggered an Error
        }
    });

const loginUser = (payload) => {
    return client.post(loginEndpoint, JSON.stringify(payload))
        .then((response) => {
            if (response.data) {
                if (response.data.user.email_validated === 0) {
                    alert("You need to validate your account before you can log into the service. Please check your inbox for a validation email from CCMI.")
                    localStorage.removeItem("user");
                    window.location = '/login';
                } else {
                    localStorage.setItem("user", JSON.stringify(response.data));
                    window.location.reload();
                }
            }
        }, (error) => {
            if(error.response.status==401 || error.response.status==403){
                //failed login for whatever reason - message redirect to login
                alert("We were unable to log you into the Perill service. Please check your details and try again.")
                localStorage.removeItem("user");
                window.location = '/login';
            }
        });
};

const getCurrentUserLocal = () => {
    return JSON.parse(localStorage.getItem('user'));
}

const logOutUser = () => {
    localStorage.removeItem("user");
    window.location.href = "/login";
};

const resetPassword = (payload) => {
    return client.post(resetEndpoint, JSON.stringify(payload))
        .then((response) => {

        }, (error) => {

        }
    );
};

const changePassword = (payload) => {
    return client.post(passwordResetEndpoint, JSON.stringify(payload))
        .then((response) => {
            if (response.data) {
                // window.location.reload();
                // window.location.href = "/login";
            }
        }, (error) => {

        }
        );
}

const resendEmailVerification = (payload) => {
    return client.post(resendVerificationEndpoint, JSON.stringify(payload))
        .then((response) => {
                if (response.data) {
                }
            }, (error) => {

            }
        );
};

export default {
    loginUser,
    logOutUser,
    getCurrentUserLocal,
    registerUser,
    resetPassword,
    changePassword,
    resendEmailVerification
};
