import client from "./client";
import reset from "../pages/Reset";

const endpoint = '/survey_diagnostics/';
const stakeholder_endpoint = '/survey_diagnostics_stakeholder/';
const comparison_endpoint = 'survey_diagnostics_comparison';
const roi_endpoint = 'roi_report';
const getDiagnostics = (survey_id, user_id, team_id) => client.get(endpoint + survey_id + '?user_id=' + user_id + '&team_id=' + team_id);

// const getSurveyDiagnostics = (survey_id) => client.get(endpoint + survey_id);

const getSurveyDiagnostics = (survey_id, payload) => {
    return client.post(endpoint + survey_id, payload)
        .then((response) => {
            return response;
            console.log(response);
        }, (error) => {
            if(error.response.status==400 || error.response.status==403){
                alert(error.response.data.description);
            }
        });
};

const getStakeholderDiagnostics = (survey_id, payload) => {
    return client.post(stakeholder_endpoint + survey_id, payload)
        .then((response) => {
            return response;
            console.log(response);
        }, (error) => {
            if(error.response.status==400 || error.response.status==403){
                alert(error.response.data.description);
            }
        });
};

const getComparisonDiagnostics = (payload) => {
    return client.post(comparison_endpoint, payload)
        .then((response) => {
            return response;
            console.log(response);
        }, (error) => {
            if(error.response.status==400 || error.response.status==403){
                alert(error.response.data.description);
            }
        });
};

const getRoiDiagnostics = (payload) => {
    return client.post(roi_endpoint, payload)
        .then((response) => {
            return response;
            console.log(response);
        }, (error) => {
            if(error.response.status==400 || error.response.status==403){
                alert(error.response.data.description);
            }
        });
};


export default {
    getDiagnostics,
    getSurveyDiagnostics,
    getStakeholderDiagnostics,
    getComparisonDiagnostics,
    getRoiDiagnostics
};
