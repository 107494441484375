import React, {useState, useEffect} from 'react';
import {MDBDatatable, MDBBtn, MDBIcon, MDBContainer, MDBTooltip} from 'mdb-react-ui-kit';
import _ from 'lodash';
import {useHistory, useLocation} from "react-router-dom";
import {
    GroupingState,
    IntegratedGrouping,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableGroupRow,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import '@icon/open-iconic/open-iconic.css';

import participantsApi from '../api/participants'
import PageHeader from "../components/PageHeader";
import NavBar from "../components/navigation/NavBar";

const Participants = () => {

    const user = JSON.parse(localStorage.getItem('user'));

    const [isLoading, setIsLoading] = useState(false);

    const [participants, setParticipants] = useState({
        columns: [
            { title: 'Company', name: 'company_name' },
            { title: 'Team', name: 'team_name' },
            { title: 'Participants', name: 'participants' },
            { title: 'ID', name: 'id' },
            { title: 'Status', name: 'status', sort: true },
            { title: 'Name', name: 'name' },
            { title: 'Role', name: 'role' },
            { title: 'Email', name: 'email' },
            // { title: 'Relationship', name: 'relationship' },
            // { label: 'Actions', field: 'actions', sort: false },
        ],
        rows: [],
    });
    const [defaultHiddenColumnNames] = useState(['id', 'status']);

    const [companies, setCompanies] = useState([]);

    // history stack
    const history = useHistory()
    const queryPage = useLocation().search.match(/page=([0-9]+)/, '')
    const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1)
    const [page, setPage] = useState(currentPage)


    // ** API calls **

    useEffect(() => {
        loadParticipants();
    }, []);

    const loadParticipants = async () => {
        const response = await participantsApi.getParticipants();

        _.each(  response.data.participants, ( participant, key ) => {
            participant.participants = (participant.role === 3) ? "Stakeholders" : "Team";
        });

        let participantsData = _.reverse(_.sortBy(_.sortBy(_.sortBy(_.reverse(_.sortBy(response.data.participants, [function(o) { return o.participants; }])), [function(o) { return o.company_name; }]), [function(o) { return o.team_name; }]), [function(o) { return o.status; }]));

        console.log(participantsData);

        let tmpCompanies = [];

        // _.each( participantsData, ( participant, key ) => {
        //     participant.participants = (participant.role === 3) ? "Stakeholders" : "Team";
        // });
        //
        // participantsData = _.sortBy(participantsData, [function(o) { return o.participants; }])

        _.each( participantsData, ( participant, key ) => {

            tmpCompanies.push(participant.company_name);
            // setCompanies(companies => [...companies, participant.company_name]);

            setParticipants((participants) => ({
                    columns: participants.columns,
                    rows: [
                        ...participants.rows,
                        {
                            company_name: participant.company_name,
                            team_name: participant.team_name,
                            // participants: (participant.role === 3) ? "Stakeholders" : "Team",
                            participants: participant.participants,
                            id: participant.id,
                            status: participant.status,
                            name: <p>{participant.name}</p>,
                            role: <p>{participant.role === 1 ? "Team member" : (participant.role === 3) ? "Stakeholder" : "Team Leader"}</p>,
                            email: <p>{participant.email}</p>,
                            // relationship: (participant.role === 3) && 'client',
                        }
                    ]

                })
            );
        } );

        setCompanies(_.sortBy(_.uniq(tmpCompanies), [function(o) { return o; }]));
    }

    // useEffect(() => {
    //     console.log(companies);
    // }, [companies]);
    //
    // useEffect(() => {
    //     // setParticipants(_.sortBy(participants, [function(o) { return o.participants; }]));
    //     console.log(participants.rows);
    //     console.log(participants.columns);
    // }, [participants]);

    const TableRow = ({ row, ...restProps }) => (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            onClick={() => row.status>0 && history.push(`/participants/${row.id}`)}
            style={{
                cursor: 'pointer',
            }}
            className={row.status===0 ? "inactive" : undefined}
        />
    );

    return (
        <>
            <div className='text-left'>
                <NavBar context="teams" />
                <PageHeader title='Participants' />
                <MDBContainer className="px-5 mt-5">
                    <div className='team'>
                        <Grid
                            rows={participants.rows}
                            columns={participants.columns}
                        >
                            <GroupingState
                                grouping={[
                                    { columnName: 'company_name' },
                                    { columnName: 'team_name' },
                                    { columnName: 'participants' },
                                ]}
                            />
                            <IntegratedGrouping />
                            <Table
                                rowComponent={TableRow}
                            />
                            <TableHeaderRow />
                            <TableColumnVisibility
                                defaultHiddenColumnNames={defaultHiddenColumnNames}
                            />
                            <TableGroupRow
                                showColumnsWhenGrouped={false}
                                className='team'
                            />
                        </Grid>
                    </div>
                    {/*<MDBDatatable*/}
                    {/*    data={participants}*/}
                    {/*    onRowClick={(row) => history.push(`/participants/${row.id}`)}*/}
                    {/*    isLoading={isLoading}*/}
                    {/*    className="team"*/}
                    {/*/>*/}
                    <div className="d-flex justify-content-end add-button">
                        <MDBTooltip tag='span' wrapperClass='d-inline-block px-2' placement='left' title='Add a participant'>
                            <MDBBtn floating tag='a' href='/participants/add'>
                                <MDBIcon fas icon="plus team" />
                            </MDBBtn>
                        </MDBTooltip>
                    </div>
                </MDBContainer>
            </div>
        </>
    );
}

export default Participants;
