import React, {useEffect, useRef, useState} from 'react';

import PageHeader from "../components/PageHeader";
import NavBar from "../components/navigation/NavBar";
import {
    MDBAccordion,
    MDBAccordionItem,
    MDBBtn,
    MDBContainer,
    MDBSwitch, MDBInput, MDBToast, MDBIcon,
} from "mdb-react-ui-kit";
import {ErrorMessage, Field, Form, FormikProvider, useFormik} from "formik";
import {Redirect, useHistory, useLocation} from "react-router-dom";
import DropZone from "../components/DropZone";
import * as Yup from "yup";
import usersApi from "../api/users";
import authApi from "../api/auth";
import CountriesDropdown from "../components/CountriesDropdown";

const Profile = () => {

    const user = JSON.parse(localStorage.getItem('user'));

    const [isLoading, setIsLoading] = useState(false);
    const [formFilled, setFormFilled] = useState(false);
    // navigate on submit
    const [toNext, setToNext] = useState(false);
    const [logoImage, setLogoImage] = useState('');
    const [logoImageUpdated, setLogoImageUpdated] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState("");
    // const [changePassword, setChangePassword] = useState(false);

    //toasts
    const wrapperRef = useRef(null);
    const triggerContainer = useRef(null);


    const history = useHistory()

    const userSchema = Yup.object().shape({
        firstname: Yup.string().required("You need to provide your first name."),
        lastname: Yup.string().required("You need to provide your last name."),
        email: Yup.string().email().required("Enter your email address"),
        // password: Yup.string().required("Enter your password"),
        // passwordConfirmation: Yup.string().equalTo(Yup.ref('password'), 'Passwords must match'),
        coaching_org: Yup.string().required("You need to provide the name of your coaching organisation. This will appear on your reports."),
        // country: Yup.string().required("Please select the primary country in which you work"),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: "",
            firstname: "",
            lastname: "",
            postcode: "",
            mobile: "",
            email: "",
            uuid: user.uuid,
            mfa_enabled: 0,
            email_validated: 1,
            email_validated_date: "",
            registered_date: "",
            processing_consent: 1,
            processing_consent_date: null,
            marketing_consent: null,
            marketing_consent_date: null,
            deleted_date: null,
            status: 1,
            role: 1,
            coaching_org: "",
            country: "",
            coaching_logo: "",
            co_coach: "",
        },
        validationSchema: userSchema,
        onSubmit: async (values) => {
            // values.marketing_consent = (values.marketing_consent === true) ? 1 : 0;
            usersApi.updateUser(values);
            if (response.ok) {
                if (values.email !== user.user.email) {
                    alert("You have changed your email! You will now be logged out of the PERILL service and will need to log in again using your new email address.");
                    const timer = setTimeout(() => {
                        authApi.logOutUser();
                    }, 1000);
                } else {
                    const timer = setTimeout(() => {
                        setToNext(true);
                    }, 3000);
                }
                return () => clearTimeout(timer);
            }
        },
    });

    const { errors, touched, isValid, dirty, values } = formik;

    useEffect(() => {
        loadUser()
            .then((response) => fillForm(response));
    }, []);

    const loadUser = async () => {
        try {
            setIsLoading(true);
            const response = await usersApi.getUser();
            console.log(response);
            return response;
        } catch(error) {

        }
    }

    async function fillForm (response) {
        try {
            await formik.setValues(response.data.users[0]);
            await setLogoImage(response.data.users[0].coaching_logo);
            await setSelectedCountry(response.data.users[0].country);
            await setIsLoading(false);
            setFormFilled(true);
        } catch(error) {

        }
    }


    const handleChange = async (e) => {
        await formik.setFieldValue(e.target.id, e.target.value);
    };

    const handleChangePassword = async () => {
        authApi.resetPassword({email: formik.values.email});
        alert("An email has been sent to your address with a link to reset your password.");
    }

    return (
        <div>
            <NavBar context="dashboard" />
            <div className="d-flex flex-row justify-content-between">
                <PageHeader title="Profile" />
            </div>
            <MDBContainer className="px-5">
                <hr className='mt-4'/>
                <MDBAccordion flush initialActive='editProfile' className="team-page">
                    <MDBAccordionItem collapseId='editProfile' headerTitle='Edit Profile'>
                        {formFilled &&
                            <FormikProvider value={formik}>
                                <Form
                                    className="form-horizontal"
                                    onSubmit={formik.handleSubmit}
                                >
                                    <Field
                                        as="select"
                                        name="title"
                                        id="title"
                                        label='title'
                                        className='form-control mt-5 mb-3'
                                        onChange={handleChange}
                                    >
                                        <option defaultValue>Title</option>
                                        <option value="Mr">Mr</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Miss">Miss</option>
                                        <option value="Dr">Dr</option>
                                        <option value="Prof">Prof</option>
                                    </Field>
                                    {/*<Select*/}
                                    {/*    options={[*/}
                                    {/*        {value: "Mr", label: 'Mr'},*/}
                                    {/*        {value: "Mrs", label: 'Mrs'},*/}
                                    {/*        {value: "Miss", label: 'Miss'},*/}
                                    {/*        {value: "Ms", label: 'Ms'},*/}
                                    {/*        {value: "Dr", label: 'Dr'},*/}
                                    {/*        {value: "Prof.", label: 'Prof.'},*/}
                                    {/*    ]}*/}
                                    {/*    defaultValue={formik.values.title}*/}
                                    {/*    className='selectable mb-3'*/}
                                    {/*    placeholder='Title'*/}
                                    {/*    formatOptionLabel={option => <div className="needsclick">{option.label}</div>}*/}
                                    {/*    // onBlur={handleBlur}*/}
                                    {/*    onChange={(e) => {*/}
                                    {/*        e && formik.setFieldValue('title', e.value);*/}
                                    {/*    }}*/}
                                    {/*/>*/}

                                    <Field
                                        type="text"
                                        label="First name"
                                        id="firstname"
                                        name="firstname"
                                        className={
                                            errors.firstname && touched.firstname ? "form-control mb-3 is-invalid" : "form-control mb-3"
                                        }
                                        onBlur={() => formik.setFieldTouched('firstname')}
                                        defaultValue={formik.values.firstname}
                                        component={MDBInput}
                                        onChange={handleChange}
                                    />
                                    <ErrorMessage name="firstname" component="div" className="error"/>
                                    <Field
                                        type="text"
                                        label="Last name"
                                        id="lastname"
                                        name="lastname"
                                        className={
                                            errors.lastname && touched.lastname ? "form-control mb-3 is-invalid" : "form-control mb-3"
                                        }
                                        onBlur={() => formik.setFieldTouched('lastname')}
                                        defaultValue={formik.values.lastname}
                                        component={MDBInput}
                                        onChange={handleChange}
                                    />
                                    <ErrorMessage name="lastname" component="div" className="error"/>
                                    <p>(This information will appear in the survey invitation emails and reports)</p>
                                    <Field
                                        type="text"
                                        label="Your email address"
                                        autoComplete="email"
                                        id="email"
                                        name="email"
                                        className={
                                            errors.email && touched.email ? "form-control mb-3 is-invalid" : "form-control mb-3"
                                        }
                                        onBlur={() => formik.setFieldTouched('email')}
                                        defaultValue={formik.values.email}
                                        component={MDBInput}
                                        onChange={handleChange}
                                    />
                                    <ErrorMessage name="email" component="div" className="error"/>
                                    <Field
                                        type="text"
                                        label="Your postcode"
                                        autoComplete="postcode"
                                        id="postcode"
                                        name="postcode"
                                        className="form-control mb-3"
                                        onBlur={() => formik.setFieldTouched('postcode')}
                                        defaultValue={formik.values.postcode}
                                        component={MDBInput}
                                        onChange={handleChange}
                                    />
                                    <Field
                                        type="tel"
                                        label="Your mobile number"
                                        autoComplete="tel"
                                        id="mobile"
                                        name="mobile"
                                        className="form-control mb-3"
                                        onBlur={() => formik.setFieldTouched('mobile')}
                                        defaultValue={formik.values.mobile}
                                        component={MDBInput}
                                        onChange={handleChange}
                                    />
                                    <MDBBtn
                                        type="button"
                                        className='mt-4 mb-5'
                                        onClick={handleChangePassword}
                                    >Change Password</MDBBtn>
                                    <hr className='mt-4'/>
                                    <MDBSwitch
                                        defaultChecked={formik.values.marketing_consent === 1 ? true : false}
                                        id='marketing_consent'
                                        name='marketing_consent'
                                        label={<span>Get the latest news, blogs and thought leadership on Coaching, Mentoring and Leadership from Professor David Clutterbuck and the <a href="https://clutterbuck-cmi.com/" target='_blank'>CCMI Network</a>. Delivered directly to you via email every few months.</span>}
                                        onChange={(e) => {
                                            formik.values.marketing_consent = ((e.target.checked === true) ? 1 : 0);
                                            e.target.checked = ((formik.values.marketing_consent) ? true : false);
                                        }}
                                        onBlur={() => formik.setFieldTouched('marketing_consent')}
                                    />
                                    <p className='mt-3 ms-5'>We respect your data and privacy. You can unsubscribe at any time. See our <a href="/privacy-policy" target='_blank'>Privacy Policy</a> for details.</p>
                                    <hr className='mt-4'/>
                                    <Field
                                        type="text"
                                        id="coaching_org"
                                        name="coaching_org"
                                        label="Coaching Organisation"
                                        className={
                                            errors.name && touched.name ? "mb-3 form-control is-invalid" : "mb-3 form-control"
                                        }
                                        defaultValue={formik.values.coaching_org}
                                        component={MDBInput}
                                        onChange={handleChange}
                                    />
                                    <ErrorMessage name="coaching_org" component="div" className="error mb-3"/>
                                    <Field
                                        id="coaching_logo"
                                        name="coaching_logo"
                                        component={DropZone}
                                        onChange={() => {
                                            setlogoImageUpdated(logoImageUpdated => !logoImageUpdated);
                                        }}
                                        className="mb-3"
                                        placeholder="Drag 'n' drop your logo here, or click to select files"
                                    />
                                    {(!logoImageUpdated && logoImage) ?
                                        <img width="100" className="mb-3" src={logoImage} alt="logo"
                                             id="logoImage"/> : null}
                                    {toNext ? <Redirect to='/'/> : null}
                                    <p>If you have a co-coach insert their name here, so that both names appear
                                        on the cover of the reports</p>
                                    <Field
                                        type="text"
                                        name="co_coach"
                                        id="co_coach"
                                        label='Co-coaches (names separated by commas)'
                                        className="mb-3 form-control"
                                        defaultValue={formik.values.co_coach}
                                        component={MDBInput}
                                        onChange={handleChange}
                                    />
                                    <p>(This information will appear in the survey invitation emails and reports)</p>
                                    {selectedCountry &&
                                        <Field
                                            as="select"
                                            name="country"
                                            id="country"
                                            placeholder="Primary country in which you work"
                                            className='mt-5 mb-3'
                                            selected_country={selectedCountry}
                                            component={CountriesDropdown}
                                        />
                                    }
                                    <div className="d-flex justify-content-end add-button">
                                        <MDBBtn
                                            type="submit"
                                            ref={triggerContainer}
                                            className={!(dirty && isValid) ? "formButton disabled-btn" : "formButton"}
                                            disabled={!(dirty && isValid)}
                                        >Update Profile</MDBBtn>
                                        <MDBToast
                                            autohide
                                            position='top-right'
                                            delay={3000}
                                            className={`toasty p1`}
                                            triggerRef={triggerContainer}
                                            bodyContent='Profile updated'
                                        />
                                    </div>
                                </Form>
                            </FormikProvider>
                        }
                    </MDBAccordionItem>
                </MDBAccordion>
            </MDBContainer>
        </div>

);
}

export default Profile;
