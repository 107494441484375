import React, {useEffect, useState, memo} from 'react';
import {
    MDBChart,
    MDBRow,
    MDBCol, MDBTable, MDBTableHead, MDBTableBody, MDBInput, MDBTextArea,
    MDBSwitch, MDBToast, MDBIcon, MDBValidationItem, MDBRating, MDBRatingElement, MDBBtn
} from "mdb-react-ui-kit";

import Hero from "../assets/img/report/report-hero-full-roi.png";
import {useLocation} from "react-router-dom";
import colors from "../config/colors";
import diagnostics from "../api/diagnostics";

// get query string
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const RoiReport = (props) => {

    const team_list = props.teams;

    let coaches = [];

    const [coachesStr, setCoachesStr] = useState('');
    const [chartData, setChartData] = useState(null);
    const [chartConfidence, setChartConfidence] = useState(null);
    const [effectiveness, setEffectiveness] = useState(null);
    const [coachRating, setCoachRating] = useState(null);
    const [recommend, setRecommend] = useState(null);
    const [overviewCommentary, setOverviewCommentary] = useState("");
    const [themesCommentary1, setThemesCommentary1] = useState("");
    const [themesCommentary2, setThemesCommentary2] = useState("");
    const [themesCommentary3, setThemesCommentary3] = useState("");
    const [reportDate, setReportDate] = useState(null);

    // toggles
    const [overviewCommentaryToggle, setOverviewCommentaryToggle] = useState(false);
    const [themesCommentary1Toggle, setThemesCommentary1Toggle] = useState(false);
    const [themesCommentary2Toggle, setThemesCommentary2Toggle] = useState(false);
    const [themesCommentary3Toggle, setThemesCommentary3Toggle] = useState(false);

    useEffect(() => {
        if (!team_list) return;
        getData();

        async function getData() {
            console.log(team_list);
            const response = await diagnostics.getRoiDiagnostics(team_list);
            if (response) {
                console.log(response);
                const data = await response.data;
                setChartData(data);
                setChartConfidence(data.confidence);
                setEffectiveness(data.effective_percentage);
                setCoachRating(data.coach_rating);
                setRecommend(data.recommend);
            }
        }
    }, [team_list])

    const textToArray = (input, split_size) => {
        let text_block = input.split(' ');
        let output = [];
        for(let j=0;j<text_block.length;j++){
            // To preserve space make the array of strings (needed to split them on the chart)
            // add small strings to the end of the previous entry
            if (text_block[j].length < split_size && j > 0)
                output[output.length-1] = output[output.length-1] + " " + text_block[j];
            else
                output.push(text_block[j]);
        }
        return output;
    }

    const getPillarData = () => {
        var arr = [];
        var min = [];
        var max = [];
        if (!chartData || chartData.pillars.length < 1) return arr;

        function getTeamPillarValues(team) {
            var values = [];
            for (let i=0;i<chartData.pillars.length;i++){
                for(let j=0;j<chartData.pillars[i].teams.length;j++){
                    if (team.team_id === chartData.pillars[i].teams[j].team_id){
                        values.push(chartData.pillars[i].teams[j].percentage_change)
                    }
                }
            }
            return values;
        }

        function getTeamColors(team, idx) {
            var bar_colors = [];
            for (var i=0;i<team.sections.length;i++){
                bar_colors.push(colors[`p${idx+1}Bar`])
            }
            return bar_colors;
        }

        // Use first pillar's data set to get list of teams
        let teams = chartData.pillars[0].teams;
        teams.map((team, idx) => {
            const obj = {
                label: (team.company_name + ":" + team.team_name).split(":"),
                barPercentage: 0.5,
                data: getTeamPillarValues(team),
                backgroundColor: getTeamColors(team, idx)
            }
            arr.push(obj);
        })

        return arr;
    }

    const getPillarLabels = () => {
        if (!chartData) return;
        var arr = [];
        for(var i=0;i<chartData.pillars.length;i++){
            arr.push(textToArray(chartData.pillars[i].pillar_name, 5));
        }
        return arr;
    }

    const getThemeData = (pillar_idx) => {
        var arr = [];
        if (!chartData) return arr;

        function getTeamSectionValues(team) {
            var values = [];
            for (var i=0;i<team.sections.length;i++){
                values.push(team.sections[i].percentage_change)
            }
            return values;
        }

        function getTeamColors(team, idx) {
            var bar_colors = [];
            for (var i=0;i<team.sections.length;i++){
                bar_colors.push(colors[`p${idx+1}Bar`])
            }
            return bar_colors;
        }

        chartData.pillars &&
        chartData.pillars[pillar_idx] &&
        chartData.pillars[pillar_idx].teams.map((team, idx) => {
            const obj = {
                label: (team.company_name + ":" + team.team_name).split(":"),
                barPercentage: 0.5,
                data: getTeamSectionValues(team),
                backgroundColor: getTeamColors(team, idx)
            }
            arr.push(obj);

        })

        return arr;
    }

    const getThemeDataLabels = (idx) => {
        if (!chartData) return;
        var arr = [];
        for(var i=0;i<chartData.pillars[idx].teams[0].sections.length;i++){
            arr.push(textToArray(chartData.pillars[idx].teams[0].sections[i].section_name, 3));
        }
        return arr;
    }

    useEffect(() => {
        if (!chartData) return;

        let repDate = new Date(chartData.report_date * 1000);
        setReportDate(repDate.toLocaleDateString());

        if (chartData && (chartData.co_coach && chartData.co_coach.length > 0)) {
            coaches = chartData.co_coach.split(",").map(function(item) {
                return item.trim();
            });
        }
        chartData && coaches.unshift(chartData.coach);
        coaches.length > 0 && setCoachesStr(arrayToList(coaches));

    }, [chartData])

    function arrayToList(array){
        return array
            .join(", ")
            .replace(/, ((?:.(?!, ))+)$/, ' and $1');
    }

    const DisplayStar = (props) => {
        if (!props) return;
        const num = props.value;
        const index = props.index;
        const from = Math.floor(num);
        const to = Math.ceil(num);
        return (
            <>
                {num > from && num < to && from === index?
                    <MDBRatingElement itemId={index} icon='star-half-alt' size='lg'/>
                :
                    <MDBRatingElement itemId={index} icon='star' size='lg' />
                }
            </>
        )
    }

    function getRatings() {
        if (coachRating === null) return;
        let adjIndex = 0;
        if (coachRating % 1 === 0)
            adjIndex = 1;
        return (
            <>
                <MDBRating readonly defaultValue={coachRating}>
                    <DisplayStar index={adjIndex} value={coachRating} />
                    <DisplayStar index={adjIndex+1} value={coachRating} />
                    <DisplayStar index={adjIndex+2} value={coachRating} />
                    <DisplayStar index={adjIndex+3} value={coachRating} />
                    <DisplayStar index={adjIndex+4} value={coachRating} />
                </MDBRating>
            </>
        );
    }

    function getRecommendations() {
        if (recommend === null) return;
        let adjIndex = 0;
        if (recommend % 1 === 0)
            adjIndex = 1;
        return (
            <>
                <MDBRating readonly defaultValue={recommend}>
                    <DisplayStar index={adjIndex} value={recommend} />
                    <DisplayStar index={adjIndex+1} value={recommend} />
                    <DisplayStar index={adjIndex+2} value={recommend} />
                    <DisplayStar index={adjIndex+3} value={recommend} />
                    <DisplayStar index={adjIndex+4} value={recommend} />
                    <DisplayStar index={adjIndex+5} value={recommend} />
                    <DisplayStar index={adjIndex+6} value={recommend} />
                    <DisplayStar index={adjIndex+7} value={recommend} />
                    <DisplayStar index={adjIndex+8} value={recommend} />
                    <DisplayStar index={adjIndex+9} value={recommend} />
                </MDBRating>
            </>
        );
    }

    const handleCommentaryKeyPress = (ev) => {
        const MAX_CHARS = 500;
        const MAX_LINES = 10;
        if (!ev) return;
        let lines = ev.target.innerText.split('\n');
        if (ev.target.innerText &&
            (ev.target.innerText.length >= MAX_CHARS || (ev.keyCode === 13 && lines.length >= MAX_LINES)) &&
            ev.keyCode != 8 &&
            ev.keyCode != 37 && ev.keyCode != 38 && ev.keyCode != 39 &&
            ev.keyCode != 40)
            ev.preventDefault();
    }

    const ThemeChart = memo(function ThemeChart({ idx }) {
        return (
        <MDBChart
            type='bar'
            data={{
                labels: getThemeDataLabels(idx),
                datasets: getThemeData(idx),
            }}
            options={{
                maintainAspectRatio: false,
                devicePixelRatio: 2,
                plugins: {
                    tooltip: {
                        mode: 'index',
                        intersect: false,
                        axis: 'y'
                    },
                    legend: {
                        display: true,
                        position: 'right',
                        align: 'start',
                        labels: {
                            display: true,
                            boxWidth: 20,
                            boxHeight: 20,
                            font: {
                                size: 10
                            },
                            padding: 20
                        },
                    },
                },
                indexAxis: 'y',
                scales: {
                    y: {
                        stacked: false,
                        grid: {
                            display: false,
                            drawBorder: false,
                        },
                        ticks: {
                            color: 'rgba(0,0,0, 0.5)',
                        },
                        title: {
                            display: false,
                            text: "Theme"
                        },
                        afterFit: function(scaleInstance) {
                            scaleInstance.width = 100;
                        }
                    },
                    x: {
                        stacked: false,
                        grid: {
                            display: true,
                            drawBorder: true,
                        },
                        ticks: {
                            color: 'rgba(0,0,0, 0.5)',
                        },
                        title: {
                            display: true,
                            text: "Percentage uplift"
                        },
                    },
                },
            }}
        />
        );
    });

    return (
            <>
                <MDBToast
                    color='info'
                    position='top-right'
                    show
                    className='mx-auto toast-info'
                    bodyClasses='toast-info'
                    headerClasses='toast-info justify-content-between'
                    headerContent={
                        <div>
                            <MDBIcon icon="info-circle" size='lg' className='me-2 toast-info'/>
                            <strong className='me-auto' className=''>Tip</strong>
                        </div>
                    }
                    bodyContent='To print a copy of your report, right click on the report and select print. All reports are best saved as a Portrait documents except the Team Meeting Presentation which is best saved as a Landscape document.'
                />
                <div className="report" id="report">
                    <div className="report-page" style={{margin: "10px"}}>
                        <img src={Hero} className="report-hero"/>
                        <div className="summary">
                            <div className="report-heading">
                                <h1>Diagnostic Summary:</h1>
                            </div>
                            <div className="summary-row">
                                <div className="summary-col">Prepared by:</div>
                                {/*<div className="summary-col">{survey && survey.coach} {(survey && survey.co_coach.length > 0) && `and ${survey.co_coach}`}</div>*/}
                                <div className="summary-col">{coachesStr.length > 0 && coachesStr}</div>
                            </div>
                            <div className="summary-row">
                                <div className="summary-col">Report Date</div>
                                <div className="summary-col">{reportDate}</div>
                            </div>
                            <div className="summary-row">
                                <div className="summary-col">Number of Clients:</div>
                                <div className="summary-col">{chartData && chartData.company_count}</div>
                            </div>
                            <div className="summary-row">
                                <div className="summary-col">Number of Teams:</div>
                                <div className="summary-col">{chartData && chartData.team_count}</div>
                            </div>
                        </div>
                    </div>
                    <div className="report-page" style={{margin: "10px"}}>
                        <div className={`report-heading-with-page-break section-header p1`}>
                            <div>
                                <h1>Team Performance Summary</h1>
                            </div>
                        </div>
                        <div>
                            <div>
                                <div className="main-text">
                                    <p>This chart summarises the percentage uplift in team performance
                                        (as rated by all participants) at Pillar level through the period of
                                        Coaching Engagement, by team.</p>
                                </div>
                            </div>
                            <h5> {"PERILL Overview - % Uplift in Scores Over Period of Engagement".toUpperCase()} </h5>
                            <div className="composite-chart" style={{height: "750px", width: "100%", maxWidth: "100%"}}>
                                {chartData &&
                                    <MDBChart
                                        type='bar'
                                        data={{
                                            labels: getPillarLabels(),
                                            datasets: getPillarData(),
                                        }}
                                        options={{
                                            maintainAspectRatio: false,
                                            devicePixelRatio: 2,
                                            plugins: {
                                                tooltip: {
                                                    mode: 'index',
                                                    intersect: false,
                                                    axis: 'y'
                                                },
                                                legend: {
                                                    display: true,
                                                    position: 'right',
                                                    align: 'start',
                                                    labels: {
                                                        display: true,
                                                        boxWidth: 20,
                                                        boxHeight: 20,
                                                        font: {
                                                            size: 12
                                                        },
                                                        padding: 20
                                                    }
                                                },
                                            },
                                            indexAxis: 'y',
                                            scales: {
                                                y: {
                                                    stacked: false,
                                                    grid: {
                                                        display: false,
                                                        drawBorder: false,
                                                    },
                                                    ticks: {
                                                        color: 'rgba(0,0,0, 0.5)',
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: "Pillar within PERILL framework"
                                                    },
                                                },
                                                x: {
                                                    // min: pillarRange[0],
                                                    // max: pillarRange[1],
                                                    stacked: false,
                                                    grid: {
                                                        display: true,
                                                        drawBorder: true,
                                                    },
                                                    ticks: {
                                                        color: 'rgba(0,0,0, 0.5)',
                                                    },
                                                    title: {
                                                        display: true,
                                                        text: "Percentage uplift"
                                                    },
                                                },
                                            },
                                        }}
                                    />
                                }
                            </div>
                            <MDBSwitch
                                className='no-print-toggle'
                                label='Coach commentary'
                                labelClass={'no-print-toggle'}
                                onChange={() => {
                                    setOverviewCommentaryToggle(!overviewCommentaryToggle)
                                }}
                            />
                            {overviewCommentaryToggle  &&
                                <div className="summary-input-container">
                                    <div className="label">Coach Commentary</div>
                                    <div contentEditable="true" className="summary-input-content"
                                         onKeyDown={handleCommentaryKeyPress}
                                         onChange={(ev) => {
                                             setThemesCommentary1(ev.target.value);
                                         }}
                                    >{overviewCommentary}
                                    </div>
                                    <div className="form-notch">
                                        <div className="form-notch-leading"></div>
                                        <div className="form-notch-middle"></div>
                                        <div className="form-notch-trailing"></div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        <div className="report-page" style={{margin: "10px"}}>
                                    <div className={`report-heading-with-page-break section-header p1`}>
                                        <div>
                                            <h1>Team Performance by Theme</h1>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="main-text">
                                            <p>The following charts summarise the percentage uplift in team
                                                performance (as rated by all participants) at a theme level within
                                                each Pillar through the period of Coaching engagement, by team.</p>
                                        </div>
                                    </div>
                                    <MDBRow className='mt-4'>
                                        {chartData && chartData.pillars.slice(0,2).map((pillar, idx) => (
                                            <MDBCol size='12' className="roi-theme-chart" id={"theme-chart" + idx}>
                                            <h5 style={{fontSize: "14px", minHeight: "20px"}}>
                                                {pillar.pillar_name.toUpperCase()}{" - %age uplift by Theme".toUpperCase()}
                                            </h5>
                                                <ThemeChart idx={idx} />
                                            </MDBCol>
                                        ))
                                        }
                                    </MDBRow>
                                    <MDBSwitch
                                        className='no-print-toggle'
                                        label='Coach commentary'
                                        labelClass={'no-print-toggle'}
                                        onChange={() => {
                                            setThemesCommentary1Toggle(!themesCommentary1Toggle)
                                        }}
                                    />
                                    {themesCommentary1Toggle  &&
                                        <div className="summary-input-container">
                                            <div className="label">Coach Commentary</div>
                                            <div contentEditable="true" className="summary-input-content"
                                                 onKeyDown={handleCommentaryKeyPress}
                                                 onChange={(ev) => {
                                                     setThemesCommentary1(ev.target.value);
                                                 }}
                                            >{themesCommentary1}
                                            </div>
                                            <div className="form-notch">
                                                <div className="form-notch-leading"></div>
                                                <div className="form-notch-middle"></div>
                                                <div className="form-notch-trailing"></div>
                                            </div>
                                        </div>
                                    }
                                </div>
                        <div className="report-page" style={{margin: "10px"}}>
                            <div className={`report-heading-with-page-break section-header p1`}>
                                <div>
                                    <h1>Team Performance by Theme</h1>
                                </div>
                            </div>
                            <div>
                                <div className="main-text">
                                    <p>The following charts summarise the percentage uplift in team
                                        performance (as rated by all participants) at a theme level within
                                        each Pillar through the period of Coaching engagement, by team.</p>
                                </div>
                            </div>
                            <MDBRow className='mt-4'>
                                {chartData && chartData.pillars.slice(2, 4).map((pillar, idx) => (
                                    <MDBCol size='12' className="roi-theme-chart" id={"theme-chart" + idx}>
                                        <h5 style={{fontSize: "14px", minHeight: "20px"}}>
                                            {pillar.pillar_name.toUpperCase()}{" - %age uplift by Theme".toUpperCase()}
                                        </h5>
                                        <ThemeChart idx={idx+2} />
                                    </MDBCol>
                                ))
                                }
                            </MDBRow>
                            <MDBSwitch
                                className='no-print-toggle'
                                label='Coach commentary'
                                labelClass={'no-print-toggle'}
                                onChange={() => {
                                    setThemesCommentary2Toggle(!themesCommentary2Toggle)
                                }}
                            />
                            {themesCommentary2Toggle  &&
                                <div className="summary-input-container">
                                    <div className="label">Coach Commentary</div>
                                    <div contentEditable="true" className="summary-input-content"
                                         onKeyDown={handleCommentaryKeyPress}
                                         onChange={(ev) => {
                                             setThemesCommentary2(ev.target.value);
                                         }}
                                    >{themesCommentary2}
                                    </div>
                                    <div className="form-notch">
                                        <div className="form-notch-leading"></div>
                                        <div className="form-notch-middle"></div>
                                        <div className="form-notch-trailing"></div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="report-page" style={{margin: "10px"}}>
                            <div className={`report-heading-with-page-break section-header p1`}>
                                <div>
                                    <h1>Team Performance by Theme</h1>
                                </div>
                            </div>
                            <div>
                                <div className="main-text">
                                    <p>The following charts summarise the percentage uplift in team
                                        performance (as rated by all participants) at a theme level within
                                        each Pillar through the period of Coaching engagement, by team.</p>
                                </div>
                            </div>
                            <MDBRow className='mt-4'>
                                {chartData && chartData.pillars.slice(4, 6).map((pillar, idx) => (
                                    <MDBCol size='12' className="roi-theme-chart" id={"theme-chart" + idx}>
                                        <h5 style={{fontSize: "14px", minHeight: "20px"}}>
                                            {pillar.pillar_name.toUpperCase()}{" - %age uplift by Theme".toUpperCase()}
                                        </h5>
                                        <ThemeChart idx={idx+4} />
                                    </MDBCol>
                                ))
                                }
                            </MDBRow>
                            <MDBSwitch
                                className='no-print-toggle'
                                label='Coach commentary'
                                labelClass={'no-print-toggle'}
                                onChange={() => {
                                    setThemesCommentary3Toggle(!themesCommentary3Toggle)
                                }}
                            />
                            {themesCommentary3Toggle  &&
                                <div className="summary-input-container">
                                    <div className="label">Coach Commentary</div>
                                    <div contentEditable="true" className="summary-input-content"
                                         onKeyDown={handleCommentaryKeyPress}
                                         onChange={(ev) => {
                                             setThemesCommentary3(ev.target.value);
                                         }}
                                    >{themesCommentary3}
                                    </div>
                                    <div className="form-notch">
                                        <div className="form-notch-leading"></div>
                                        <div className="form-notch-middle"></div>
                                        <div className="form-notch-trailing"></div>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div>
                        <div key={"index"}>
                            <div className="report-page" style={{margin: "10px"}}>
                                <div className={`report-heading-with-page-break section-header p1`}>
                                    <div>
                                        <h1>Coach Performance Summary</h1>
                                    </div>
                                </div>
                                <div>
                                    <div className="main-text">
                                        <p>The following scores are the aggregated feedback scores you have
                                            received from team participants, as rated at the end of the coaching
                                            engagement, for all teams selected for this report.</p>
                                    </div>
                                </div>
                                <MDBRow className='mt-4'>
                                    <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row'>

                                        <MDBCol size='6' className='w-30 flex-grow-1 question text-start'>
                                            <p>Level of perceived support received through the team coaching engagement.</p>
                                        </MDBCol>
                                    </MDBCol>
                                    <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-5 ps-md-1'>
                                        <MDBCol xs='12' lg='6' className='me-5 me-md-3 me-lg-5 answer'>
                                            {getRatings()}  <h2 style={{color: colors.p3, fontWeight: "500"}}>
                                            {coachRating && coachRating.toFixed(1)}</h2>
                                        </MDBCol>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='mt-4'>
                                    <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row'>
                                        <MDBCol size='6' className='w-30 flex-grow-1 question text-start'>
                                            <p>Percentage of participants who feel that this team coach has
                                                positively influenced communication and effectiveness of the
                                                team as a whole:</p>
                                        </MDBCol>
                                    </MDBCol>
                                    <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-5 ps-md-1'>
                                        <MDBCol xs='12' lg='6' className='me-5 me-md-3 me-lg-5'>
                                            <h1 style={{color: colors.p4, fontWeight: "500"}}>{chartConfidence}%</h1>
                                        </MDBCol>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='mt-4'>
                                    <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row'>
                                        <MDBCol size='6' className='w-30 flex-grow-1 question text-start'>
                                            <p>Percentage of participants who feel that this team coach has
                                                positively influenced their own communication and
                                                effectiveness within the team:</p>
                                        </MDBCol>
                                    </MDBCol>
                                    <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-5 ps-md-1'>
                                        <MDBCol xs='12' lg='6' className='me-5 me-md-3 me-lg-5'>
                                            <h1 style={{color: colors.p4, fontWeight: "500"}}>{effectiveness}%</h1>
                                        </MDBCol>
                                    </MDBCol>
                                </MDBRow>
                                <MDBRow className='mt-4'>
                                    <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row'>

                                        <MDBCol size='6' className='w-30 flex-grow-1 question text-start'>
                                            <p>Likelihood of participant to recommend this Coach to colleagues:</p>
                                        </MDBCol>
                                    </MDBCol>
                                    <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-5 ps-md-1'>
                                        <MDBCol xs='12' lg='6' className='me-5 me-md-3 me-lg-5 answer'>
                                            {getRecommendations()}  <h2 style={{color: colors.p3, fontWeight: "500"}}>
                                            {recommend && recommend.toFixed(1)}</h2>
                                        </MDBCol>
                                    </MDBCol>
                                </MDBRow>
                            </div>
                        </div>
                    </div>
                </div>
            </>
    );
}

export default RoiReport;
