import React, { useState, useRef, useEffect } from 'react';
import { MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBSideNavCollapse, MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
import { matchPath } from "react-router";
import { useLocation, useRouteMatch } from "react-router-dom";

import {ReactComponent as Logo} from "../../assets/img/ccmi-hoz-logo-color.svg";

const SideNav = (props) => {
    const [modeOpen, setModeOpen] = useState(true);
    const [modeCollapse1, setModeCollapse1] = useState(true);
    const [modeCollapse2, setModeCollapse2] = useState(false);
    const [mode, setMode] = useState('side');
    const [activeBtn, setActiveBtn] = useState('first');
    const sidenavContent = useRef('ref');
    const [container, setContainer] = useState();
    const [slimMode, setSlimMode] = useState(false);

    useEffect(() => {
        setContainer(props.contentRef.current);
    }, []);

    const location = useLocation();
    const currentPath = location.pathname;

    const [accordionOpen, setAccordionOpen] = useState(true);
    const [collapseOpened, setCollapseOpened] = useState('');

    const toggleAccordion = (value: string) => {
        value !== collapseOpened ? setCollapseOpened(value) : setCollapseOpened('');
    };

    function CustomLink({to, children, ...props}) {
        const match = matchPath(`${currentPath}/`, {
            path: to,
            end: false,
        });

        return (
            <MDBSideNavLink
                className={match ? "active" : ""}
                href={to}
                {...props}
            >
                {children}
            </MDBSideNavLink>
        )

    }

    return (
        <div className="no-print">
        <MDBSideNav
            mode={mode}
            isOpen={modeOpen}
            constant
            getOpenState={(e) => setModeOpen(e)}
            contentRef={container}
            fixed
            slim={slimMode}
            closeOnEsc={false}
            backdrop={false}
        >
            <div className='sidenav-header'>
                <Logo style={{ height: '5rem' }} />
            </div>
            <MDBSideNavMenu>
                <MDBSideNavItem className="dashboard">
                    <CustomLink to='/dashboard'>
                        <MDBIcon fas icon='tachometer-alt' className='fa-fw me-3' />
                        Dashboard
                    </CustomLink>
                </MDBSideNavItem>
                <MDBSideNavItem className="companies">
                    <CustomLink to='/companies/'>
                        <MDBIcon far icon='building' className='fa-fw me-3' />
                        Companies
                    </CustomLink>
                </MDBSideNavItem>
                <MDBSideNavItem className="teams">
                    <CustomLink to='/teams/'>
                        <MDBIcon fas icon='users' className='fa-fw me-3' />
                        Teams
                    </CustomLink>
                </MDBSideNavItem>
                <MDBSideNavItem className="teams">
                    <CustomLink to='/participants/'>
                        <MDBIcon fas icon='user-friends' className='fa-fw me-3' />
                        Participants
                    </CustomLink>
                </MDBSideNavItem>
                <MDBSideNavItem className="surveys">
                    <CustomLink to='/surveys/'>
                        <MDBIcon fas icon='poll-h' className='fa-fw me-3' />
                        Surveys
                    </CustomLink>
                </MDBSideNavItem>
                <MDBSideNavItem className="surveys">
                    <CustomLink to='/reports/'>
                        <MDBIcon fas icon='file-invoice' className='fa-fw me-3' />
                        Reports
                    </CustomLink>
                </MDBSideNavItem>
                <MDBSideNavItem>
                    <MDBSideNavLink

                        icon='angle-down'
                        shouldBeExpanded={collapseOpened === 'accordionCollapse1'}
                        onClick={() => toggleAccordion('accordionCollapse1')}
                    >
                        <MDBIcon fas icon="external-link-alt" className='fa-fw me-3' />
                        Links
                    </MDBSideNavLink>
                    <MDBSideNavCollapse id='accordionCollapse1' show={collapseOpened === 'accordionCollapse1'}>
                        <CustomLink to='/privacy-policy' className='ms-3'  target="_blank">
                            <MDBIcon fas icon='user-secret' className='fa-fw me-3' />
                            Privacy Policy
                        </CustomLink>
                        <CustomLink className='ms-3' to='https://docs.google.com/document/d/1ZDBnQgV3Ahkh8k1OChqrTf3MvYT83XyS4RpFWHFdEoM' target="_blank">
                            <MDBIcon fas icon='question' className='fa-fw me-3' />
                            FAQ / Self Help Guide
                        </CustomLink>
                        <CustomLink className='ms-3' to='https://forms.gle/5opr6XpZCWJfST4W9' target="_blank">
                            <MDBIcon fas icon='comment-alt' className='fa-fw me-3' />
                            Feedback Form
                        </CustomLink>
                        <CustomLink className='ms-3 mb-6' to='https://clutterbuck-cmi.com/' target="_blank" >
                            <MDBIcon fas icon='home' className='fa-fw me-3' />
                            CCMI Homepage
                        </CustomLink>
                    </MDBSideNavCollapse>
                </MDBSideNavItem>

                {/*<div className='sidenav-footer text-center fixed-bottom'>*/}
                {/*    <MDBSideNavItem className="admin">*/}
                {/*        <CustomLink to='/admin/dashboard'>*/}
                {/*            <MDBIcon fas icon="chalkboard-teacher" className='me-3'/>*/}
                {/*            Admin Portal*/}
                {/*        </CustomLink>*/}
                {/*    </MDBSideNavItem>*/}
                {/*    <MDBSideNavItem className="admin">*/}
                {/*        <CustomLink to='/admin/subscriptions'>*/}
                {/*            <MDBIcon fas icon="money-bill-wave-alt" className='me-3'/>*/}
                {/*            Subscriptions*/}
                {/*        </CustomLink>*/}
                {/*    </MDBSideNavItem>*/}
                {/*</div>*/}
            </MDBSideNavMenu>
        </MDBSideNav>
        </div>
    );
}

export default SideNav
//export default withRouter(SideNav)
