import React from 'react'

const Page404 = () => {
    return (
        <>
            <p>404</p>
        </>
    )
}

export default Page404
