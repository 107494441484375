import React, { createRef, useReducer } from 'react';
import { MDBSticky } from 'mdb-react-ui-kit';

import SideNav from "../components/navigation/SideNav";
import NavBar from "../components/navigation/NavBar";
import {Dashboard} from "../pages";
import TheContent from "./TheContent";

const MainLayout = () => {

    const MainContent = React.forwardRef((props, ref) => (
        <div ref={ref} className="text-start">
            {props.children}
        </div>
    ));

    const ref = React.createRef();

    return (
        <>
            <SideNav
                key={Date.now()}
                contentRef={ref}
            />
            <MainContent
                ref={ref}
            >
                <div className="c-body">
                    <TheContent />
                </div>
            </MainContent>
        </>
    )
}

export default MainLayout
