export default function authHeader() {

    const user = JSON.parse(localStorage.getItem('user'));

    // if a call returns a 401, immediately try the /refresh endpoint (send auth and refresh tokens as headers)
    // if successful will return new object with Authorization and Refresh values
    // if fails, return user to /login

    if (user && user.Authorization) {
            return {
            'Content-Type': 'application/json',
            'Authorization': user.Authorization
        };
    } else {
        return {
            'Content-Type': 'application/json'
        };
    }
}
