import React, {useState, useEffect} from 'react';
import {
    MDBContainer,
    MDBInputGroup,
    MDBIcon,
    MDBBtn,
    MDBSwitch,
    MDBCheckbox
} from 'mdb-react-ui-kit';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import authApi from "../api/auth"
import { ReactComponent as Logo } from '../assets/img/ccmi-graphic-logo-color.svg';
import md5 from "md5";

function equalTo(ref, msg) {
    return Yup.mixed().test({
        name: 'equalTo',
        exclusive: false,
        message: msg || '${path} must be the same as ${reference}',
        params: {
            reference: ref.path,
        },
        test: function(value) {
            return value === this.resolve(ref);
        },
    });
}
Yup.addMethod(Yup.string, 'equalTo', equalTo);

const resetSchema = Yup.object().shape({
    password: Yup.string().required("Enter your password"),
    passwordConfirmation: Yup.string().equalTo(Yup.ref('password'), 'Passwords must match').required('Required'),
    uuid: Yup.string().required("You've arrived here without a token. Please check the link in the email and try again"),
});

const Reset = (props) => {

    const initialValues = {
        password: "",
        passwordConfirmation: "",
        uuid: props.uuid,
    };
    const md5=require("md5");

    const [success, setSuccess] = useState(0);

    return (
        <div id="intro" className="bg-image shadow-2-strong">
            <div className="mask d-flex align-items-center h-100" style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-5 col-md-8">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={resetSchema}
                                onSubmit={(values) => {
                                    values.password = md5(values.password);
                                    values.passwordConfirmation = null;
                                    authApi.changePassword(values);
                                    setSuccess(1);
                                    const timer = setTimeout(() => {
                                        window.location.href = "/login";
                                    }, 5000);
                                    return () => clearTimeout(timer);
                                }}
                            >
                                {(formik) => {
                                    const { errors, touched, isValid, dirty } = formik;
                                    return (
                                        <Form className="bg-white  rounded-5 shadow-5-strong login-register-form">
                                            <div className="login-register-header">
                                                <Logo style={{ height: '5rem', width: '5rem' }} />
                                                <h1>Reset your Password</h1>
                                                <p className="">If you requested a password reset, please enter your new password below.</p>
                                                <p className="">If you did not make this request, please <a href="/login">Log In</a> or close the window.</p>
                                            </div>
                                            <div className="modal-divider"></div>
                                            {!success &&
                                                <div className="p-5">
                                                    <MDBInputGroup textBefore={<MDBIcon fas icon="key" />} className="mb-4">
                                                        <Field
                                                            type="password"
                                                            placeholder="Password"
                                                            autoComplete="current-password"
                                                            id="password"
                                                            name="password"
                                                            className={
                                                                errors.password && touched.password ? "form-control is-invalid" : "form-control"
                                                            }
                                                            onBlur={() => formik.setFieldTouched('password')}
                                                        />
                                                        <Field
                                                            type="password"
                                                            placeholder="Confirm Password"
                                                            id="passwordConfirmation"
                                                            name="passwordConfirmation"
                                                            className={
                                                                errors.passwordConfirmation && touched.passwordConfirmation ? "form-control is-invalid" : "form-control"
                                                            }
                                                            onBlur={() => formik.setFieldTouched('passwordConfirmation')}
                                                        />
                                                        <ErrorMessage name="password" component="span" className="error" />
                                                        <ErrorMessage name="passwordConfirmation" component="span" className="error" />
                                                    </MDBInputGroup>
                                                    <MDBBtn
                                                        type="submit"
                                                        className={!(dirty && isValid) ? "formButton disabled-btn" : "formButton"}
                                                        disabled={!(dirty && isValid)}
                                                    >Update Password</MDBBtn>
                                                    <div className="login-register-footer">
                                                        <p>Don't need to reset? <a href='/login/'>Sign in here</a>.</p>
                                                    </div>
                                                </div>
                                            }
                                            {success &&
                                                <div className="p-5">
                                                    <p>Thank you, you can now log in. If you're not automatically redirected to the login page after 5 seconds, please <a href='/login'>click here</a>.</p>
                                                </div>
                                            }
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Reset;
