import React, { useState } from 'react';
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBCollapse,
    MDBTypography
} from 'mdb-react-ui-kit';
import {useLocation} from 'react-router-dom'

import colors from "../../config/colors";
import { ReactComponent as Logo } from '../../assets/img/ccmi-graphic-logo-color.svg';
import authApi from "../../api/auth"
import HeaderDropdown from "../../containers/HeaderDropdown";

export default function NavBar(props) {

    const {context} = props;

    const logOutUser = () => (
        authApi.logOutUser(),
            <Redirect to='/login' />
    )

    const location = useLocation();

    const user = JSON.parse(localStorage.getItem('user'));

    const isAuthenticated = () => {
        // check for token
        //const user = JSON.parse(localStorage.getItem('user'));

        if (user && user.Authorization) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <MDBNavbar
            className={context}
            expand='lg' bgColor={colors.clBlue}>
            <MDBContainer fluid>
                <div className='py-0 ms-5 font-weight-bold'>
                    <h1 className='align-middle clutterbuck'>Hello, {user.user.firstname}</h1>
                    <p>Welcome to your PERILL Diagnostics account</p>
                </div>
                {/*<MDBCollapse>*/}
                {/*    <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>*/}
                {/*        <MDBNavbarItem>*/}
                {/*            <MDBNavbarLink active aria-current='page' href='https://ccmidev.eblue-hosting.co.uk'>*/}
                {/*                CCMI Website*/}
                {/*            </MDBNavbarLink>*/}
                {/*        </MDBNavbarItem>*/}
                {/*    </MDBNavbarNav>*/}
                {/*</MDBCollapse>*/}
            </MDBContainer>
            {location.pathname === '/register' &&
            <form className='container-fluid justify-content-end'>
                <MDBTypography tag='strong' className='nav-link'>Already registered?</MDBTypography>
                <MDBBtn className='me-2' type='button'>
                    Log In
                </MDBBtn>
            </form>
            }
            <MDBContainer fluid className="align-items-start justify-content-end h-100 mt-4">
            {isAuthenticated() &&
            <HeaderDropdown />
            }
            </MDBContainer>
        </MDBNavbar>
    );
}
