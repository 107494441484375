import React from 'react'

const Page500 = () => {
    return (
        <>
            <p>500</p>
        </>
    )
}

export default Page500
