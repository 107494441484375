import React, {useState, useEffect} from 'react';
import {
    MDBInputGroup,
    MDBBtn
} from 'mdb-react-ui-kit';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {ReactComponent as Logo} from "../assets/img/ccmi-graphic-logo-color.svg";

import questionnaire from "../api/questionnaire"
import QuestionnaireStepper from "../components/QuestionnaireStepper";
import CoachFeedbackForm from "../components/CoachFeedbackForm";

const loginSchema = Yup.object().shape({
    key: Yup.string().required("Enter your access key"),
});

const Questionnaire = (props) => {

    document.body.classList.add('bg-white');

    const [surveyStarted, setSurveyStarted] = useState(false);
    const [surveyComplete, setSurveyComplete] = useState(null);
    const [endSurveyComplete, setEndSurveyComplete] = useState(null);
    const [feedbackComplete, setFeedbackComplete] = useState(null);
    const [surveyLength, setSurveyLength] = useState(0);

    const responses_data = JSON.parse(sessionStorage.getItem('responses'));
    const initialValues = {
        key: "",
        uuid: props.uuid
    };

    const md5=require("md5");

    useEffect(() => {
        if (surveyComplete) {
            console.log(responses_data);
            console.log('survey complete');
            const surveySubType = responses_data.questionnaire_survey_subtype;
            console.log('surveySubType:' + surveySubType)
            if (surveySubType === 2) {
                // loadCoachFeedback();
                setEndSurveyComplete(true);
            }
        }
    }, [surveyComplete]);

    if (responses_data == null) {
        return (
            <div id="intro" className="bg-image shadow-2-strong">
                <div className="mask d-flex align-items-center h-100" style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-md-8">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={loginSchema}
                                    onSubmit={(values) => {
                                        if (values.key.trim().length < 32) {
                                            values.key = md5(values.key.trim());
                                        }
                                        questionnaire.questionnaireLogin(values);
                                        values.key = "";
                                    }}
                                >
                                    {(formik) => {
                                        const {errors, touched, isValid, dirty} = formik;
                                        return (
                                            <Form className="bg-white  rounded-5 shadow-5-strong login-register-form">
                                                <div className="login-register-header">
                                                    <a href='https://clutterbuck-cmi.com/' target='_blank'>
                                                        <Logo style={{height: '5rem', width: '5rem'}}/>
                                                    </a>
                                                    <h1>Welcome</h1>
                                                    <p className="">Enter your access key to start your survey.</p>
                                                </div>
                                                <div className="modal-divider"></div>
                                                <div className="p-5">
                                                    <MDBInputGroup className="mb-4">
                                                        {/*<MDBInputGroupText>*/}
                                                        {/*    <MDBIcon fas icon="key"/>*/}
                                                        {/*</MDBInputGroupText>*/}
                                                        <Field
                                                            type="text"
                                                            placeholder="Access Key"
                                                            id="key"
                                                            name="key"
                                                            className={
                                                                errors.key && touched.key ? "form-control is-invalid" : "form-control"
                                                            }
                                                        />
                                                        <ErrorMessage name="key" component="span" className="error"/>
                                                    </MDBInputGroup>
                                                    <MDBBtn
                                                        type="submit"
                                                        className={!(dirty && isValid) ? "formButton disabled-btn" : "formButton"}
                                                        disabled={!(dirty && isValid)}
                                                    >Submit</MDBBtn>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {

        return (
            <>
                <div className="container flex-column rounded-9">
                    <div className="row justify-content-center">
                        {!surveyStarted &&
                            <>
                            <div className="questionnaire-header">
                                <Logo className="mb-5" style={{height: '5rem', width: '5rem'}}/>
                                <h1 className='mb-5'>Welcome.</h1>
                            </div>
                            <div className="questionnaire-intro p-0 p-sm-3">
                            <p className="mb-5">You have been invited to take part in a PERILL diagnostics {responses_data.questionnaire_stakeholder === true ? "stakeholder survey" : "diagnostics questionnaire"}.</p>
                            <p className="">There are 6 sections to complete, with {responses_data.questionnaire_questions.length/6} statements per section. It should not take you more than {(responses_data.questionnaire_questions.length <= 30) ? "15" : "45 - 60"} minutes to complete the survey in its entirety, but you can save your progress and revisit the questions at any time.</p>
                            <p className="">For each statement, please score them from 1 ( Strongly disagree) to 10 (Strongly agree) {responses_data.questionnaire_stakeholder === true ? "and you’ll also be asked to rate your confidence level in the scores that you have given from 1 (Not confident) to 5 (Very confident)." : "and then rate your confidence level in the score that you have given from 1 (Not confident) to 5 (Very confident)."}</p>
                                {responses_data.questionnaire_stakeholder === true ?
                                    <p className="">Your scores (along with the scores of all other participants) will
                                        be used to produce a PERILL report on team function and will be reported back to
                                        the issuer of the survey. Your scores will be anonymous.</p>
                                    :
                                    <p className="">Your scores will be used to produce a report on stakeholder
                                        perception of team function vs. the team perception, and will be used to help
                                        coach the team.</p>
                                }
                                <MDBBtn
                                type="button"
                                className='mt-4 mb-5'
                                onClick={() => {
                                    setSurveyStarted(true);
                                }}
                            >Begin Survey</MDBBtn>
                            </div>
                            </>
                        }
                        {surveyStarted && !surveyComplete &&
                            <QuestionnaireStepper
                                uuid={props.uuid}
                                setSurveyComplete={setSurveyComplete}
                                questionTotal={responses_data.questionnaire_questions.length}
                            />
                        }
                        {surveyComplete && !feedbackComplete &&
                            <>
                            <div className="questionnaire-header">
                                <Logo className="mb-5" style={{height: '5rem', width: '5rem'}}/>
                                <h1 className='mb-5'>You're finished.</h1>
                            </div>
                            <div className="questionnaire-intro p-0 p-sm-3">
                                <p className="mb-5">Thank you. You have now completed your diagnostics questionnaire.</p>
                                {endSurveyComplete &&
                                    <>
                                    <p>Now that you have finished this Coaching engagement, please answer the following questions
                                        on the Coaching experience and on the Diagnostic Surveys. Note: These responses will not be anonymous.</p>
                                        <CoachFeedbackForm
                                            uuid={props.uuid}
                                            setFeedbackComplete={setFeedbackComplete}
                                        />
                                    </>
                                }
                            </div>
                            </>
                        }
                        {feedbackComplete &&
                            <>
                                <div className="questionnaire-header">
                                    <Logo className="mb-5" style={{height: '5rem', width: '5rem'}}/>
                                    <h1 className='mb-5'>You're finished.</h1>
                                </div>
                                <div className="questionnaire-intro p-0 p-sm-3">
                                    <p className="mb-5">Thank you. You have now completed your diagnostics questionnaire.</p>
                                </div>
                            </>
                        }
                        </div>
                </div>
            </>
        );
    }
}

export default Questionnaire;
