import React, {useState, useEffect} from 'react';
import {
    MDBBtn,
    MDBIcon,
    MDBContainer,
    MDBTooltip,
} from 'mdb-react-ui-kit';
import {useHistory, useLocation} from "react-router-dom";
import _ from "lodash";
import {
    GroupingState,
    IntegratedGrouping,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableGroupRow,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import '@icon/open-iconic/open-iconic.css';


import surveysApi from '../api/surveys';
import participantsApi from '../api/participants';
import PageHeader from "../components/PageHeader";
import NavBar from "../components/navigation/NavBar";

const Surveys = () => {

    const [surveys, setSurveys] = useState({
        columns: [
            { title: 'ID', name: 'id', sort: true, width: 80, fixed: 'left' },
            { title: 'Survey Name', name: 'name', sort: true, width: 200, fixed: 'left', fixedValue: 80 },
            { title: 'Your Survey Description', name: 'description', sort: false },
            { title: 'Company', name: 'company_name', sort: true },
            { title: 'Company Status', name: 'status', sort: true },
            { title: 'Team', name: 'team_name', sort: true },
            { title: 'Survey Type', name: 'type', sort: true },
            { title: 'Expiration Date', name: 'expiry_date', sort: true },
            { title: 'Status', name: 'survey_status', sort: true },
        ],
        rows: [],
    });
    const [tableColumnExtensions] = useState([
        { columnName: 'id', wordWrapEnabled: true, width: 70 },
        { columnName: 'name', wordWrapEnabled: true, width: "auto" },
        { columnName: 'description', wordWrapEnabled: true, width: "auto" },
        { columnName: 'participants', wordWrapEnabled: true, width: 180 },
        { columnName: 'surveys', wordWrapEnabled: true, width: 180 },
    ]);
    const [defaultHiddenColumnNames] = useState(['id','status']);

    const user = JSON.parse(localStorage.getItem('user'));

    // history stack
    const history = useHistory()
    const queryPage = useLocation().search.match(/page=([0-9]+)/, '')
    const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1)
    const [page, setPage] = useState(currentPage)

    const printDate = raw_date => {
        const registered_date = new Date(raw_date * 1000);
        return registered_date.getDate()+
            "-"+
            ("0" + (registered_date.getMonth() + 1)).slice(-2)+
            "-"+
            registered_date.getFullYear()
    }

    const formatDate = received_date => {
        const moderated_date = new Date(received_date * 1000);
        return(
            moderated_date.getFullYear()+
            "-"+
            ("0" + (moderated_date.getMonth() + 1)).slice(-2)+
            "-"+
            moderated_date.getDate()
        )
    }

    const TableRow = ({ row, ...restProps }) => (
        <Table.Row
            {...restProps}
            // eslint-disable-next-line no-alert
            onClick={() => row.status>0 && history.push(`/surveys/${row.id}`)}
            style={{
                cursor: 'pointer',
            }}
            className={row.status===0 ? "inactive" : undefined}
        />
    );

    const loadSurveys = async () => {
        const response = await surveysApi.getSurveys(user.user.id);
        console.log(response.data)
        let surveysData = _.reverse(_.sortBy(_.sortBy(_.sortBy(response.data.surveys, [function(o) { return o.company_name; }]), [function(o) { return o.team_name; }]), [function(o) { return o.status; }]));

        _.each( surveysData, ( survey, key ) => {

            var status = '';
            if (survey.in_progress_survey_count > 0) {
                status = "In progress"
            } else if (survey.completed_survey_count > 0) {
                status = "Completed"
            } else if (survey.not_sent_survey_count > 0) {
                status = "Not sent"
            }

            var titleStr = '';
            switch (survey.sub_type) {
                case 0:
                    titleStr = 'Initial Survey - ';
                    break;
                case 1:
                    titleStr = 'Mid Point Survey - ';
                    break;
                case 2:
                    titleStr = 'End Point Survey - ';
                    break;
                case 3:
                    titleStr = 'Post Engagement Survey - ';
                    break;
                default:
            }

            titleStr += survey.name;

            setSurveys((surveys) => ({
                columns: surveys.columns,
                rows: [
                    ...surveys.rows,
                    {
                        id: survey.id,
                        name: <p>{titleStr}</p>,
                        company_name: survey.company_name,
                        status: survey.status,
                        team_name: survey.team_name,
                        description: <p>{survey.description}</p>,
                        type: <p>{survey.type === 0 ? 'Full' : 'Lite'}</p>,
                        expiry_date: <p>{printDate(survey.expiry_date)}</p>,
                        survey_status: <p>{status}</p>,
                    }],
                })
            );
        } );
    }

    useEffect(() => {
        loadSurveys();
    }, []);

    const ParticipantsCell = ({ value, style, ...restProps }) => (
        <Table.Cell
            {...restProps}
        >
            <div
                style={{
                    fontWeight: 500,
                    lineHeight: 1,
                    whiteSpace: "pre-wrap",
                    ...style,
                }}
            >{value}</div>
        </Table.Cell>
    );

    const Cell = (props) => {
        const { column } = props;
        if (column.name === 'participants') {
            return <ParticipantsCell {...props} />;
        }
        return <Table.Cell {...props} />;
    };

    return (
        <>
            <div className='text-left'>
                <NavBar context="surveys" />
                <PageHeader title='Surveys' />
                <MDBContainer className="px-5 mt-5">
                    <div className='survey'>
                        <Grid
                            rows={surveys.rows}
                            columns={surveys.columns}
                        >
                            <GroupingState
                                grouping={[
                                    { columnName: 'company_name' },
                                    { columnName: 'team_name' },
                                ]}
                            />
                            <IntegratedGrouping />
                            <Table
                                rowComponent={TableRow}
                                columnExtensions={tableColumnExtensions}
                                cellComponent={Cell}
                            />
                            <TableHeaderRow />
                            <TableColumnVisibility
                                defaultHiddenColumnNames={defaultHiddenColumnNames}
                            />
                            <TableGroupRow
                                showColumnsWhenGrouped={false}
                                className='team'
                            />
                        </Grid>
                    </div>
                    <div className="d-flex justify-content-end add-button">
                        <MDBTooltip tag='span' wrapperClass='d-inline-block px-2' placement='left' title='Create survey'>
                            <MDBBtn floating tag='a' href='/surveys/add'>
                                <MDBIcon fas icon="plus survey" />
                            </MDBBtn>
                        </MDBTooltip>
                    </div>
                </MDBContainer>
            </div>
        </>
    );
}

export default Surveys;
