import React from "react";
import {MDBTypography, MDBContainer} from "mdb-react-ui-kit";

export default function PageHeader(props) {

    return (
        // <MDBContainer>
        <div className='page-header'>
            <h1>{props.title}</h1>
        </div>
            // </MDBContainer>
    );
}
