import React, { useRef } from 'react';
import { MDBCol, MDBContainer, MDBRow, MDBScrollspy, MDBScrollspyLink, MDBScrollspySubList,
    MDBCard,
    MDBCardBody,
    MDBCardTitle,
    MDBCardText,
    MDBCardImage,
    MDBBtn,
    MDBRipple
} from 'mdb-react-ui-kit';

import {ReactComponent as Logo} from "../assets/img/ccmi-graphic-logo-color.svg";

const FAQs = (props) => {

    document.body.classList.add('bg-white');

    const section1 = useRef(null);
    const section1A = useRef(null);
    const section1B = useRef(null);
    const section1C = useRef(null);
    const section1D = useRef(null);
    const section1E = useRef(null);

    const section2 = useRef(null);
    const section2A = useRef(null);
    const section2B = useRef(null);
    const section2C = useRef(null);
    const section2D = useRef(null);

    const section3 = useRef(null);
    const section3A = useRef(null);
    const section3B = useRef(null);
    const section3C = useRef(null);
    const section3D = useRef(null);

    const section4 = useRef(null);
    const section4A = useRef(null);
    const section4B = useRef(null);
    const section4C = useRef(null);
    const section4D = useRef(null);
    const section4E = useRef(null);
    const section4F = useRef(null);
    const section4G = useRef(null);

    const section5 = useRef(null);
    const section5A = useRef(null);
    const section5B = useRef(null);
    const section5C = useRef(null);
    const section5D = useRef(null);
    const section5E = useRef(null);
    const section5F = useRef(null);
    const section5G = useRef(null);
    const section5H = useRef(null);
    const section5I = useRef(null);
    const section5J = useRef(null);
    const section5K = useRef(null);
    const section5L = useRef(null);
    const section5M = useRef(null);

    const containerRef = useRef(null);

    const subsections1 = [section1A, section1B, section1C, section1D, section1E];
    const subsections2 = [section2A, section2B, section2C, section2D];
    const subsections3 = [section3A, section3B, section3C, section3D];
    const subsections4 = [section4A, section4B, section4C, section4D, section4E, section4F, section4G];
    const subsections5 = [section5A, section5B, section5C, section5D, section5E, section5F, section5G, section5H, section5I, section5J, section5K, section5L, section5M];

    return (
        <>
            <div className="container flex-column rounded-9">
                <div className="row justify-content-center">
                    <div className="faq-header">
                        <Logo className="mb-5" style={{height: '5rem', width: '5rem'}}/>
                        <h1 className='mb-5'>Guide to Using the PERILL Team Diagnostics App</h1>
                    </div>
                    <div className="faq-row p-0 p-sm-3 mb-5">
                        <MDBContainer>
                            <h2 className='mb-5'>Video Guides</h2>
                            <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
                                <MDBCol>
                                    <MDBCard shadow='1' border='danger' className='video-card'>
                                        <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                            <MDBCardImage src='/img/how-to-sign-up.png' fluid alt='...' />
                                            <a>
                                                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                            </a>
                                        </MDBRipple>
                                        <MDBCardBody>
                                            <MDBCardTitle className='mb-3' style={{minHeight: 50}}>How to create your account</MDBCardTitle>
                                            {/*<MDBCardText>How to create your account</MDBCardText>*/}
                                            <MDBBtn href='#'>WATCH VIDEO</MDBBtn>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                                <MDBCol>
                                    <MDBCard shadow='1' border='danger' className='video-card'>
                                        <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                            <MDBCardImage src='/img/how-to-setup-diagnostic.png' fluid alt='...' />
                                            <a>
                                                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                            </a>
                                        </MDBRipple>
                                        <MDBCardBody>
                                            <MDBCardTitle className='mb-3' style={{minHeight: 50}}>How to create and issue a team diagnostic</MDBCardTitle>
                                            {/*<MDBCardText>How to create and issue a team diagnostic</MDBCardText>*/}
                                            <MDBBtn className='team' href='#'>WATCH VIDEO</MDBBtn>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                                <MDBCol>
                                    <MDBCard shadow='1' border='danger' className='video-card'>
                                        <MDBRipple rippleColor='light' rippleTag='div' className='bg-image hover-overlay'>
                                            <MDBCardImage src='/img/how-to-generate-reports.png' fluid alt='...' />
                                            <a>
                                                <div className='mask' style={{ backgroundColor: 'rgba(251, 251, 251, 0.15)' }}></div>
                                            </a>
                                        </MDBRipple>
                                        <MDBCardBody>
                                            <MDBCardTitle className='mb-3' style={{minHeight: 50}}>How to generate the diagnostic reports</MDBCardTitle>
                                            {/*<MDBCardText>How to generate the diagnostic reports</MDBCardText>*/}
                                            <MDBBtn className='survey' href='#'>WATCH VIDEO</MDBBtn>
                                        </MDBCardBody>
                                    </MDBCard>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </div>
                    <hr />
                    <div className="faq-row p-sm-3 mb-8">
                        <MDBContainer>
                            <MDBRow>
                                <h2 className='mb-5'>FAQs</h2>
                                <MDBCol md='8'>
                                    <div id='element' ref={containerRef} className='scrollspy-faq'>
                                        <section ref={section1} id='section-1'>
                                            <h3 className='text-uppercase'>Getting Started</h3>
                                            <section
                                                id='section-1A'
                                                ref={section1A}
                                            >
                                                <h4>How do I register?</h4>
                                                <p>Registration is by invite only during the pre-launch phase. Please see the email invite for details.</p>
                                                <p>There are 3 steps involved:</p>
                                                <ol>
                                                    <li>Go to the <a href="/register">registration page</a>, enter your details, create a password & enter the Access Token as provided in the invite email.</li>
                                                    <li>Once registered, you’ll be sent an email to validate your email address (entitled “Thank you for registering with CCMI”).
                                                        Click on the link to verify your account.</li>
                                                    <li>Now <a href="/register">login here</a>.</li>
                                                </ol>
                                            </section>
                                            <section
                                                id='section-1B'
                                                ref={section1B}
                                            >
                                                <h4>Creating a test team to experience it myself</h4>
                                                <p>Many coaches create an example team so they can see the full experience for themselves. That's a good idea and fine to do. Our top tips are:</p>
                                                <ul>
                                                    <li>Create a team with at least 2 of your email addresses (the minimum required to create a report)</li>
                                                    <li>Select the LITE version as its much quicker & easier</li>
                                                    <li>You must log out of the Perill platform before trying to access the diagnostic survey or the system
                                                        sees you have a valid session & takes you to the dashboard rather than the survey.</li>
                                                </ul>
                                            </section>
                                            <section
                                                id='section-1C'
                                                ref={section1C}
                                            >
                                                <h4>Getting started with a real team</h4>
                                                <p>Please watch the videos (top of page) & read this guide.</p>
                                                <p>You will need to create a Company, then a Team, then add participants to that team.</p>
                                                <p>Once completed, you can go to Surveys Tab, to select the Team and survey type.</p>
                                            </section>
                                            <section
                                                id='section-1D'
                                                ref={section1D}
                                            >
                                                <h4>I’m missing the Verification email</h4>
                                                <p>If you have successfully registered for a Perill account but have not received the follow up email
                                                    to validate your email address, it's likely to have been caught up in junk mail filters.
                                                    Please check your junk mail folder and add @clutterbuck-cmi.com to your email safe list –
                                                    the title of the email you are looking for is “Thank you for registering with CCMI”.
                                                    If you still cannot find it, please email us (<a href="mailto:diagnostics@clutterbuck-cmi.com">diagnostics@clutterbuck-cmi.com</a>) from
                                                    the email account you registered with and we can manually validate you.
                                                </p>
                                            </section>
                                            <section
                                                id='section-1E'
                                                ref={section1E}
                                            >
                                                <h4>Is there a limit on the number of companies, teams & team members I can add?</h4>
                                                <p>During your unlimited access for 12 months for the BETA trial, there’s no limits on the number of companies, teams or participants.</p>
                                            </section>
                                        </section>

                                        <section ref={section2} id='section-2'>
                                            <h3 className='text-uppercase'>Overview Questions</h3>
                                            <section
                                                id='section-2A'
                                                ref={section2A}
                                            >
                                                <h4>What is PERILL?</h4>
                                                <p>The PERILL model is the first significant attempt to apply complex, adaptive thinking to work teams.
                                                    It identified from extensive literature analysis and research, six factors that interact to drive
                                                    or hinder collective performance. At their simplest, these factors interact in three dimensions
                                                    but there will be times and situations when all six are influencing and being influenced by each other.
                                                    The six influencing factors in PERILL:</p>
                                                <ul>
                                                    <li><h5 className='p1'>Purpose & Motivation</h5>
                                                        This is about having a clear reason for being and a clear direction that energises and captures t
                                                        he imagination of team members. When individual and collective identity coincide
                                                        around a common purpose, great things are possible.
                                                    </li>
                                                    <li>
                                                        <h5 className='p2'>External Systems & Processes</h5>
                                                        How the team interacts with its various stakeholders, how it understands those stakeholders and they understand it, how the team manages conflicting expectations, obtains resources etc
                                                    </li>
                                                    <li>
                                                        <h5 className='p3'>Relationships</h5>
                                                        Factors, such as trust, respect and genuine concern for each other’s welfare, which enable close collaboration
                                                    </li>
                                                    <li>
                                                        <h5 className='p4'>Internal Systems & Processes</h5>
                                                        In particular, work design and interdependencies, communication and decision-making
                                                    </li>
                                                    <li>
                                                        <h5 className='p5'>Learning</h5>
                                                        How the team enhances its performance (how it does today’s tasks), capability (how it enhances its skills and resources to tackle tomorrow’s tasks) and capacity (how it does more with less).
                                                    </li>
                                                    <li>
                                                        <h5 className='p6'>Leadership</h5>
                                                        The moderating factor that influences whether each binary combination of other factors is expressed positively or negatively.
                                                    </li>
                                                </ul>
                                                <p>The PERILL Diagnostic offers you a unique approach to enhancing performance,
                                                    as it allows you to look at the team as a dynamic entity (beyond the individuals within it),
                                                    that has its own systems, personality, values, relationships, vision and rules.</p>
                                                <p>The insights gathered through this assessment explore the needs of the TEAM AS A COLLECTIVE,
                                                    shifting the focus to the work of the team itself rather than that of the individuals
                                                    that make up the team in the moment.</p>
                                                <p>The recommendations and exercises that come out of the insights are designed
                                                    to optimise the collective function to enhance the team productivity and performance.
                                                </p>
                                            </section>
                                            <section
                                                id='section-2B'
                                                ref={section2B}
                                            >
                                                <h4>What is the difference between PERILL DIAGNOSTICS FOR TEAMS and PERILL LITE DIAGNOSTICS FOR TEAMS?</h4>
                                                <p>The PERILL DIAGNOSTIC FOR TEAMS is designed for coaches who are accredited at practitioner team level
                                                    or above (or undergoing training). This gives you access to the full PERILL diagnostic (which comprises
                                                    120 questions across the 6 Perill pillars) and offers an in depth report of performance, strengths
                                                    and issues by pillar. It also gives full team member responses with scores on a heatmap to allow the coach
                                                    to find patterns quickly and easily.</p>
                                                <p>The PERILL LITE DIAGNOSTIC FOR TEAMS has been designed for teams to use without the need for a team coach,
                                                    and as such, it can be initiated by HR staff or by the business or team leader. The PERILL LITE diagnostic
                                                    survey comprises 30 questions that is driven by the pillars within the PERILL approach, but as the name
                                                    suggests is a light touch on PERILL and not all encompassing. The resulting report offers a short overview
                                                    of team strengths and opportunities, and provides some basic support through suggested coaching exercises
                                                    and team meeting work flows as a result of these findings.</p>
                                                <p>However, understanding and using the PERILL model is most effective when a PERILL accredited coach or
                                                    coaching organisation facilitates the discussion, so the LITE diagnostic does not go into the same level
                                                    of detail as PERILL diagnostic and the responses are kept anonymous to protect the confidentiality of the process.</p>
                                                <p>Note: If you have signed up to the full PERILL DIAGNOSTIC FOR TEAMS plan, then you also have the option to issue
                                                    the LITE diagnostic if you feel that this is more appropriate within the circumstances.</p>
                                            </section>
                                            <section
                                                id='section-2C'
                                                ref={section2C}
                                            >
                                                <h4>My client is hesitant to invest time or money in diagnostics. Do you have any advice?</h4>
                                                <p>For clients who are time poor or aren't sure on the value that they will get from PERILL DIAGNOSTICS FOR TEAMS,
                                                    the Lite option is a quick and easy introduction to PERILL for free. Using this option may help to convince
                                                    your client of the value of a full diagnostic assessment and persuade them that it is worth both
                                                    the financial and time investment.</p>
                                                <p>The Perill reports are much better value than most diagnostics in the marketplace & we chose to charge
                                                    an annual membership rather than charging by survey to help avoid this specific issue and give you
                                                    flexibility in how you might charge your clients for the diagnostic. However, our overarching message
                                                    would be that a good diagnostic report at the early stages of the team coaching will provide overall
                                                    value by identifying the hidden issues & the quick wins. In other words, it will be money very well spent.</p>
                                            </section>
                                            <section
                                                id='section-2D'
                                                ref={section2D}
                                            >
                                                <h4>What if I have some feedback?</h4>
                                                <p>Since PERILL Diagnostics for Teams is still in the pre-launch phase, we ask that you support us by providing feedback.
                                                    With your help we can make it as simple and helpful to use as possible for coaches & teams alike.
                                                    Simply provide details using this feedback form or email us at
                                                    <a href='mailto:diagnostics@clutterbuck-cmi.com'>diagnostics@clutterbuck-cmi.com</a> to
                                                    let us know your thoughts and questions.</p>
                                            </section>
                                        </section>

                                        <section ref={section3} id='section-3'>
                                            <h3 className='text-uppercase'>ABOUT THE ONLINE PERILL PLATFORM</h3>
                                            <section
                                                id='section-3A'
                                                ref={section3A}
                                            >
                                                <h4>How does the online version of the PERILL Diagnostic work?</h4>
                                                <p>PERILL DIAGNOSTICS FOR TEAMS is now available as an online option for the first time,
                                                    combining cutting edge technology with the wealth of Professor David Clutterbuck’s experience.</p>
                                                <p>Once you have signed up and created an account you can invite team members to take part in a diagnostic survey
                                                    that is based around the 6 pillars of PERILL. Once all the surveys have been completed,
                                                    you’ll generate a copy of the diagnostic report and a separate report for you to lead the team to work through
                                                    as they digest and evaluate the findings.</p>
                                                <p>Whether you are a coach, business or team Leader, you can access PERILL DIAGNOSTICS FOR TEAMS
                                                    to help you quickly and effectively understand the strengths, issues, gaps and potential challenges
                                                    that help or hinder productivity and performance of your teams.</p>
                                            </section>
                                            <section
                                                id='section-3B'
                                                ref={section3B}
                                            >
                                                <h4>What access to the platform will I have?</h4>
                                                <p>As a participant in CCMI training programs (including GTCI Practitioner Program),
                                                    you will be granted full access to the online platform via a unique access code supplied by the
                                                    GTCI support team which gives you the opportunity for unlimited team diagnostic usage for 12 months.
                                                    You can issue both the standard and Lite Perill diagnostic surveys as necessary.</p>
                                            </section>
                                            <section
                                                id='section-3C'
                                                ref={section3C}
                                            >
                                                <h4>Is there a charge to use the platform?</h4>
                                                <p>As a participant in CCMI training programs (including GTCI Practitioner Program),
                                                    after your free 12 month usage, there is an annual membership fee of £500 a year
                                                    (normally £750 per year for non students). Membership gives accredited team coaches
                                                    up to 5 teams to be diagnosed an unlimited number of times throughout the year.
                                                    Additional packages and pricing will be available for those needing extra teams.</p>
                                                <p>The Perill Lite diagnostic will be free for teams as a way of generating team coaching
                                                    demand but has substantially less functionality, information and the data is anonymous.</p>
                                            </section>
                                            <section
                                                id='section-3D'
                                                ref={section3D}
                                            >
                                                <h4>How is the personal data for my clients team members handled?</h4>
                                                <p>Clutterbuck Coaching and Mentoring Ltd (CCCMI) is committed to treating personal data
                                                    provided to us with respect and integrity which includes data you provide us when you
                                                    create an account and any personal data for the teams you add to the platform.
                                                    Prior to adding any client team member personal details, you must ensure you have permission to do so
                                                    (usually from the HR Department) and that Survey Participants have been notified in advance.
                                                    Please refer to our full <a href='/privacy-policy'>privacy policy</a> for details.</p>
                                            </section>
                                        </section>

                                        <section ref={section4} id='section-4'>
                                            <h3 className='text-uppercase'>Managing Surveys & Invites</h3>
                                            <section
                                                id='section-4A'
                                                ref={section4A}
                                            >
                                                <h4>How long does it take to complete the two diagnostic surveys?</h4>
                                                <p>THE PERILL DIAGNOSTIC FOR TEAMS is 120 questions long, with 20 questions per pillar.
                                                    This takes a participant between 45-60 minutes to complete.</p>
                                                <p>THE PERILL LITE DIAGNOSTIC FOR TEAMS is 30 questions long, with just 5 questions per pillar.
                                                    This takes a participant approximately 15 minutes to complete.</p>
                                                <p>If you are a coach that is accredited at practitioner level or above, then the longer diagnostic
                                                    will provide significant value to you in exploring issues and patterns that need to be addressed in team coaching.
                                                    However, if you are looking for an initial 'light touch' read on performance
                                                    then you may find the lite diagnostic more appropriate in the first instance.</p>
                                                <p>Please remember that minimum best practice is to run a survey at the beginning & at the end of your team coach engagement.</p>
                                            </section>
                                            <section
                                                id='section-4B'
                                                ref={section4B}
                                            >
                                                <h4>Can I include myself in a real team survey?</h4>
                                                <p>Yes you can. If your intent was to check the survey invite was sent, you do not need to complete the survey.
                                                    If you wanted to complete the survey, make sure you are logged out of the system first.
                                                    When you get to run the report, you can exclude yourself / your responses then. </p>
                                            </section>
                                            <section
                                                id='section-4C'
                                                ref={section4C}
                                            >
                                                <h4>How is the Expiry Date used and can I change it ? </h4>
                                                <p>When you create a survey, you’ll be asked to create an Expiry Date. We use this to include in the email
                                                    as a date for completing the survey. Please note that changing the Expiry date (after sending the original invites)
                                                    does not resend the invites or send out reminders. Please email the team directly for reminders.</p>
                                            </section>
                                            <section
                                                id='section-4D'
                                                ref={section4D}
                                            >
                                                <h4>How can I tell who has started or completed the survey?</h4>
                                                <p>Go into the Survey Tab, select your Team & then Survey Participants (2nd Drop down) –
                                                    you’ll see who received it, the date & the % completed by each individual.</p>
                                            </section>
                                            <section
                                                id='section-4E'
                                                ref={section4E}
                                            >
                                                <h4>How do I add a new team member to an existing team or survey?</h4>
                                                <p>Go to the Team Tab, select your Team, and using the + icon (bottom right) add the new participant to the team.</p>
                                                <p>Now go to Surveys, click the email icon next to the desired team to send just to the new person.
                                                    Please note this function does not resend to all existing team members – only to newly added ones.</p>
                                            </section>
                                            <section
                                                id='section-4F'
                                                ref={section4F}
                                            >
                                                <h4>Why are some survey invite emails not getting through & how do I fix that?</h4>
                                                <p>All platform emails are sent instantly so missing emails are likely to have been caught up in
                                                    junk mail filters for the individual (eg Gmail / Hotmail etc) or by your clients IT department.
                                                    Please ensure they look for emails entitled: ‘Your Team Coaching Survey’ from
                                                    <a href='mailto:donotreply@clutterbuck-cmi.com'>donotreply@clutterbuck-cmi.com</a> and
                                                    request that the @clutterbuck-cmi.com domain is added to the email safe list. </p>
                                            </section>
                                            <section
                                                id='section-4G'
                                                ref={section4G}
                                            >
                                                <h4>A survey participant has not received the invite. How do I resend?</h4>
                                                <p>We are developing the capability for you to resend the invites but for now only the Perill admin team can do so.
                                                    Please email us with details of the participants email address (as originally sent) & if required another
                                                    email address to send onto. Please ensure that spam filters and safe list (as advised above) has been completed.</p>
                                            </section>

                                        </section>

                                        <section ref={section5} id='section-5'>
                                            <h3 className='text-uppercase'>Managing The Diagnostic Reports</h3>
                                            <section
                                                id='section-5A'
                                                ref={section5A}
                                            >
                                                <h4>How do I generate a Team Report?</h4>
                                                <p>To generate Team Report, you’ll need to include responses from at least 2 participants.
                                                    You may include / exclude Team Members prior to generating the report (for example excluding
                                                    someone who has now left the business) & can then review this report on screen before saving to your desktop.
                                                    Note that the system will not email this report anywhere or to anyone - it simply generates a report for you
                                                    to review and action as necessary.</p>
                                            </section>
                                            <section
                                                id='section-5B'
                                                ref={section5B}
                                            >
                                                <h4>How can I save & store my Team Reports?</h4>
                                                <p>The Report section is under development still. However, you can save them to your desktop by using the print button
                                                    (under File, on you webpage / browser) & select Print /Printer.  On the print options screen, select Print to PDF
                                                    (sometimes as a dropdown alongside your printer name), Select Desktop as the location & hit Save.</p>
                                            </section>
                                            <section
                                                id='section-5C'
                                                ref={section5C}
                                            >
                                                <h4>How are the scores calculated?</h4>
                                                <p>Each member of the team is asked to score all the questions from 1 (Strongly disagree) to 10 (Strongly agree),
                                                    but also to rate how confident they are in the score they have given, from 1 (Not confident) to 5 (Very confident) –
                                                    the confidence score is there mostly to ensure they consider & reflect upon the answer
                                                    but otherwise does not apply any additional weighting. The two numbers are multiplied together.</p>
                                                <p>For each person, the minimum possible score for each question is 1 (1x1) whilst the maximum is score is 50 (10x5).</p>
                                            </section>
                                            <section
                                                id='section-5D'
                                                ref={section5D}
                                            >
                                                <h4>How are the scores shown in the reports?</h4>
                                                <p>The team scores are combined as a mean average to show the highest & lowest
                                                    scoring questions on the same 1-50 scale – the top 5 featuring in the reports.</p>
                                                <p>The third column in the reports is Variance which reflects the difference between the highest
                                                    & lowest individual participant scores as this usually shows the
                                                    greatest area of divergence (e.g. on purpose).</p>
                                            </section>
                                            <section
                                                id='section-5E'
                                                ref={section5E}
                                            >
                                                <h4>How can I tell if a score is good or bad, surely it's all relative?</h4>
                                                <p>In the PERILL DIAGNOSTICS there is a heatmap of scores which gives you a quick view
                                                    into whether the scores are high or low. However, the focus should not in itself be on the
                                                    absolute numbers but instead the relative patterns they give. Additionally, the report cannot
                                                    be aware of context which only the Coach/Team can identify which is why the patterns
                                                    pulled out in the analysis is more important.</p>
                                            </section>
                                            <section
                                                id='section-5F'
                                                ref={section5F}
                                            >
                                                <h4>Who should see the Detailed Originator Report vs. the Team Meeting Presentation?</h4>
                                                <p>As the originator of the diagnostic you will always get an Originator Report & Detailed Analysis
                                                    which gives you an in depth summary of findings. However, there is also a Team Meeting Presentation
                                                    available that simplifies and summarises the findings and guides the team through some simple exercises
                                                    in evaluating those findings.</p>
                                                <p>When a team coach initiates a diagnostic, they may share details of the detailed analysis with the leader
                                                    (either privately or in the room together with the team) but this might not be necessary or optimal and
                                                    should only be done if the team has contracted to do so in advance.</p>
                                                <p>Either way, the intent has been for the team to use the Team Meeting Report as the framework
                                                    for guided exercise during the team coaching sessions and that where appropriate,
                                                    more detailed analysis is available in the other report.</p>
                                            </section>
                                            <section
                                                id='section-5G'
                                                ref={section5G}
                                            >
                                                <h4>How is stakeholder feedback captured & how is it shown in the report?</h4>
                                                <p>For now, Stakeholder feedback is not included in the platform so you should supplement
                                                    the reports with your own stakeholder interviews for the full picture.</p>
                                            </section>
                                            <section
                                                id='section-5H'
                                                ref={section5H}
                                            >
                                                <h4>In the LITE report, why are the questions not linked back to the pillars?</h4>
                                                <p>Although the LITE DIAGNOSTIC is based on the PERILL pillars, in most instances the originator of the survey
                                                    will not have been trained and accredited in the PERILL model. As a result, the Originator Report does not
                                                    return content that is heavily dependent on a knowledge of the PERILL model.</p>
                                            </section>
                                            <section
                                                id='section-5I'
                                                ref={section5I}
                                            >
                                                <h4>Is there a simple version of the report to give to the teams?</h4>
                                                <p>Yes. As the originator of the diagnostic you will always get an Originator Report & Detailed Analysis
                                                    which gives you an in depth summary of findings. However, there is also a Team Meeting Presentation
                                                    which lays out the key scores and provides a framework for the team to safely discuss and prioritise
                                                    together with embedded insights & exercises. It has been deliberately designed to be used without
                                                    a coach but is obviously most effective with a coach / coach pair facilitating the discussion.</p>
                                            </section>
                                            <section
                                                id='section-5J'
                                                ref={section5J}
                                            >
                                                <h4>Why do I sometimes see repetition in the results & recommendations?</h4>
                                                <p>Occasionally you will find that a theme that is pulled out for highest and lowest
                                                    mean scores is also the same as the greatest variance in scores at an individual level.
                                                    This may mean some repetition of results & recommendations within the report. However,
                                                    it is important to have these noted still within the report, as it highlights a need to look
                                                    in more detail at the scoring to understand the reason for the variance.</p>
                                            </section>
                                            <section
                                                id='section-5K'
                                                ref={section5K}
                                            >
                                                <h4>Do I have to share the Detailed Originator Report and the Team Meeting Presentation?</h4>
                                                <p>No - ultimately that is your choice as the coach. The reports are there to provide guidance
                                                    & a structure for the team to work through. It is not absolute and clearly has no understanding
                                                    of the context that only the coach / team can provide.</p>
                                            </section>
                                            <section
                                                id='section-5L'
                                                ref={section5L}
                                            >
                                                <h4>Do I have to follow the agenda as set out in the Team Meeting Presentation?</h4>
                                                <p>No. This is a suggested agenda only. You can choose to run the sessions as suits you,
                                                    the team and how you want to approach the agenda. You can adapt the times and sessions as works for you.</p>
                                            </section>
                                            <section
                                                id='section-5M'
                                                ref={section5M}
                                            >
                                                <h4>Can we decide not to pursue the PERILL and PERILL lite diagnostic?</h4>
                                                <p>Of course you may choose to not pursue this route. However, you may struggle to show effective change
                                                    and levels of engagement from the team without diagnostics and performance measurement at the
                                                    start of the process vs. the end of the process.</p>
                                            </section>
                                        </section>

                                    </div>
                                </MDBCol>

                                <MDBCol md='4'>
                                    <MDBScrollspy container={containerRef} offset={40}>
                                        <MDBScrollspyLink collapsible subsections={subsections1} targetRef={section1}>
                                            Getting Started
                                        </MDBScrollspyLink>
                                        <MDBScrollspySubList collapsible={subsections1} className='ps-3'>
                                            <MDBScrollspyLink targetRef={section1A}>How do I register?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section1B}>Creating a test team to experience it myself</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section1C}>Getting started with a real team</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section1D}>I’m missing the Verification email</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section1E}>Is there a limit on the number of companies, teams & team members I can add?</MDBScrollspyLink>
                                        </MDBScrollspySubList>

                                        <MDBScrollspyLink collapsible subsections={subsections2} targetRef={section2}>
                                            Overview Questions
                                        </MDBScrollspyLink>
                                        <MDBScrollspySubList collapsible={subsections2} className='ps-3'>
                                            <MDBScrollspyLink targetRef={section2A}>How does the online version of the PERILL Diagnostic work?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section2B}>What is the difference between PERILL DIAGNOSTICS FOR TEAMS and PERILL LITE DIAGNOSTICS FOR TEAMS?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section2C}>My client is hesitant to invest time or money in diagnostics. Do you have any advice?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section2D}>What if I have some feedback?</MDBScrollspyLink>
                                        </MDBScrollspySubList>

                                        <MDBScrollspyLink collapsible subsections={subsections3} targetRef={section3}>
                                            About The Online Perill Platform
                                        </MDBScrollspyLink>
                                        <MDBScrollspySubList collapsible={subsections3} className='ps-3'>
                                            <MDBScrollspyLink targetRef={section3A}>What is PERILL?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section3B}>What access to the platform will I have?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section3C}>Is there a charge to use the platform?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section3D}>How is the personal data for my clients team members handled?</MDBScrollspyLink>
                                        </MDBScrollspySubList>

                                        <MDBScrollspyLink collapsible subsections={subsections4} targetRef={section4}>
                                            Managing Surveys & Invites
                                        </MDBScrollspyLink>
                                        <MDBScrollspySubList collapsible={subsections4} className='ps-3'>
                                            <MDBScrollspyLink targetRef={section4A}>How long does it take to complete the two diagnostic surveys?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section4B}>Can I include myself in a real team survey?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section4C}>How is the Expiry Date used and can I change it?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section4D}>How can I tell who has started or completed the survey?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section4E}>How do I add a new team member to an existing team or survey?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section4F}>Why are some survey invite emails not getting through & how do I fix that?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section4G}>A survey participant has not received the invite. How do I resend?</MDBScrollspyLink>
                                        </MDBScrollspySubList>

                                        <MDBScrollspyLink collapsible subsections={subsections5} targetRef={section5}>
                                            Managing The Diagnostic Reports
                                        </MDBScrollspyLink>
                                        <MDBScrollspySubList collapsible={subsections5} className='ps-3'>
                                            <MDBScrollspyLink targetRef={section5A}>How do I generate a Team Report?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section5B}>How can I save & store my Team Reports?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section5C}>How are the scores calculated? </MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section5D}>How are the scores shown in the reports?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section5E}>How can I tell if a score is good or bad, surely it's all relative?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section5F}>Who should see the Detailed Originator Report vs. the Team Meeting Presentation?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section5G}>How is stakeholder feedback captured & how is it shown in the report?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section5H}>In the LITE report, why are the questions not linked back to the pillars?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section5I}>Is there a simple version of the report to give to the teams?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section5J}>Why do I sometimes see repetition in the results & recommendations?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section5K}>Do I have to share the Detailed Originator Report and the Team Meeting Presentation?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section5L}>Do I have to follow the agenda as set out in the Team Meeting Presentation?</MDBScrollspyLink>
                                            <MDBScrollspyLink targetRef={section5M}>Can we decide not to pursue the PERILL and PERILL lite diagnostic?</MDBScrollspyLink>
                                        </MDBScrollspySubList>

                                    </MDBScrollspy>
                                </MDBCol>
                            </MDBRow>
                        </MDBContainer>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FAQs;
