import React, {useState, useEffect} from 'react';
import {
    MDBDatatable,
    MDBRow,
    MDBBtn,
    MDBIcon,
    MDBContainer,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter,
    MDBTooltip,
    MDBTable, MDBTableHead, MDBTableBody,
    MDBProgress, MDBProgressBar,
    MDBCheckbox,
    MDBSwitch,
    MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem, MDBCol
} from 'mdb-react-ui-kit';
import {Redirect, useHistory, useLocation} from "react-router-dom";
import _ from "lodash";
import Select from 'react-select';

import surveysApi from '../api/surveys';
import PageHeader from "../components/PageHeader";
import NavBar from "../components/navigation/NavBar";
import RoiReportGenerator from "./RoiReportGenerator";

function useForceUpdate(){
    const [value, setValue] = useState(0); // integer state
    return () => setValue(value => value + 1); // update state to force render
    // An function that increment 👆🏻 the previous state like here
    // is better than directly setting `value + 1`
}

const Reports = () => {

    const forceUpdate = useForceUpdate();

    const [sendModal, setSendModal] = useState(false);
    const [confModal, setConfModal] = useState(false);
    const [progressModal, setProgressModal] = useState(false);
    const [sendSurveyName, setSendSurveyName] = useState('');
    const [sendSurveyTeamName, setSendSurveyTeamName] = useState('');
    const [sendSurveyId, setSendSurveyId] = useState('');

    const [selectedCompany, setSelectedCompany] = useState('');
    const [companyTeams, setCompanyTeams] = useState([]);
    const [companyTeamIds, setCompanyTeamIds] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState('');
    const [selectedTeamId, setSelectedTeamId] = useState('');
    const [teamSurveys, setTeamSurveys] = useState([]);
    const [selectedSurvey, setSelectedSurvey] = useState({});
    const [isComparison, setIsComparison] = useState(false);
    const [selectedSurvey2, setSelectedSurvey2] = useState({});
    const [selectedReportType, setSelectedReportType] = useState('')
    const [availableReports, setAvailableReports] = useState([]);

    const [generateReportId, setGenerateReportId] = useState('');
    const [generateReportId2, setGenerateReportId2] = useState('');
    const [generateReportType, setGenerateReportType] = useState(null);
    const [completeCount, setCompleteCount] = useState('');
    const [reportRunnable, setReportRunnable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [includeStakeholders, setIncludeStakeholders] = useState(0);
    const [includeReportStakeholders, setIncludeReportStakeholders] = useState(true);
    const [participantsData, setParticipantsData] = useState({});

    const [surveys, setSurveys] = useState({
        columns: [
            { label: 'ID', field: 'id', sort: true, width: 80, fixed: 'left' },
            { label: 'Name', field: 'name', sort: true, width: 200, fixed: 'left', fixedValue: 80 },
            { label: 'Description', field: 'description', sort: false },
            { label: 'Company', field: 'company_name', sort: true },
            { label: 'Team', field: 'team_name', sort: true },
            { label: 'Expires', field: 'expiry_date', sort: true },
            { label: 'Actions', field: 'actions', sort: false, width: 150, fixed: 'right' },
        ],
        rows: [],
    });

    const reportTypes = [
        'PERILL - Originator Report & Detailed Analysis (Plus survey name)',
        'PERILL - Team Meeting Presentation (Plus survey name)',
        'PERILL LITE - Originator Report & Detailed Analysis (Plus survey name)',
        'PERILL LITE - Team Meeting Presentation (Plus survey name)',
        'TEAM & STAKEHOLDER OVERVIEW ((Plus survey name)',
        'TEAM ENGAGEMENT REVIEW (select mid point / end point / post engagement as taken from latest survey)',
    ];

    const [sendParticipants, setSendParticipants] = useState({
        columns: [
            { label: 'Name', field: 'name', sort: true },
            { label: 'Email', field: 'email', sort: false },
            { label: 'Sent', field: 'survey_sent_status', sort: false },
            { label: 'Percent complete', field: 'survey_percent_complete', sort: true },
        ],
        rows: [],
    });
    const [participantsProgress, setParticipantsProgress] = useState({
        columns: [
            { label: 'Name', field: 'name', sort: true },
            { label: 'Email', field: 'email', sort: false },
            { label: 'Percent complete', field: 'survey_percent_complete', sort: true },
        ],
        rows: [],
    });

    const [allReportParticipants, setAllReportParticipants] = useState({
        columns: [
            { label: 'Name', field: 'name', sort: true },
            { label: 'Email', field: 'email', sort: false },
            { label: 'Percent complete', field: 'survey_percent_complete', sort: true },
        ],
        rows: [],
    });
    const [reportParticipants, setReportParticipants] = useState([]);

    const user = JSON.parse(localStorage.getItem('user'));

    const toggleSendModal = () => setSendModal(!sendModal);
    const toggleConfModal = () => setConfModal(!confModal);
    const toggleProgressModal = () => setProgressModal(!progressModal);

    // history stack
    const history = useHistory()
    const queryPage = useLocation().search.match(/page=([0-9]+)/, '')
    const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1)
    const [page, setPage] = useState(currentPage)

    const printDate = raw_date => {
        const registered_date = new Date(raw_date * 1000);
        return registered_date.getDate()+
            "-"+
            ("0" + (registered_date.getMonth() + 1)).slice(-2)+
            "-"+
            registered_date.getFullYear()
    }

    const formatDate = received_date => {
        const moderated_date = new Date(received_date * 1000);
        return(
            moderated_date.getFullYear()+
            "-"+
            ("0" + (moderated_date.getMonth() + 1)).slice(-2)+
            "-"+
            moderated_date.getDate()
        )
    }

    useEffect(() => {
        loadSurveys();
    }, []);

    useEffect(() => {
        if (!progressModal) {
            setGenerateReportId('');
            setGenerateReportType(null);
            setReportParticipants([]);
            setParticipantsProgress([]);
            setCompleteCount('');
            setReportRunnable(false);
        }
    }, [progressModal]);

    useEffect(() => {
        generateReportType === 3 ?
            ((completeCount.pass >= 1) && (reportParticipants.length >= 1)) ? setReportRunnable(true) : setReportRunnable(false)
            :
            ((completeCount.pass >= 2) && (reportParticipants.length >= 2)) ? setReportRunnable(true) : setReportRunnable(false);
    }, [completeCount]);

    useEffect(() => {
        let active = true;

        const doStuff = async () => {
            console.log(participantsProgress);
            await setReportParticipants([]);
            if (participantsProgress && participantsProgress.length > 0) {
                _.forEach(participantsProgress, async function (p, key) {
                    if (p.percent_complete === 100) {
                        await setReportParticipants(reportParticipants => ([...reportParticipants, parseInt(p.id) ]))
                        // reportParticipants => ([...reportParticipants, parseInt(p.id) ])
                    }
                });
            }

            setCompleteCount(_.countBy(participantsProgress, (rec) => {
                return rec.percent_complete == 100 ? 'pass': 'fail';
            }));
        };

        doStuff();
        return () => {
            active = false;
        };
    }, [participantsProgress]);

    // useEffect(() => {
    //     if (!includeReportStakeholders) {
    //         setParticipantsProgress(_.reverse(_.sortBy(_.filter(allReportParticipants, function(o) {return o.role<3})), [function(o) { return o.percent_complete; }]));
    //         console.log(_.map(_.filter(allReportParticipants, function(o) {return o.role<3}), 'id'));
    //         setReportParticipants(_.map(_.filter(allReportParticipants, function(o) {return o.role<3}), 'id'));
    //         // setReportParticipants(reportParticipants => (
    //         //     reportParticipants.filter(participant => {
    //         //         return participant != participant.id;
    //         //     }))
    //         // );
    //     } else {
    //         setParticipantsProgress(_.reverse(_.sortBy(allReportParticipants, [function(o) { return o.percent_complete; }])));
    //         setReportParticipants(_.map(allReportParticipants, 'id'));
    //         /// _.filter(allReportParticipants, function(o) {return o.role<3})
    //         // setReportParticipants(reportParticipants => ([...reportParticipants, parseInt(e.target.value) ]));
    //     }
    //     console.log(participantsProgress);
    //     console.log(reportParticipants);
    // },[includeReportStakeholders]);

    useEffect(() => {
        console.log(reportParticipants);
        ((reportParticipants.length >= 2) && (completeCount.pass >= 2)) ? setReportRunnable(true) : setReportRunnable(false);
    }, [reportParticipants]);

    useEffect( () => {
        console.log(selectedCompany);
        const setAllTeams = async () => {
            await setCompanyTeams(_.uniq(_.map(_.filter(surveys.rows, { 'company_name': selectedCompany }), 'team_name')));
            await setCompanyTeamIds(_.uniq(_.map(_.filter(surveys.rows, { 'company_name': selectedCompany }), 'team_id')));
        }

        setAllTeams();

    }, [selectedCompany])

    useEffect(() => {
        console.log(companyTeams);
        // await setSelectedTeamId(_.);
    }, [companyTeams])

    useEffect(() => {
        console.log(companyTeamIds);
    }, [companyTeamIds])

    useEffect(() => {
        console.log(selectedSurvey2);
    }, [selectedSurvey2])


    useEffect(() => {
        console.log(selectedTeam);
        setTeamSurveys(_.orderBy(_.reverse(_.sortBy(_.filter(surveys.rows, {'team_id': selectedTeamId }), 'expiry_date')), "expiry_date", "asc"));
    }, [selectedTeam])

    useEffect(() => {
        console.log(teamSurveys);
    }, [teamSurveys])

    useEffect(() => {
        console.log(selectedSurvey);
        // setAvailableReports(selectedSurvey);
    }, [selectedSurvey])

    useEffect(() => {
        console.log(availableReports);
    }, [availableReports])

    useEffect(() => {
        console.log(allReportParticipants);
    }, [allReportParticipants])

    const getSurveyProgress = async (survey_id, report_type) => {
        setGenerateReportId(survey_id);
        setGenerateReportType(parseInt(report_type));
        setProgressModal(!progressModal);

        try {
            const response = await surveysApi.getSurveyProgress(survey_id);
            let participantsData = response.data.participants;

            if (parseInt(report_type) === 3) {
                // await setIncludeReportStakeholders(1);
                // setParticipantsProgress(_.sortBy(_.filter(participantsData, function(o) {return o.role===3})), [function(o) { return o.percent_complete; }]);
                await setParticipantsProgress(_.reverse(_.sortBy(participantsData, [function(o) { return o.percent_complete; }])));
                await setAllReportParticipants(_.reverse(_.sortBy(participantsData, [function(o) { return o.percent_complete; }])));
            } else {
                // if (!includeReportStakeholders) {
                await setParticipantsProgress(_.sortBy(_.filter(participantsData, function(o) {return o.role<3})), [function(o) { return o.percent_complete; }]);
                // } else {
                //     setTimeout(() => {
                //         setParticipantsProgress(_.sortBy(participantsData, [function(o) { return o.percent_complete; }]));
                //         setAllReportParticipants(_.sortBy(participantsData, [function(o) { return o.percent_complete; }]));
                //     }, 400);
                // }
            }
        } catch(error) {

        }
    }

    const getComparisonSurveyProgress = async (survey_1_id, survey_2_id, report_type) => {
        console.log("Report type: " + parseInt(report_type));
        setGenerateReportId(survey_1_id);
        setGenerateReportId2(survey_2_id);
        setGenerateReportType(parseInt(report_type));
        setProgressModal(!progressModal);

        try {
            const response = await surveysApi.getSurveyProgress(survey_2_id);
            let participantsData = response.data.participants;
            // await setParticipantsData(response.data.participants);
            console.log(participantsData);

            setParticipantsProgress(_.reverse(_.sortBy(participantsData, [function(o) { return o.percent_complete; }])));
            setAllReportParticipants(_.reverse(_.sortBy(participantsData, [function(o) { return o.percent_complete; }])));

        } catch(error) {

        }
    }

    const handleGenerateReport = async (survey_id, report_type, participants) => {

        await setProgressModal(false);

        // await setParticipantsProgress(_.sortBy(_.filter(participantsData, function(o) {return o.role<3})), [function(o) { return o.percent_complete; }]);

        let participantsPayload = {
            "participants": reportParticipants
        }

        let comparisonPayload = {
            "first_survey_id": generateReportId,
            "second_survey_id": generateReportId2,
            "participants": reportParticipants
        }

        console.log(reportParticipants);
        console.log('generateReportType: ' + generateReportType);
        // console.log('/surveys/report?s=' + generateReportId + '&p=' + encodeURIComponent(JSON.stringify(participantsPayload)));
        switch (generateReportType) {
            case 0:
                console.log('/surveys/report?s=' + generateReportId + '&p=' + encodeURIComponent(JSON.stringify(participantsPayload)));
                return history.push('/surveys/report?s=' + generateReportId + '&p=' + encodeURIComponent(JSON.stringify(participantsPayload)));
            // return <Redirect to={`/surveys/report?s=${generateReportId}&p=${encodeURIComponent(JSON.stringify(participantsPayload))}`} />
            case 1:
                console.log('/surveys/teamleaderoverviewreport?s=' + generateReportId + '&p=' + encodeURIComponent(JSON.stringify(participantsPayload)));
                return history.push('/surveys/teamleaderoverviewreport?s=' + generateReportId + '&p=' + encodeURIComponent(JSON.stringify(participantsPayload)));
            // return <Redirect to={`/surveys/teamleaderoverviewreport?s=${generateReportId}&p=${encodeURIComponent(JSON.stringify(participantsPayload))}`} />
            case 2:
                console.log('/surveys/teampresentation?s=' + generateReportId + '&p=' + encodeURIComponent(JSON.stringify(participantsPayload)));
                return history.push('/surveys/teampresentation?s=' + generateReportId + '&p=' + encodeURIComponent(JSON.stringify(participantsPayload)));
            // return <Redirect to={`/surveys/teampresentation?s=${generateReportId}&p=${encodeURIComponent(JSON.stringify(participantsPayload))}`} />
            case 3:
                console.log('/surveys/stakeholderreport?s=' + generateReportId + '&p=' + encodeURIComponent(JSON.stringify(participantsPayload)));
                return history.push('/surveys/stakeholderreport?s=' + generateReportId + '&p=' + encodeURIComponent(JSON.stringify(participantsPayload)));
            //return <Redirect to={`/surveys/stakeholderreport?s=${generateReportId}&p=${encodeURIComponent(JSON.stringify(participantsPayload))}`} />
            case 4:
                console.log('/surveys/comparisonreport?p=' + encodeURIComponent(JSON.stringify(comparisonPayload)));
                return history.push('/surveys/comparisonreport?p=' + encodeURIComponent(JSON.stringify(comparisonPayload)));
            //return <Redirect to={`/surveys/stakeholderreport?s=${generateReportId}&p=${encodeURIComponent(JSON.stringify(participantsPayload))}`} />
            default:
                return null
        }
    }

    useEffect(() => {
        console.log(surveys);
    }, [surveys]);

    const loadSurveys = async () => {
        const response = await surveysApi.getSurveys(user.user.id);
        const surveysData = response.data.surveys.filter(survey => survey.company_status === 1);
        console.log(surveysData);

        _.each( surveysData, ( survey, key ) => {

            var titleStr = '';
            switch (survey.sub_type) {
                case 0:
                    titleStr = 'Initial Survey - ';
                    break;
                case 1:
                    titleStr = 'Mid Point Survey - ';
                    break;
                case 2:
                    titleStr = 'End Point Survey - ';
                    break;
                case 3:
                    titleStr = 'Post Engagement Survey - ';
                    break;
                default:
            }

            titleStr += survey.name;

            setSurveys((surveys) => ({
                    columns: surveys.columns,
                    rows: [
                        ...surveys.rows,
                        {
                            id: survey.id,
                            name: titleStr,
                            description: survey.description,
                            company_name: survey.company_name,
                            team_name: survey.team_name,
                            team_id: survey.team_id,
                            expiry_date: printDate(survey.expiry_date),
                            type: survey.type,
                        }].map((row) => {
                        return {
                            ...row,
                            actions: (
                                <>
                                    <MDBTooltip tag='span' wrapperClass='d-inline-block' title='Generate Report'>
                                        <MDBBtn
                                            size='sm'
                                            floating
                                            outline
                                            className='message-btn ms-2'
                                            onClick={() => {
                                                getSurveyProgress(`${row.id}`,`${row.type}`);
                                            }}
                                        >
                                            <MDBIcon outline='true' fas icon='file-invoice' className='datatable-disable-onclick'/>
                                        </MDBBtn>
                                    </MDBTooltip>
                                    <MDBTooltip tag='span' wrapperClass='d-inline-block' title='Generate Team Presentation'>
                                        <MDBBtn
                                            size='sm'
                                            floating
                                            outline='true'
                                            className='message-btn ms-2'
                                            onClick={() => {
                                                getSurveyProgress(`${row.id}`, '2');
                                            }}
                                        >
                                            <MDBIcon outline='true' fas icon='chalkboard-teacher' className='datatable-disable-onclick'/>
                                        </MDBBtn>
                                    </MDBTooltip>
                                    <MDBTooltip tag='span' wrapperClass='d-inline-block' title='Generate Stakeholder Report'>
                                        <MDBBtn
                                            size='sm'
                                            floating
                                            outline
                                            className='message-btn ms-2'
                                            onClick={() => {
                                                getSurveyProgress(row.id, '3');
                                            }}
                                        >
                                            <MDBIcon outline='true' fas icon='file-invoice' className='datatable-disable-onclick'/>
                                        </MDBBtn>
                                    </MDBTooltip>
                            </>
                            ),
                        };
                    }),
                })
            );
        } );
    }

    return (
        <>
            <div className='text-left'>
                <NavBar context="reports" />
                <PageHeader title='Reports' />
                <MDBContainer className="px-5 mt-5">
                    <MDBRow>
                        <MDBCol size={6} className='flex-row'>
                            <h6 className="mb-3">To generate a report, begin by selecting a company.</h6>
                        </MDBCol>
                    </MDBRow>
                    <MDBRow>
                        <MDBCol size={6} className='flex-row'>
                            <MDBDropdown dropright group className='mb-3'>
                                <MDBDropdownToggle>Select Company</MDBDropdownToggle>
                                <MDBDropdownMenu>
                                    {(surveys.rows.length > 0) && _.uniqBy(surveys.rows, 'company_name').map((item, idx) => {
                                        return (
                                            <MDBDropdownItem key={idx} link onClick={(e) => {
                                                e.preventDefault();
                                                setSelectedCompany(item.company_name)
                                                setSelectedTeam('')
                                                setSelectedTeamId('')
                                                setSelectedSurvey({})
                                            }}>{item.company_name}</MDBDropdownItem>
                                        )
                                    })}
                                </MDBDropdownMenu>
                            </MDBDropdown>
                        </MDBCol>
                        <MDBCol size={6} className='flex-column'>
                            <h2>{selectedCompany}</h2>
                        </MDBCol>
                    </MDBRow>
                    {selectedCompany!=='' &&
                        <>
                            <MDBRow>
                                <MDBCol size={6}>
                                    <h6 className="main-text mb-3">Now select a {selectedCompany} team.</h6>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={6} className='flex-row'>
                                    <MDBDropdown dropright group className='mb-3'>
                                        <MDBDropdownToggle>Select Team</MDBDropdownToggle>
                                        <MDBDropdownMenu>
                                            {(surveys.rows.length > 0) && _.uniq(companyTeams).map((item, idx) => {
                                                console.log(companyTeamIds[idx]);
                                                return (
                                                    <MDBDropdownItem key={idx} link onClick={(e) => {
                                                        e.preventDefault();
                                                        setSelectedTeam(item)
                                                        setSelectedTeamId(companyTeamIds[idx])
                                                        setSelectedSurvey({})
                                                    }}>{item}</MDBDropdownItem>
                                                )
                                            })}
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBCol>
                                <MDBCol size={6} className='flex-column'>
                                    <h2>{selectedTeam}</h2>
                                </MDBCol>
                            </MDBRow>
                        </>
                    }
                    {selectedTeam !== '' &&
                        <>
                            <MDBRow>
                                <MDBCol size={6}>
                                    <h6 className="main-text mb-3">Now select a {selectedTeam} team survey. If you are creating an Engagement Review, select the Initial survey.</h6>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={6}>
                                    <MDBDropdown dropright group className='mb-3'>
                                        <MDBDropdownToggle>Select Survey</MDBDropdownToggle>
                                        <MDBDropdownMenu>
                                            {(surveys.rows.length > 0) && teamSurveys.map((item, idx) => {
                                                return (
                                                    <MDBDropdownItem key={idx} link onClick={(e) => {
                                                        e.preventDefault();
                                                        setSelectedSurvey(item)
                                                    }}>{item.name}</MDBDropdownItem>
                                                )
                                            })}
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBCol>
                                <MDBCol size={6} className='flex-column'>
                                    <h2>{selectedSurvey.name}</h2>
                                </MDBCol>
                            </MDBRow>
                        </>
                    }
                    {Object.keys(selectedSurvey).length > 0 &&
                        <div>
                            <MDBRow>
                                <MDBCol size={6}>
                                    <h6 className="main-text mb-3">Now select a report to generate.</h6>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={6}>
                                    <MDBDropdown dropright group className='mb-3'>
                                        <MDBDropdownToggle>Select Report Type</MDBDropdownToggle>
                                        <MDBDropdownMenu>
                                                <MDBDropdownItem link onClick={(e) => {
                                                    e.preventDefault();
                                                    getSurveyProgress(`${selectedSurvey.id}`,`${selectedSurvey.type === 0 ? '0' : '1'}`);
                                            }}>PERILL {selectedSurvey.type === 1 && 'LITE '} - Originator Report & Detailed Analysis ({selectedSurvey.name})</MDBDropdownItem>
                                                <MDBDropdownItem link onClick={(e) => {
                                                    e.preventDefault();
                                                    getSurveyProgress(`${selectedSurvey.id}`, '2');
                                            }}>PERILL {selectedSurvey.type === 1 && 'LITE '}  - Team Meeting Presentation ({selectedSurvey.name})</MDBDropdownItem>
                                                <MDBDropdownItem link onClick={(e) => {
                                                    e.preventDefault();
                                                    getSurveyProgress(selectedSurvey.id, '3');
                                            }}>TEAM & STAKEHOLDER OVERVIEW ({selectedSurvey.name})</MDBDropdownItem>
                                                <MDBDropdownItem key={selectedSurvey.id} link onClick={(e) => {
                                                    e.preventDefault();
                                                    console.log(_.filter(_.without(teamSurveys, selectedSurvey), function(o) { return o.expiry_date >= selectedSurvey.expiry_date; }));
                                                    if (_.filter(_.without(teamSurveys, selectedSurvey), function(o) { return o.expiry_date >= selectedSurvey.expiry_date; }).length > 0) {
                                                        setIsComparison(!isComparison);
                                                        setSelectedReportType('Engagement Review')
                                                    } else {
                                                        setSelectedSurvey({});
                                                        alert("There are no surveys for the " + selectedTeam + " team which are after the survey you've selected as the Initial survey.");
                                                        forceUpdate();
                                                    }
                                            }}>TEAM ENGAGEMENT REVIEW (select mid point / end point / post engagement as taken from latest survey) ({selectedSurvey.name})</MDBDropdownItem>
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBCol>
                                <MDBCol size={6} className='flex-column'>
                                    <h2>{selectedReportType}</h2>
                                </MDBCol>
                            </MDBRow>
                        </div>
                    }

                    {isComparison &&
                        <>
                            <MDBRow>
                                <MDBCol size={6}>
                                    <h6 className="main-text mb-3">Please select a 2nd (End Point) survey for the Engagement Review.</h6>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol size={6}>
                                    <MDBDropdown dropright group className='mb-3'>
                                        <MDBDropdownToggle>Select 2nd Survey</MDBDropdownToggle>
                                        <MDBDropdownMenu>
                                            {(surveys.rows.length > 0) && _.without(teamSurveys, selectedSurvey).map((item, idx) => {
                                                if (item.expiry_date >= selectedSurvey.expiry_date) {
                                                    return (
                                                        <MDBDropdownItem key={idx} link onClick={(e) => {
                                                            e.preventDefault();
                                                            setSelectedSurvey2(item);
                                                            getComparisonSurveyProgress(selectedSurvey.id, item.id, '4');
                                                        }}>{item.name}</MDBDropdownItem>
                                                    )
                                                } else {
                                                    return(<></>);
                                                }
                                            })}
                                        </MDBDropdownMenu>
                                    </MDBDropdown>
                                </MDBCol>
                                <MDBCol size={6} className='flex-column'>
                                    <h2>{selectedSurvey2.name}</h2>
                                </MDBCol>
                            </MDBRow>
                        </>
                    }

                </MDBContainer>
            </div>

            <MDBModal show={sendModal} setShow={setSendModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Are you sure?</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleSendModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>Below are all {generateReportType === 3 ? 'stakeholders' : 'participants'} in the {sendSurveyName} survey. You can see who's received it already, when it was sent and each person's progress.</p>
                            <p>Clicking the "Email Survey" button below will only send to any newly added team member. It does not resend the original survey invites.
                                Whilst we develop this capability, please contact <a href={"mailto:diagnostics@clutterbuck-cmi.com"}>diagnostics@clutterbuck-cmi.com</a> with the email addresses you need resending to.</p>
                            <MDBSwitch
                                id='flexSwitchCheckDefault'
                                label='Would you like to invite the Team Stakeholders to complete a survey?'
                                checked={includeStakeholders}
                                onChange={(e) => {
                                    (e.target.checked === true) ? setIncludeStakeholders(1) : setIncludeStakeholders(0);
                                }}
                            />
                            <p>Do you wish to continue?</p>

                            <div className="d-flex justify-content-start">
                                <MDBTable striped>
                                    <MDBTableHead>
                                        <tr>
                                            <th scope='col'>Name</th>
                                            <th scope='col'>Email</th>
                                            <th scope='col'>Sent</th>
                                            <th scope='col'>% Complete</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {(sendParticipants.length > 0) && sendParticipants.map((participant, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <th scope='row'>{participant.name}</th>
                                                    <td>{participant.email}</td>
                                                    <td>{participant.survey_sent_status ? formatDate(participant.survey_sent_status) : "Not sent"}</td>
                                                    <td>
                                                        <MDBProgress height='20' className='progress-bar-rail'>
                                                            <MDBProgressBar width={participant.survey_percent_complete} valuemin={0} valuemax={100}>
                                                                {participant.survey_percent_complete}%
                                                            </MDBProgressBar>
                                                        </MDBProgress>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleSendModal}>
                                I'm not ready
                            </MDBBtn>
                            <MDBBtn
                                type="submit"
                                onClick={() => {
                                    handleSend()
                                }}
                            >Email survey</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal show={confModal} setShow={setConfModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Survey sent</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleConfModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            {sendSurveyName} has been sent to {sendSurveyTeamName}.
                            <div className="d-flex justify-content-end add-button">
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='primary' onClick={toggleConfModal}>
                                Close
                            </MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            <MDBModal
                // nonInvasive={true}
                show={progressModal}
                setShow={setProgressModal}
                tabIndex='-1'
            >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Generate {generateReportType===2 ? "Team Presentation" : "Report"}</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleProgressModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>Below are all participants in the {sendSurveyName} survey, showing their
                                progress.</p>
                            <p>To generate a {generateReportType===2 ? "presentation" : "report"}, you must select at least 2 participants with completed questionnaires (or the generate {generateReportType===2 ? "presentation" : "report"} button will remain disabled).</p>
                            <p>Only include valid team members so you don’t skew the results. Once generated, you can review the reports and save them to your desktop – they are not emailed to you or the participants.</p>
                            {/*{generateReportType !== 3 &&*/}
                            {/*    <MDBSwitch*/}
                            {/*        id='includeReportStakeholders'*/}
                            {/*        label='Include stakeholders in the report?'*/}
                            {/*        checked={includeReportStakeholders}*/}
                            {/*        onChange={(e) => {*/}
                            {/*            (e.target.checked === true) ? setIncludeReportStakeholders(1) : setIncludeReportStakeholders(0);*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*}*/}
                            <div className="d-flex justify-content-start">
                                <MDBTable striped>
                                    <MDBTableHead>
                                        <tr>
                                            <th scope='col'>Name</th>
                                            <th scope='col'>Email</th>
                                            <th scope='col'>% Complete</th>
                                            <th scope='col'>Include in report</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {/*{(participantsProgress && participantsProgress.length > 0 && (participantsProgress.length === reportParticipants.length)) && participantsProgress.map((participant, idx) => {*/}
                                        {(participantsProgress && participantsProgress.length > 0) && participantsProgress.map((participant, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <th scope='row'>{participant.name}</th>
                                                    <td>{participant.email}</td>
                                                    <td>
                                                        <MDBProgress height='20' className='progress-bar-rail'>
                                                            <MDBProgressBar width={participant.percent_complete} valuemin={0} valuemax={100}>
                                                                {participant.percent_complete}%
                                                            </MDBProgressBar>
                                                        </MDBProgress>
                                                    </td>
                                                    <td>
                                                        {(participantsProgress && participantsProgress.length > 2) &&
                                                            <MDBCheckbox
                                                                name='flexCheck'
                                                                value={participant.id}
                                                                id='flexCheckDefault'
                                                                disabled={participant.percent_complete === 100 ? false : true}
                                                                defaultChecked={participant.percent_complete === 100 ? true : false}
                                                                onChange={async (e) => {
                                                                    if (e.target.checked) {
                                                                        setReportParticipants(reportParticipants => ([...reportParticipants, parseInt(e.target.value)]))
                                                                    } else {
                                                                        setReportParticipants(reportParticipants => (
                                                                            reportParticipants.filter(participant => {
                                                                                return participant != e.target.value;
                                                                            }))
                                                                        )
                                                                    }
                                                                }}
                                                            />
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleProgressModal}>
                                I'm not ready
                            </MDBBtn>
                            <MDBBtn
                                type="submit"
                                disabled={!reportRunnable}
                                onClick={() => {
                                    handleGenerateReport();
                                }}
                            >Generate {generateReportType===2 ? "Presentation" : "Report"}</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}

export default Reports;
