import client from "./client";

const questionnaireEndpoint = '/questionnaires/';
const questionnaireResponsesEndpoint = '/questionnaire_responses/';
const questionnaireFeedbackEndpoint = '/feedback';

const user = JSON.parse(localStorage.getItem('user'));

const questionnaireLogin = (values) => {
    return client.post(questionnaireEndpoint + values.uuid, JSON.stringify(values))
        .then((response) => {
            console.log(response.data);
            if (response.data) {
                sessionStorage.setItem("responses", JSON.stringify(response.data));
                window.location.reload();
            }
        }, (error) => {
            // console.log(error);
        });
};

const questionnaireResponses = (uuid, payload) => {
    return client.post(questionnaireResponsesEndpoint + uuid, JSON.stringify(payload))
        .then((response) => {
            if (response.ok) {
                //sessionStorage.setItem("responses", JSON.stringify(payload.responses));
                //console.log(JSON.parse(sessionStorage.getItem('responses')));
                window.location.reload();
            }
        }, (error) => {
            // console.log(error);
        });
};

const questionnaireFeedback = (payload) => {
    return client.post(questionnaireFeedbackEndpoint, JSON.stringify(payload))
        .then((response) => {
            return response;
        }, (error) => {
            // console.log(error);
        });
};


export default {
    questionnaireLogin,
    questionnaireResponses,
    questionnaireFeedback
};
