import React, {useReducer, useState, useEffect} from 'react';
import {Router, Route, Switch, Redirect, useLocation} from 'react-router-dom';
import { createBrowserHistory } from 'history';
import {MDBSpinner} from "mdb-react-ui-kit";

const history = createBrowserHistory();

// containers
import MainLayout from "./containers/MainLayout";

// base pages
import {
    Login,
    Page404,
    Page500,
    Questionnaire,
    Register,
    ResetPassword,
    Reset,
    Privacy,
    FAQs
} from "../src/pages/index"

const isAuthenticated = () => {
    // check for token
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.Authorization) {
        return true;
    } else {
        return false;
    }
}

const UnauthenticatedRoute = ({ component: Component, uuid, ...rest }) => (
    <Route {...rest} render={(props) => (
        !isAuthenticated()
            ? <Component {...props} uuid={uuid} />
            : <Redirect to='/' />
    )} />
);

const AuthenticatedRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={(props) => (
        isAuthenticated()
            ? <Component {...props} />
            : <Redirect to='/login' />
    )} />
);

const App = () => {

    const dispatchContainerEvent = (actionType, payload) => {
        // in case we need it
        // switch (actionType) {
        //   default:
        //     return;
        // }
    };

    // state for containerRef (for sidebar)
    const [state, setState] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            containerRef: null
        }
    )


    return (
        <Router history={history}>
            <RouteSwitch />
        </Router>
    );
};

// get query string
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function RouteSwitch () {

    let query = useQuery();
    const uuid=query.get("v");

    const loading = (
        <div className="pt-3 text-center">
            <MDBSpinner role='status'>
                <span className='visually-hidden'>Loading...</span>
            </MDBSpinner>
        </div>
    )

    return (
        <React.Suspense fallback={loading}>
            <Switch>
                <UnauthenticatedRoute exact path="/questionnaire" name="Questionnaire" component={Questionnaire} uuid={uuid}/>
                <UnauthenticatedRoute exact path="/login" name="Login Page" component={Login} uuid={uuid} />
                <UnauthenticatedRoute exact path="/register" name="Register Page" component={Register} />
                <UnauthenticatedRoute exact path="/resetpassword" name="Reset Password" component={ResetPassword} />
                <UnauthenticatedRoute exact path="/reset" name="Change Your Password" component={Reset} uuid={uuid} />
                <UnauthenticatedRoute exact path="/privacy" name="Privacy Policy" component={Privacy} />
                <UnauthenticatedRoute exact path="/faqs" name="FAQs" component={FAQs} />
                <UnauthenticatedRoute exact path="/404" name="Page 404" component={Page404} />
                <UnauthenticatedRoute exact path="/500" name="Page 500" component={Page500} />
                <AuthenticatedRoute path="/" name="Home" component={MainLayout} />

                <Route exact path="/login" name="Login Page" render={props => <Login {...props} uuid={uuid}/>} />
                <Route exact path="/questionnaire" name="Questionnaire" render={props => <Questionnaire {...props} uuid={uuid}/>} />
                <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
                <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
                <Route exact path="/privacy-policy" name="Privacy Policy" render={props => <Privacy {...props}/>} />
                <Route exact path="/help" name="Help / FAQ" render={props => <FAQs {...props}/>} />
                {/*<Route exact path="/reset" name="Change Your Password" render={props => <Reset {...props}/>} />*/}

                <Route exact path="/" component={MainLayout} />

                {/*<Route*/}
                {/*    render={function () {*/}
                {/*        return <h1>Not Found</h1>;*/}
                {/*    }}*/}
                {/*/>*/}

            </Switch>
        </React.Suspense>
    );
}

export default App;
