import React from 'react';
import {ErrorMessage, Field, Form, useFormik, FormikProvider} from "formik";
import {
    MDBBtn,
    MDBRow,
    MDBCol,
    MDBValidationItem,
    MDBTextArea
} from "mdb-react-ui-kit";
import * as Yup from "yup";
import ScoreSelector from "./ScoreSelector";
import Select from "react-select";

import questionnaire from "../api/questionnaire"

const feedbackSchema = Yup.object().shape({
    // firstname: Yup.string().required("Enter your first name"),
});

function CoachFeedbackForm(props) {
    const setFeedbackComplete = props.setFeedbackComplete;

    const formik = useFormik({
        //enableReinitialize: true,
        initialValues: {
            uuid: props.uuid,
            q1: "",
            q2: "",
            q3: "",
            q4: "",
            q5: "",
            q6: "",
            q7: "",
            q8: "",
            q9: "",
            q10: "",
            q11: "",
            q12: "",
            q13: "",
        },
        validationSchema: feedbackSchema,
        onSubmit: async (values) => {
            console.log(values);
            const response = await questionnaire.questionnaireFeedback(values);
            if (response.status === 200) {
                setFeedbackComplete(true);
            } else if (response.status === 400) {

            }
        },
    });

    const { errors, touched, isValid, dirty, values } = formik;

    const handleChange = (e: any) => {
        formik.setFieldValue(e.target.name, e.target.value);
    };

    return (
        <FormikProvider value={formik}>
            <Form
                className="form-horizontal"
                onSubmit={formik.handleSubmit}
            >
                        <div className="pt-5">
                            <h3 className='mb-6'>FEEDBACK ON YOUR TEAM COACH / TEAM COACH PAIR:</h3>
                            <input
                                name='uuid'
                                id='uuid'
                                type='hidden'
                                className='border-0'
                                required
                                value={props.uuid}
                            />
                            <MDBRow className='feedback-question-row'>
                                <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row'>
                                    <MDBCol size='1' className='flex-shrink-1'>
                                        <p>1.</p>
                                    </MDBCol>
                                    <MDBCol size='5' className='w-30 flex-grow-1 question text-start'>
                                        <p>How would you rate the support that you have received overall through this team coaching engagement? (1 lowest, 5 highest)</p>
                                    </MDBCol>
                                </MDBCol>
                                <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-md-1'>
                                    <MDBCol xs='12' lg='6' className='me-md-3 me-lg-5 text-center'>
                                        <MDBValidationItem invalid feedback='invalid' className='border-0' >
                                            <input
                                                name='q1'
                                                id='q1'
                                                type='hidden'
                                                className='border-0'
                                                required
                                                // value={question.score}
                                            />
                                        </MDBValidationItem>
                                        <ScoreSelector
                                            size='md'
                                            id='q1'
                                            range="5"
                                            className="score-rating"
                                            // label="score"
                                            color='#0082c2'
                                        />
                                    </MDBCol>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className='feedback-question-row'>
                                <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row'>
                                    <MDBCol size='1' className='flex-shrink-1'>
                                        <p>2.</p>
                                    </MDBCol>
                                    <MDBCol size='5' className='w-30 flex-grow-1 question text-start'>
                                        <p>Has working with this team coach positively influenced the communication and effectiveness of your team?</p>
                                    </MDBCol>
                                </MDBCol>
                                <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-md-1'>
                                    <MDBCol xs='12' lg='12' className='me-md-3 me-lg-5 align-items-center justify-content-center'>
                                        <Select
                                            options={[
                                                {value: 'Yes', label: 'Yes'},
                                                {value: 'No', label: 'No'},
                                                {value: 'Not Sure', label: 'Not sure'},
                                            ]}
                                            className='selectable mb-3'
                                            // placeholder={<span>Survey type <span className='p2'>*</span></span>}
                                            formatOptionLabel={option => <div className="needsclick">{option.label}</div>}
                                            // onBlur={handleBlur}
                                            onChange={(e) => {
                                                e && formik.setFieldValue('q2', e.value);
                                            }}
                                        />
                                    </MDBCol>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className='feedback-question-row'>
                                <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row'>
                                    <MDBCol size='1' className='flex-shrink-1'>
                                        <p>3.</p>
                                    </MDBCol>
                                    <MDBCol size='5' className='w-30 flex-grow-1 question text-start'>
                                        <p>Has working with this team coach positively influenced your individual communication and effectiveness within your team ?</p>
                                    </MDBCol>
                                </MDBCol>
                                <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-md-1'>
                                    <MDBCol xs='12' lg='12' className='me-md-3 me-lg-5 align-items-center justify-content-center'>
                                        <Select
                                            options={[
                                                {value: 'Yes', label: 'Yes'},
                                                {value: 'No', label: 'No'},
                                                {value: 'Not Sure', label: 'Not sure'},
                                            ]}
                                            className='selectable mb-3'
                                            // placeholder={<span>Survey type <span className='p2'>*</span></span>}
                                            formatOptionLabel={option => <div className="needsclick">{option.label}</div>}
                                            // onBlur={handleBlur}
                                            onChange={(e) => {
                                                e && formik.setFieldValue('q3', e.value);
                                            }}
                                        />
                                    </MDBCol>
                                </MDBCol>
                            </MDBRow>


                            <MDBRow className='feedback-question-row'>
                                <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row'>
                                    <MDBCol size='1' className='flex-shrink-1'>
                                        <p>4.</p>
                                    </MDBCol>
                                    <MDBCol size='5' className='w-30 flex-grow-1 question text-start'>
                                        <p>How likely would you be to recommend this Coach / Coach pair to another team or colleague? (1 - 10)</p>
                                    </MDBCol>
                                </MDBCol>
                                <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-md-1'>
                                    <MDBCol xs='12' lg='12' className=' me-md-3 me-lg-5 align-items-center justify-content-center'>
                                        <MDBValidationItem invalid feedback='invalid' className='border-0' >
                                            <input
                                                name='q4'
                                                id='q4'
                                                type='hidden'
                                                className='border-0'
                                                required
                                                // value={question.score}
                                            />
                                        </MDBValidationItem>
                                        <ScoreSelector
                                            size='md'
                                            id='q4'
                                            range="10"
                                            className="score-rating"
                                            color='#0082c2'
                                        />
                                    </MDBCol>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className='feedback-question-row'>
                                <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row'>
                                    <MDBCol size='1' className='flex-shrink-1'>
                                        <p>5.</p>
                                    </MDBCol>
                                    <MDBCol size='5' className='w-30 flex-grow-1 question text-start'>
                                        <p>What would have made the team coaching experience more valuable for you?</p>
                                    </MDBCol>
                                </MDBCol>
                                <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-md-1'>
                                    <MDBCol xs='12' lg='12' className=' me-md-3 me-lg-5 align-items-center justify-content-center'>
                                        <MDBTextArea
                                            label='Suggestions'
                                            id='q5'
                                            onChange={handleChange}
                                            onBlur={() => formik.setFieldTouched('q5')}
                                            name='q5'
                                            rows={4}
                                            className="mb-3"
                                        />
                                    </MDBCol>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className='feedback-question-row'>
                                <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row'>
                                    <MDBCol size='1' className='flex-shrink-1'>
                                        <p>6.</p>
                                    </MDBCol>
                                    <MDBCol size='5' className='w-30 flex-grow-1 question text-start'>
                                        <p>What was the most confusing or challenging part of the team coaching experience?</p>
                                    </MDBCol>
                                </MDBCol>
                                <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-md-1'>
                                    <MDBCol xs='12' lg='12' className=' me-md-3 me-lg-5 align-items-center justify-content-center'>
                                        <MDBTextArea
                                            label='Challenges'
                                            id='q6'
                                            onChange={handleChange}
                                            onBlur={() => formik.setFieldTouched('q6')}
                                            name='q6'
                                            rows={4}
                                            className="mb-3"
                                        />
                                    </MDBCol>
                                </MDBCol>
                            </MDBRow>

                            <MDBRow className='feedback-question-row'>
                                <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row'>
                                    <MDBCol size='1' className='flex-shrink-1'>
                                        <p>7.</p>
                                    </MDBCol>
                                    <MDBCol size='5' className='w-30 flex-grow-1 question text-start'>
                                        <p>Is there anything else you think we should know?</p>
                                    </MDBCol>
                                </MDBCol>
                                <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-md-1'>
                                    <MDBCol xs='12' lg='12' className='me-md-3 me-lg-5 align-items-center justify-content-center'>
                                        <MDBTextArea
                                            label='Anything else'
                                            id='q7'
                                            onChange={handleChange}
                                            onBlur={() => formik.setFieldTouched('q7')}
                                            name='q7'
                                            rows={4}
                                            className="mb-3"
                                        />
                                    </MDBCol>
                                </MDBCol>
                            </MDBRow>

                            <h3 className='mb-6'>FEEDBACK ON DIAGNOSTIC SURVEY & REPORTS:</h3>
                            <MDBRow className='feedback-question-row'>
                                <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row'>
                                    <MDBCol size='1' className='flex-shrink-1'>
                                        <p>1.</p>
                                    </MDBCol>
                                    <MDBCol size='5' className='w-30 flex-grow-1 question text-start'>
                                        <p>How easy was it to access the diagnostic survey? (1 not easy, 10 very easy)</p>
                                    </MDBCol>
                                </MDBCol>
                                <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-md-1'>
                                    <MDBCol xs='12' lg='6' className='me-md-3 me-lg-5'>
                                        <MDBValidationItem invalid feedback='invalid' className='border-0' >
                                            <input
                                                name='q8'
                                                id='q8'
                                                type='hidden'
                                                className='border-0'
                                                required
                                                // value={question.score}
                                            />
                                        </MDBValidationItem>
                                        <ScoreSelector
                                            size='md'
                                            id='q8'
                                            range="10"
                                            className="score-rating"
                                            // label="score"
                                            color='#0082c2'
                                        />
                                    </MDBCol>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='feedback-question-row'>
                                <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row'>
                                    <MDBCol size='1' className='flex-shrink-1'>
                                        <p>2.</p>
                                    </MDBCol>
                                    <MDBCol size='5' className='w-30 flex-grow-1 question text-start'>
                                        <p>How easy was it to complete the diagnostic survey? (1 not easy, 10 very easy)</p>
                                    </MDBCol>
                                </MDBCol>
                                <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-md-1'>
                                    <MDBCol xs='12' lg='6' className='me-md-3 me-lg-5'>
                                        <MDBValidationItem invalid feedback='invalid' className='border-0' >
                                            <input
                                                name='q9'
                                                id='q9'
                                                type='hidden'
                                                className='border-0'
                                                required
                                                // value={question.score}
                                            />
                                        </MDBValidationItem>
                                        <ScoreSelector
                                            size='md'
                                            id='q9'
                                            range="10"
                                            className="score-rating"
                                            // label="score"
                                            color='#0082c2'
                                        />
                                    </MDBCol>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='feedback-question-row'>
                                <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row'>
                                    <MDBCol size='1' className='flex-shrink-1'>
                                        <p>3.</p>
                                    </MDBCol>
                                    <MDBCol size='5' className='w-30 flex-grow-1 question text-start'>
                                        <p>How effective were the PERILL diagnostic reports in helping you and your team to
                                            understand and improve its communication and effectiveness ? (1-10)</p>
                                    </MDBCol>
                                </MDBCol>
                                <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-md-1'>
                                    <MDBCol xs='12' lg='6' className='me-md-3 me-lg-5'>
                                        <MDBValidationItem invalid feedback='invalid' className='border-0' >
                                            <input
                                                name='q10'
                                                id='q10'
                                                type='hidden'
                                                className='border-0'
                                                required
                                                // value={question.score}
                                            />
                                        </MDBValidationItem>
                                        <ScoreSelector
                                            size='md'
                                            id='q10'
                                            range="10"
                                            className="score-rating"
                                            // label="score"
                                            color='#0082c2'
                                        />
                                    </MDBCol>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='feedback-question-row'>
                                <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row'>
                                    <MDBCol size='1' className='flex-shrink-1'>
                                        <p>4.</p>
                                    </MDBCol>
                                    <MDBCol size='5' className='w-30 flex-grow-1 question text-start'>
                                        <p>How effective was your team coach in making a positive impact on your team performance?(1-10)</p>
                                    </MDBCol>
                                </MDBCol>
                                <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-md-1'>
                                    <MDBCol xs='12' lg='6' className='me-md-3 me-lg-5'>
                                        <MDBValidationItem invalid feedback='invalid' className='border-0' >
                                            <input
                                                name='q11'
                                                id='q11'
                                                type='hidden'
                                                className='border-0'
                                                required
                                                // value={question.score}
                                            />
                                        </MDBValidationItem>
                                        <ScoreSelector
                                            size='md'
                                            id='q11'
                                            range="10"
                                            className="score-rating"
                                            // label="score"
                                            color='#0082c2'
                                        />
                                    </MDBCol>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='feedback-question-row'>
                                <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row'>
                                    <MDBCol size='1' className='flex-shrink-1'>
                                        <p>5.</p>
                                    </MDBCol>
                                    <MDBCol size='5' className='w-30 flex-grow-1 question text-start'>
                                        <p>How likely would you be to recommend this diagnostic to other teams or colleagues? (1 not at all, 10 absolutely!)</p>
                                    </MDBCol>
                                </MDBCol>
                                <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-md-1'>
                                    <MDBCol xs='12' lg='6' className='me-md-3 me-lg-5'>
                                        <MDBValidationItem invalid feedback='invalid' className='border-0' >
                                            <input
                                                name='q12'
                                                id='q12'
                                                type='hidden'
                                                className='border-0'
                                                required
                                                // value={question.score}
                                            />
                                        </MDBValidationItem>
                                        <ScoreSelector
                                            size='md'
                                            id='q12'
                                            range="10"
                                            className="score-rating"
                                            // label="score"
                                            color='#0082c2'
                                        />
                                    </MDBCol>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow className='feedback-question-row'>
                                <MDBCol md='6' className='d-flex justify-content-between row-flex questionnaire-row'>
                                    <MDBCol size='1' className='flex-shrink-1'>
                                        <p>6.</p>
                                    </MDBCol>
                                    <MDBCol size='5' className='w-30 flex-grow-1 question text-start'>
                                        <p>How could we improve the diagnostic survey or reports to make it easier to understand or use?</p>
                                    </MDBCol>
                                </MDBCol>
                                <MDBCol xs='12' md='6' className='d-flex flex-column flex-md-row questionnaire-row ps-md-1'>
                                    <MDBCol xs='12' lg='12' className='me-md-3 me-lg-5 align-items-center justify-content-center'>
                                        <MDBTextArea
                                            label='Anything else'
                                            id='q13'
                                            onChange={handleChange}
                                            onBlur={() => formik.setFieldTouched('q14')}
                                            name='q13'
                                            rows={4}
                                            className="mb-3"
                                        />
                                    </MDBCol>
                                </MDBCol>
                            </MDBRow>


                            <MDBBtn
                                type="submit"
                                className={!(dirty && isValid) ? "formButton disabled-btn mb-5" : "formButton mb-5"}
                                disabled={!(dirty && isValid)}
                            >Submit Feedback</MDBBtn>
                        </div>
                    </Form>
        </FormikProvider>
    );
}

export default CoachFeedbackForm;