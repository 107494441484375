import {
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardImage,
    MDBCardText,
    MDBCardTitle,
    MDBTypography,
    MDBIcon, MDBBtn,
    MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem, MDBDropdownLink
} from "mdb-react-ui-kit";
import React from "react";
import {NavLink} from "react-router-dom";
import authApi from "../api/auth";

function CompanyList(props) {

    const displayClients = (props) => {

        const clients = props.payload;

        return(
            <MDBRow className='row-cols-1 row-cols-md-2 row-cols-xl-3 g-4 my-3'>
                { clients && clients.length > 0 ?
                    clients[0].map((client, index) => {
                        return (
                            <MDBCol key={client.id}>
                                <MDBCard>
                                    <a href={`/companies/${client.id}`} className='image-tag'>
                                        {client.logo ?
                                            <MDBCardImage
                                                fluid
                                                key={index}
                                                src={client.logo}
                                                alt='...'
                                                position='top'
                                                className='company-card-logo'
                                            />
                                            :
                                            <div className='company-image-placeholder'>
                                                <h1>{client.name}</h1>
                                            </div>
                                        }
                                    </a>
                                    <MDBCardBody className="card-body py-3 justify-content-end">
                                        <div className="d-flex flex-column justify-content-between w-100 mt-3">
                                            <MDBCardTitle className="pt-1 pe-2 company">{client.name}</MDBCardTitle>
                                            <MDBCardText>
                                                {client.description}
                                            </MDBCardText>
                                        </div>
                                    </MDBCardBody>
                                </MDBCard>
                            </MDBCol>
                        );

                    })
                    :
                    <MDBTypography>No companies yet.</MDBTypography>
                }
                <MDBCol>
                    <MDBCard>
                        <MDBCardBody className="placeholder company py-5">
                            <MDBCardText className="text-center">
                                <a href='/companies/add' className="stretched-link">
                                    <MDBIcon icon='plus-circle' size='10x' className='position-relative'/><br /><br />
                                    Click to create a company
                                </a>
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        );
    }
    return(
        <>
            {displayClients(props)}
        </>
    )
}

export default CompanyList;


