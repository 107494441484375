import React, {useState, useEffect} from 'react';
import {
    MDBContainer,
    MDBInputGroup,
    // MDBInputGroupText,
    MDBIcon,
    MDBBtn, MDBSwitch
} from 'mdb-react-ui-kit';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

import authApi from "../api/auth"
import {ReactComponent as Logo} from "../assets/img/ccmi-graphic-logo-color.svg";
import md5 from "md5";

const loginSchema = Yup.object().shape({
    email: Yup.string().email().required("Enter your email address"),
    password: Yup.string().required("Enter your password"),
});

const Login = (props) => {

    const initialValues = {
        email: "",
        password: "",
        uuid: props.uuid,
    };
    const md5=require("md5");


    return (
        <div id="intro" className="bg-image shadow-2-strong">
            <div className="mask d-flex align-items-center h-100" style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-5 col-md-8">
                            {/*<MDBContainer>*/}
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={loginSchema}
                                    onSubmit={(values) => {
                                        values.password = md5(values.password);
                                        authApi.loginUser(values);
                                        values.password = "";
                                        //authApi.loginDummyUser();
                                    }}
                                >
                                    {(formik) => {
                                        const { errors, touched, isValid, dirty } = formik;
                                        return (
                                            <Form className="bg-white  rounded-5 shadow-5-strong login-register-form">
                                                <div className="login-register-header">
                                                    <a href='https://clutterbuck-cmi.com/' target='_blank'>
                                                        <Logo style={{height: '5rem', width: '5rem'}}/>
                                                    </a>
                                                    <h1>Welcome back</h1>
                                                    <p className="">Log in to access your PERILL account.</p>
                                                </div>
                                                <div className="modal-divider"></div>
                                                <div className="p-5">
                                                    {/*<MDBInputGroup textBefore={<MDBIcon fas icon="at" />} className="mb-3">*/}
                                                        <Field
                                                            type="text"
                                                            placeholder="Your email address"
                                                            autoComplete="email"
                                                            id="email"
                                                            name="email"
                                                            className={
                                                                errors.email && touched.email ? "form-control mb-3 is-invalid" : "mb-3 form-control"
                                                            }
                                                        />
                                                        <ErrorMessage name="email" component="div" className="error" />
                                                    {/*</MDBInputGroup>*/}
                                                    {/*<MDBInputGroup textBefore={<MDBIcon fas icon="key" />} className="mb-4">*/}
                                                        <Field
                                                            type="password"
                                                            placeholder="Password"
                                                            autoComplete="current-password"
                                                            id="password"
                                                            name="password"
                                                            className={
                                                                errors.password && touched.password ? "form-control is-invalid mb-4" : "form-control mb-4"
                                                            }
                                                        />
                                                        <ErrorMessage name="password" component="div" className="error" />
                                                    {/*</MDBInputGroup>*/}
                                                    <MDBInputGroup className="mb-4">
                                                        <MDBSwitch
                                                            id='remember-me'
                                                            label='Remember me'
                                                        />

                                                    </MDBInputGroup>

                                                    <MDBBtn
                                                        type="submit"
                                                        className={!(dirty && isValid) ? "formButton disabled-btn" : "formButton"}
                                                        disabled={!(dirty && isValid)}
                                                    >Log In</MDBBtn>
                                                    <div className="login-register-footer">
                                                        <a href='/resetpassword/'>Forgot password?</a>
                                                        <p>Don't have a CCMI account? <a href='/register/'>Register here</a>.</p>
                                                        <a href='https://docs.google.com/document/d/1ZDBnQgV3Ahkh8k1OChqrTf3MvYT83XyS4RpFWHFdEoM' target='_blank'>FAQ / Self Help Guide</a>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            {/*</MDBContainer>*/}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
