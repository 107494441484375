import React, {memo, useState, useEffect} from 'react';
import {
    MDBBtn,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader, MDBModalTitle, MDBModalBody, MDBModalFooter,
    MDBTable, MDBTableHead, MDBTableBody,
    MDBCheckbox, MDBRow, MDBCol, MDBContainer,
} from 'mdb-react-ui-kit';
import {useHistory, useLocation} from "react-router-dom";
import companiesApi from '../api/clients';
import RoiReport from "../components/RoiReport";

const ReportGenerator = () => {

    const [progressModal, setProgressModal] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [generateReportType, setGenerateReportType] = useState(null);

    const [companies, setCompanies] = useState({});
    const [reportTeams, setReportTeams] = useState(null);

    const toggleProgressModal = () => setProgressModal(!progressModal);

    const getData = () => {
        try {
            setReportTeams([]);
            let response = companiesApi.getROICompanies();
            response.then(
                response => {
                    if (response.data.companies.length > 0) {
                        setCompanies(response.data.companies);
                        setProgressModal(true);
                    } else {
                        alert("You need at least one completed set of Initial and Endpoint surveys to run an ROI Report.");
                        handleNotReady()
                    }
                }
            )
        } catch(error) {
            console.log("You need at least one completed set of Initial and Endpoint surveys to run an ROI Report.", error);
        }
    }

    const handleGenerateReport = async () => {
        setProgressModal(false);
        setShowReport(true);
    }

    useEffect(() => {
        getData()
    }, [])

    const handleNotReady = () => {
        history.back();
    }

    return (
        <>
            <MDBModal
                show={progressModal}
                nonInvasive={true}
                tabIndex='-1'
            >
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Generate ROI Report</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={handleNotReady}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>Select the Teams you would like to include in the ROI Report</p>
                            <div className="d-flex justify-content-start">
                                <MDBTable striped>
                                    <MDBTableHead>
                                        <tr>
                                            <th scope='col'>Company</th>
                                            <th scope='col'>Status</th>
                                            <th scope='col'>Team</th>
                                            <th scope='col'>Include in report</th>
                                        </tr>
                                    </MDBTableHead>
                                    <MDBTableBody>
                                        {(companies && companies.length > 0) && companies.map((company, cidx) => {

                                             return (
                                                 <tr rowspan={company.teams.length} key={cidx}>
                                                     <th scope='row'>{company.company_name}</th>
                                                     <th scope='row'>{company.company_status === 1 ? "Active" : "Inactive"}</th>
                                                     <td>
                                                     {company.teams.length > 0 && company.teams.map((team, tidx) => {
                                                         return (
                                                             <div key={tidx}>{team.team_name}</div>
                                                        );
                                                     })}
                                                     </td>
                                                     <td>
                                                     {company.teams.length > 0 && company.teams.map((team, tidx) => {
                                                         return (
                                                             <div>
                                                                 <MDBCheckbox
                                                                     name='flexCheck'
                                                                     value={team.team_id}
                                                                     id='flexCheckDefault'
                                                                     // disabled={participant.percent_complete !== 100}
                                                                     // defaultChecked={participant.percent_complete === 100}
                                                                     onChange={async (e) => {
                                                                         if (e.target.checked) {
                                                                             setReportTeams(reportTeams => ([...reportTeams, parseInt(e.target.value)]))
                                                                         } else {
                                                                             setReportTeams(reportTeams => (
                                                                                 reportTeams.filter(team => {
                                                                                     return team != e.target.value;
                                                                                 }))
                                                                             )
                                                                         }
                                                                     }}
                                                                 />
                                                             </div>
                                                         );
                                                     })}
                                                     </td>
                                                 </tr>
                                             )
                                        })
                                        }
                                    </MDBTableBody>
                                </MDBTable>
                            </div>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={handleNotReady}>
                                I'm not ready
                            </MDBBtn>
                            <MDBBtn
                                type="submit"
                                disabled={!reportTeams || reportTeams.length === 0}
                                onClick={() => {
                                    handleGenerateReport();
                                }}
                            >Generate {generateReportType===2 ? "Presentation" : "Report"}</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>

            {showReport ?
            <RoiReport teams={{"teams": reportTeams}}/>
            :
            null}
        </>
    );
}

export default ReportGenerator;
