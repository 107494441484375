import React from 'react';
import {MDBContainer, MDBRow, MDBCol} from 'mdb-react-ui-kit';

import PageHeader from "../components/PageHeader";
import DashboardCompanies from "../components/DashboardCompanies";
import DashboardCompaniesDatatable from "../components/DashboardCompaniesDatatable"
import NavBar from "../components/navigation/NavBar";

const Dashboard = () => {

    return (
            <div>
                <NavBar context="dashboard" />
                <PageHeader title="Dashboard | Your Companies" />
                <MDBContainer className="px-5">
                    {/*<MDBRow className='row-cols-md-3 row-flex'>*/}
                    {/*    <MDBCol size='md' className='col-md-4 col-sm-6 col-xs-12'>*/}
                    {/*        <DashboardCompaniesCount />*/}
                    {/*    </MDBCol>*/}
                    {/*    <MDBCol size='md' className='col-md-4 col-sm-6 col-xs-12'>*/}
                    {/*        <DashboardTeamsCount />*/}
                    {/*    </MDBCol>*/}
                    {/*    <MDBCol size='md' className='col-md-4 col-sm-6 col-xs-12'>*/}
                    {/*        <DashboardSurveys />*/}
                    {/*    </MDBCol>*/}
                    {/*</MDBRow>*/}
                    <MDBRow className='row-cols-md-3 row-flex'>
                        <MDBCol size='md' className='col-md-12 col-sm-12 col-xs-12'>
                            <DashboardCompanies />
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>
            </div>
    );
}

export default Dashboard;
