import React from 'react';

import PageHeader from "../components/PageHeader";
import NavBar from "../components/navigation/NavBar";

const Settings = () => {

    return (
        <div className='text-left'>
            <NavBar/>
            <PageHeader title="Settings" />
        </div>
    );
}

export default Settings;
