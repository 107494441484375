import client from "./client";

const endpoint = '/participants';
const p_survey_endpoint = '/survey_participants';


const getParticipants = () => {
    return client.get(endpoint)
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            }
        });
}

const getTeamParticipants = (team_id) => {
    return client.get(endpoint + "?team_id=" + team_id)
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            }
        });
};

const getSurveyParticipants = (survey_id) => {
    return client.get(p_survey_endpoint + "?survey_id=" + survey_id)
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            }
        });
};

const getParticipant = (id) => {
    return client.get(endpoint + '/' + id)
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            }
        });
}

const deleteParticipant = (id) => client.delete(endpoint + '/' + id)
    .catch(function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            return error.response.data.description;
        } else if (error.request) {
            // The request was made but no response was received
        } else {
            // Something happened in setting up the request that triggered an Error
        }
    });

const addParticipant = (participant) => {
    return client.post(endpoint, JSON.stringify(participant))
        .then((response) => {

        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            } else if (error.response.status == 402) {
                window.location = '/subscribe';
            }
        });
};

const updateParticipant = (id, participant) => {
    console.log(participant);
    return client.put(endpoint + '/' + id, JSON.stringify(participant))
        .then((response) => {

        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            } else if (error.response.status == 402) {
                window.location = '/subscribe';
            }
        });
};


export default {
    addParticipant,
    getParticipants,
    getTeamParticipants,
    getSurveyParticipants,
    deleteParticipant,
    getParticipant,
    updateParticipant
};
