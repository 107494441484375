import React, {useEffect, useState} from 'react';
import {
    MDBBtn,
    MDBContainer,
    MDBIcon,
    MDBTooltip,
    MDBDropdown,
    MDBDropdownMenu,
    MDBDropdownToggle,
    MDBDropdownItem,
    MDBModal,
    MDBModalDialog,
    MDBModalContent,
    MDBModalHeader,
    MDBModalTitle,
    MDBModalBody,
    MDBModalFooter,
} from 'mdb-react-ui-kit';
import {
    GroupingState,
    IntegratedGrouping,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    Table,
    TableHeaderRow,
    TableGroupRow,
    TableColumnVisibility,
} from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import '@icon/open-iconic/open-iconic.css';
import {useHistory, useLocation} from "react-router-dom";

import clientsApi from '../api/clients'
import CompanyList from "../components/CompanyList";
import PageHeader from "../components/PageHeader";
import NavBar from "../components/navigation/NavBar";
import _ from "lodash";
import colors from "../config/colors";
import dashboardApi from "../api/dashboard";
import dummyBulk from "../assets/json/dummybulk.json";

const Companies = () => {

    const user = JSON.parse(localStorage.getItem('user'));

    const [companyActivationID, setCompanyActivationID] = useState(0);
    const [inactivateModal, setInactivateModal] = useState(false);
    const toggleInactivateModal = () => setInactivateModal(!inactivateModal);


    const [companies, setCompanies] = useState({
        columns: [
            { title: 'ID', name: 'id' },
            { title: 'Company', name: 'logo', sort: true },
            { title: 'Name', name: 'name', sort: true },
            { title: 'Description', name: 'description'},
            { title: 'Status', name: 'status', sort: true },
            { title: 'Teams', name: 'teams', sort: false },
            { title: 'Participants', name: 'participants', sort: false },
            { title: 'Surveys', name: 'surveys', sort: false },
        ],
        rows: [],
    });

    const [tableColumnExtensions] = useState([
        { columnName: 'id', wordWrapEnabled: true, width: 70 },
        { columnName: 'name', wordWrapEnabled: true, width: "auto" },
        { columnName: 'logo', wordWrapEnabled: true, width: 120 },
        { columnName: 'description', wordWrapEnabled: true, width: "auto" },
        { columnName: 'teams', wordWrapEnabled: true, width: "auto" },
        { columnName: 'participants', wordWrapEnabled: true, width: "auto" },
        { columnName: 'surveys', wordWrapEnabled: true, width: "auto" },
    ]);
    const [defaultHiddenColumnNames] = useState(['id']);

    const [isLoading, setIsLoading] = useState(false);

    // history stack
    const history = useHistory()
    const queryPage = useLocation().search.match(/page=([0-9]+)/, '')
    const currentPage = Number(queryPage && queryPage[1] ? queryPage[1] : 1)
    const [page, setPage] = useState(currentPage)


    useEffect(() => {
        loadClients();
    }, []);

    const loadClients = async () => {
        const response = await clientsApi.getCompanies(user.user.id);
        let companiesData = response.data.companies;

        _.each(companiesData, function(company) {
            var max_id = 0;
            var survey_ids = [];
            _.each(company.teams, function(team) {
                _.each(team.surveys, function(survey) {
                    survey_ids.push(survey.id);
                });
            });
            max_id = _.max(survey_ids);
            company.max_survey_id = max_id;
        });

        let sorted = _.sortBy(companiesData, function(company) {
            return company.max_survey_id;
        }).reverse();

        console.log(sorted);

        _.each(sorted, (company, companykey) => {

            let companyTeams = [];
            _.each(company.teams, (team, key) => {
                companyTeams.push({
                    "id": team.id,
                    "team_name": team.name,
                    "survey_count": team.surveys.length,
                    "participant_count": team.team_member_count + team.team_leader_count + team.stakeholder_count
                });
            });

            let teamsCol = [];
            let surveysCol = [];
            let participantsCol = [];

            _.each(companyTeams, (team, key) => {
                teamsCol.push(<p>{team.team_name}</p>);
                surveysCol.push(<p>{team.survey_count}</p>);
                participantsCol.push(<p>{team.participant_count}</p>);
            });

            setCompanies((companies) => ({
                columns: companies.columns,
                rows: [
                    ...companies.rows,
                    {
                        id: company.id,
                        name: company.status===1 ? company.name : company.name + ' (Inactive)',
                        logo: company.logo,
                        description: company.description,
                        status: company.status,
                        teams: teamsCol,
                        surveys: surveysCol,
                        participants: participantsCol,
                    }]
                })
            );
        });
    }

    const generateKey = (pre) => {
        return `${ pre }_${ new Date().getTime() }`;
    }

    const TableRow = ({ row, ...restProps }) => (
        <Table.Row
            {...restProps}
            key={generateKey(row.id)}
            // eslint-disable-next-line no-alert
            onClick={() => history.push(`/companies/${row.id}`)}
            className={row.status===0 ? "inactive" : undefined}
        />
    );

    const TableGroupHeaderRow = ({ row, ...restProps }) => (
        <Table.Row
            {...restProps}
            key={generateKey(row.id)}
            // eslint-disable-next-line no-alert
            // onClick={() => history.push(`/companies/${row.id}`)}
            onClick={() => console.log(row)}
            className={row.status===0 ? "inactive" : undefined}
        />
    );

    const NameLogoCell = ({ value, style, ...restProps }) => (
        <Table.Cell
            key={generateKey(value)}
            {...restProps}
        >
            {value ?
                <img
                    src={value}
                    alt='...'
                    className='img-thumbnail'
                    position='top'
                />
                :
                <MDBIcon far icon='building' size='1x' className={restProps.status === 1 ? "img-thumbnail company p-3" : "img-thumbnail grey p-3"} />
            }
        </Table.Cell>
    );

    const ActiveCell = ({ value, style, ...restProps }) => (
        <Table.Cell
            key={generateKey(value)}
            {...restProps}
        >
            {/*<MDBDropdown group>*/}
            {/*    <MDBDropdownToggle*/}
            {/*        color={value === 1 ? "success" : "light"}*/}
            {/*        onClick={(e) => {*/}
            {/*            e.preventDefault();*/}
            {/*        }}*/}
            {/*    >{value === 1 ? "Active" : "Inactive"}</MDBDropdownToggle>*/}
            {/*    <MDBDropdownMenu>*/}
            {/*        <MDBDropdownItem link onClick={(e) => {*/}
            {/*            e.preventDefault();*/}
            {/*            {value === 0 &&*/}
            {/*            toggleInactivateModal()*/}
            {/*            }*/}
            {/*        }}>Active</MDBDropdownItem>*/}
            {/*        <MDBDropdownItem link onClick={(e) => {*/}
            {/*            e.preventDefault();*/}
            {/*            {value === 1 && toggleInactivateModal()}*/}
            {/*        }}>Inactive</MDBDropdownItem>*/}
            {/*    </MDBDropdownMenu>*/}
            {/*</MDBDropdown>*/}
            {value === 1 ? "Active" : "Inactive"}
        </Table.Cell>
    );


    const Cell = (props) => {
        const { column } = props;
        if (column.name === 'logo') {
            return <NameLogoCell key={generateKey(column.name)} {...props} />;
        } else if (column.name === 'status') {
            return <ActiveCell key={generateKey(column.name)} {...props} />;
        }
        return <Table.Cell key={generateKey(column.name)} {...props} />;
    };

    return (
        <>
            <div className='text-left'>
                <NavBar context="companies" />
                <PageHeader title='Companies' />
                <MDBContainer className="px-5">
                    <div className='company'>
                        <Grid
                            rows={companies.rows}
                            columns={companies.columns}
                        >
                            {/*<GroupingState*/}
                            {/*    grouping={[*/}
                            {/*        { columnName: 'name' },*/}
                            {/*        // { columnName: 'team_name' },*/}
                            {/*    ]}*/}
                            {/*/>*/}
                            {/*<IntegratedGrouping />*/}
                            <Table
                                rowComponent={TableRow}
                                columnExtensions={tableColumnExtensions}
                                cellComponent={Cell}
                            />
                            <TableHeaderRow />
                            <TableColumnVisibility
                                defaultHiddenColumnNames={defaultHiddenColumnNames}
                            />
                            {/*<TableGroupRow*/}
                            {/*    showColumnsWhenGrouped={false}*/}
                            {/*    rowComponent={TableGroupHeaderRow}*/}
                            {/*    // className='team'*/}
                            {/*/>*/}
                        </Grid>
                    </div>
                    <div className="d-flex justify-content-end add-button">
                        <MDBTooltip tag='span' wrapperClass='d-inline-block px-2' placement='left' title='Create company'>
                            <MDBBtn floating tag='a' href='/companies/add'>
                                <MDBIcon fas icon="plus company" />
                            </MDBBtn>
                        </MDBTooltip>
                    </div>
                </MDBContainer>
            </div>

            <MDBModal show={inactivateModal} setShow={setInactivateModal} tabIndex='-1'>
                <MDBModalDialog>
                    <MDBModalContent>
                        <MDBModalHeader>
                            <MDBModalTitle>Company: {companyActivationID}</MDBModalTitle>
                            <MDBBtn className='btn-close' color='none' onClick={toggleInactivateModal}></MDBBtn>
                        </MDBModalHeader>
                        <MDBModalBody>
                            <p>By changing the status of this company from Active to Inactive, you will no longer be able to
                                view the information about this company, including team, participant, survey and report details.</p>
                            <p>You will see the company in the greyed out section at the bottom of your dashboard, but you will
                                need to reactivate the status to active to see all of the company information.</p>
                            <p>Do you wish to continue?</p>
                        </MDBModalBody>
                        <MDBModalFooter>
                            <MDBBtn color='secondary' onClick={toggleInactivateModal}>
                                I'm not ready
                            </MDBBtn>
                            <MDBBtn
                                type="submit"
                                onClick={() => {
                                    console.log("foo")
                                }}
                            >I'm sure, make this company inactive</MDBBtn>
                        </MDBModalFooter>
                    </MDBModalContent>
                </MDBModalDialog>
            </MDBModal>
        </>
    );
}

export default Companies;
