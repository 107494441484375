import React from 'react'
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from 'mdb-react-ui-kit';

import authApi from "../api/auth";
import {ReactComponent as Logo} from "../assets/img/ccmi-graphic-logo-color.svg";
import {Redirect} from "react-router-dom";

const user = JSON.parse(localStorage.getItem('user'));
console.log(user);
const HeaderDropdown = () => {
    const logOutUser = () => (
        authApi.logOutUser(),
            <Redirect to='/login' />
    )

    return (
        user.user.role && user.user.role === 4 ?
                <MDBDropdown className='user-dropdown'>
                    <MDBDropdownToggle className='dropdown-toggle' tag='a' >
                        <img src="/img/avatar.png" width="58" height="58" className="avatar header" />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <MDBDropdownItem link href="/profile">
                            Edit Profile
                        </MDBDropdownItem>
                        <MDBDropdownItem link href="/surveys/roireport">
                            Run ROI Report
                        </MDBDropdownItem>
                        <MDBDropdownItem divider />
                        <MDBDropdownItem header>Admin Portal</MDBDropdownItem>
                        <MDBDropdownItem link href="/admin/subscriptions">
                            Subscribers
                        </MDBDropdownItem>
                        <MDBDropdownItem link href="/admin/data-dumps">
                            Data Dumps
                        </MDBDropdownItem>
                        <MDBDropdownItem link href="/admin/data-tools">
                            Data Tools
                        </MDBDropdownItem>
                        <MDBDropdownItem divider/>
                        <MDBDropdownItem link href="/subscribe">
                            Subscribe Flow
                        </MDBDropdownItem>
                        <MDBDropdownItem link onClick={logOutUser}>
                            Log out
                        </MDBDropdownItem>
                    </MDBDropdownMenu>
                </MDBDropdown>
            :
                <MDBDropdown className='user-dropdown'>
                    <MDBDropdownToggle className='dropdown-toggle' tag='a' >
                        {/*<Logo style={{ height: '4rem' }} />*/}
                        <img src="/img/avatar.png" width="58" height="58" className="avatar header" />
                    </MDBDropdownToggle>
                    <MDBDropdownMenu>
                        <MDBDropdownItem link href="/profile">
                            Edit Profile
                        </MDBDropdownItem>
                        <MDBDropdownItem link href="/surveys/roireport">
                            Run ROI Report
                        </MDBDropdownItem>
                        <MDBDropdownItem divider />
                        <MDBDropdownItem link onClick={logOutUser}>
                            Log out
                        </MDBDropdownItem>
                    </MDBDropdownMenu>
                </MDBDropdown>

    )
}

export default HeaderDropdown
