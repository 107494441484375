import client from "./client";

const endpoint = '/users';

const getUsers = (offset) => {
    return client.get(endpoint)
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            }
        });
}

const getUser = () => {
    return client.get(endpoint)
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            }
        });
}


const addUser = (user) => {
    return client.post(endpoint, JSON.stringify(user))
        .then((response) => {

        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            } else if (error.response.status == 402) {
                window.location = '/subscribe';
            }
        });
};

const updateUser = (user) => {
    return client.put(endpoint, JSON.stringify(user))
        .then((response) => {

        }, (error) => {
            if ((error.response.status == 400)) {
                alert("Integrity error: A Perill user with this email already exists.");
            } else if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            } else if (error.response.status == 402) {
                window.location = '/subscribe';
            }
        });
};

export default {
    addUser,
    getUsers,
    getUser,
    updateUser,
};
