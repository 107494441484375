import client from "./client";

let endpoint = '/teams';

const getTeams = (company_id) => {
    if (company_id) endpoint += '?company_id=' + company_id;
    return client.get(endpoint)
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            }
        });
};

const getTeam = (team_id) => {
    return client.get(endpoint + '/' + team_id)
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            }
        });
}

const deleteTeam = (team_id) => client.delete(endpoint + '/' + team_id)
    .catch(function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            return error.response.data.description;
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
        } else {
            // Something happened in setting up the request that triggered an Error
        }
    });

const addTeam = (team) => {
    return client.post(endpoint, JSON.stringify(team))
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            } else if (error.response.status == 402) {
                window.location = '/subscribe';
            }
        });
};

const updateTeam = (team_id, team) => {
    return client.put(endpoint + '/' + team_id, JSON.stringify(team))
        .then((response) => {

        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            } else if (error.response.status == 402) {
                window.location = '/subscribe';
            }
        });
};

const getTeamsCount = () => {
    return client.get(endpoint  + '/count');
}

export default {
    getTeams,
    getTeam,
    deleteTeam,
    addTeam,
    updateTeam,
    getTeamsCount
};
