import React from 'react';

const Companies = React.lazy(() => import('./pages/Companies'));
const AddCompany = React.lazy(() => import('./pages/AddCompany'));
const Company = React.lazy(() => import('./pages/Company'));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Participants = React.lazy(() => import('./pages/Participants'));
const AddParticipant = React.lazy(() => import('./pages/AddParticipant'));
const Participant = React.lazy(() => import('./pages/Participant'));
const Profile = React.lazy(() => import('./pages/Profile'));
const Reports = React.lazy(() => import('./pages/Reports'));
const Report = React.lazy(() => import('./pages/Report'));
const TeamLeaderOverviewReport = React.lazy(() => import('./pages/TeamLeaderOverviewReport'));
const TeamPresentation = React.lazy(() => import('./pages/TeamPresentation'));
const StakeholderReport = React.lazy(() => import('./pages/StakeholderReport'));
const ComparisonReport = React.lazy(() => import('./pages/ComparisonReport'));
const ROIReportGenerator = React.lazy(() => import('./pages/RoiReportGenerator'));
const Settings = React.lazy(() => import('./pages/Settings'));
const Surveys = React.lazy(() => import('./pages/Surveys'));
const AddSurvey = React.lazy(() => import('./pages/AddSurvey'));
const Survey = React.lazy(() => import('./pages/Survey'));
const Teams = React.lazy(() => import('./pages/Teams'));
const AddTeam = React.lazy(() => import('./pages/AddTeam'));
const Team = React.lazy(() => import('./pages/Team'));
const ResetPassword = React.lazy(() => import('./pages/ResetPassword'));
const Reset = React.lazy(() => import('./pages/Reset'));
const Privacy = React.lazy(() => import('./pages/Privacy'));
const FAQs = React.lazy(() => import('./pages/FAQs'));
// admin
const AdminDashboard = React.lazy(() => import('./pages/admin/Dashboard'));
const AdminSubscriptions = React.lazy(() => import('./pages/admin/Subscriptions'));
const AdminSubscription = React.lazy(() => import('./pages/admin/Subscription'));
const AdminDataDumps = React.lazy(() => import('./pages/admin/DataDumps'));
const AdminDataTools = React.lazy(() => import('./pages/admin/DataTools'));
const Subscribe = React.lazy(() => import('./pages/Subscribe'));
const PaymentComplete = React.lazy(() => import('./pages/PaymentComplete'));

const routes = [
    { path: '/dashboard', name: 'Home', component: Dashboard, exact: true },
    { path: '/companies', name: 'companies', component: Companies, exact: true },
    { path: '/companies/add', name: 'Add Company', component: AddCompany, exact: true },
    { path: '/companies/:id', name: 'Edit Company', component: Company },
    { path: '/dashboard', name: 'Dashboard', component: Dashboard, exact: true },
    { path: '/participants', name: 'Participants', component: Participants, exact: true },
    { path: '/participants/add', name: 'Add Participant', component: AddParticipant, exact: true },
    { path: '/participants/:id', name: 'Edit Participant', component: Participant },
    { path: '/profile', name: 'Profile', component: Profile, exact: true },
    { path: '/reports', name: 'Reports', component: Reports, exact: true },
    { path: '/reports/:id', name: 'Report', component: Report, exact: true },
    { path: '/surveys/reports', name: 'Reports', component: Reports, exact: true },
    // { path: '/surveys/report/:id', name: 'Report', component: Report, exact: true },
    { path: '/surveys/report', name: 'Report', component: Report, exact: true },
    // { path: '/surveys/teamleaderoverviewreport/:id', name: 'TeamLeaderOverviewReport', component: TeamLeaderOverviewReport, exact: true },
    { path: '/surveys/teamleaderoverviewreport', name: 'TeamLeaderOverviewReport', component: TeamLeaderOverviewReport, exact: true },
    // { path: '/surveys/teampresentation/:id', name: 'Team Presentation', component: TeamPresentation, exact: true },
    { path: '/surveys/teampresentation', name: 'Team Presentation', component: TeamPresentation, exact: true },
    { path: '/surveys/stakeholderreport', name: 'Stakeholder Report', component: StakeholderReport, exact: true },
    { path: '/surveys/comparisonreport', name: 'Comparison Report', component: ComparisonReport, exact: true },
    { path: '/surveys/roireport', name: 'Coach ROI Report', component: ROIReportGenerator, exact: true },

    { path: '/settings', name: 'Settings', component: Settings, exact: true },
    { path: '/surveys', name: 'Surveys', component: Surveys, exact: true },
    { path: '/surveys/add', name: 'Add Survey', component: AddSurvey, exact: true },
    { path: '/surveys/:id', name: 'Edit Survey', component: Survey },
    { path: '/teams', name: 'Teams', component: Teams, exact: true },
    { path: '/teams/add', name: 'Add Team', component: AddTeam, exact: true },
    { path: '/teams/:id', name: 'Edit Client', component: Team },
    { path: '/resetpassword', name: 'Reset Password', component: ResetPassword },
    { path: '/reset', name: 'Change your Password', component: Reset },
    { path: '/privacy', name: 'Privacy Policy', component: Privacy },
    { path: '/privacy-policy', name: 'Privacy Policy', component: Privacy },
    { path: '/faqs', name: 'FAQs', component: FAQs },
    { path: '/help', name: 'Help / FAQs', component: FAQs },

    { path: '/admin/dashboard', name: 'Admin Portal', component: AdminDashboard, exact: true },
    { path: '/admin/subscriptions', name: 'Subscriptions', component: AdminSubscriptions, exact: true },
    { path: '/admin/subscription/:id', name: 'Edit Subscription', component: AdminSubscription, exact: false },
    { path: '/admin/data-dumps', name: 'Data Dumps', component: AdminDataDumps, exact: true },
    { path: '/admin/data-tools', name: 'Data Tools', component: AdminDataTools, exact: true },

    { path: '/subscribe', name: 'Subscribe', component: Subscribe, exact: true },
    { path: '/payment-complete', name: 'Payment Complete', component: PaymentComplete, exact: true }
];

export default routes;
