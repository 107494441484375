import client from "./client";

const endpoint = '/surveys';
const emailEndpoint = '/send_invites';
const progressEndpoint = '/survey_progress/';
const resendEndpoint = '/resend_invite';

const getSurveys = () => {
    return client.get(endpoint)
        .then((response) => {
            console.log();
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            }
        });
}

const getTeamSurveys = (team_id) => {
    return client.get(endpoint + "?team_id=" + team_id)
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            }
        });

};

const getSurvey = (survey_id) => {
    return client.get(endpoint  + '/' + survey_id)
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            }
        });
}

const deleteSurvey = (survey_id) => client.delete(endpoint + '/' + survey_id);

const addSurvey = (survey) => {
    return client.post(endpoint, JSON.stringify(survey))
        .then((response) => {

        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            } else if (error.response.status == 402) {
                window.location = '/subscribe';
            }
        });
};

const updateSurvey = (id, survey) => {
    return client.put(endpoint + '/' + id, JSON.stringify(survey))
        .then((response) => {

        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            }
        });
};

const getSurveysCount = () => {
    return client.get(endpoint  + '/count')
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            }
        });
}

const emailSurvey = (payload) => {
    return client.post(emailEndpoint, JSON.stringify(payload))
        .then((response) => {
            return response;
        }, (error) => {
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            } else if (error.response.status == 402) {
                window.location = '/subscribe';
            }
        });
}

const resendSurvey = (payload) => {
    // console.log(payload);
    // console.log(JSON.stringify(payload));
    return client.post(resendEndpoint, JSON.stringify(payload))
        .then((response) => {
            return response;
        }, (error) => {
            console.log(error)
            if ((error.response.status == 401) || (error.response.status == 403)) {
                //redirect to login
                alert("Please log in again.")
                window.location = '/login';
            } else if (error.response.status == 402) {
                window.location = '/subscribe';
            }
        });
}

const getSurveyProgress = (survey_id) => client.get(progressEndpoint + survey_id);

export default {
    addSurvey,
    getSurveys,
    getTeamSurveys,
    deleteSurvey,
    getSurvey,
    updateSurvey,
    getSurveysCount,
    emailSurvey,
    getSurveyProgress,
    resendSurvey
};
