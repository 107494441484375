export default {
  clBlue: "#0082ca",
  clRed: "#c11b20",
  clYellow: "#ffdd00",
  clOrange: "#f08019",
  clGreen: "#93d500",
  clPurple: "#773dbd",
  clPink: "#f478c4",
  clGrey: "#6f7271",
  pageBackground: "#FBFBFB",
  white: "#FFF",
  lightGrey: "#D8D8D8",
  p1: '#007fc1',
  p2: '#cd1423',
  p3: '#f6a000',
  p4: '#9cbf1d',
  p5: '#e07aad',
  p6: '#643c8c',
  shadowColor: "rgba(0, 0, 0, 0.16)",
  p3RadarBg: "rgba(246, 160, 0, 0.5)",
  p3RadarBorder: "rgba(246, 160, 0, 0.7)",
  p3Bar: "rgba(246, 160, 0, 0.8)",
  p1RadarBg: "rgba(0, 127, 193, 0.5)",
  p1RadarBorder: "rgba(0, 127, 193, 0.7)",
  p1Bar: "rgba(0, 127, 193, 0.8)",
  p2RadarBg: "rgba(205, 20, 35, 0.5)",
  p2RadarBorder: "rgba(205, 20, 35, 0.7)",
  p2Bar: "rgba(205, 20, 35, 0.8)",
  p4RadarBg: "rgba(156, 191, 29, 0.5)",
  p4RadarBorder: "rgba(156, 191, 29, 0.7)",
  p4Bar: "rgba(156, 191, 29, 0.8)",
  p5RadarBg: "rgba(224, 122, 173, 0.5)",
  p5RadarBorder: "rgba(224, 122, 173, 0.7)",
  p5Bar: "rgba(224, 122, 173, 0.8)",
  p6RadarBg: "rgba(100, 60, 140, 0.5)",
  p6RadarBorder: "rgba(100, 60, 140, 0.7)",
  p6Bar: "rgba(100, 60, 140, 0.8)",
  bronze: "rgba(205, 127,50, 1)",
  silver: "rgba(192, 192, 192, 1)",
  gold: "rgba(255,215, 0, 1)",
  bronzeBg: "rgba(205, 127,50, 0.4)",
  silverBg: "rgba(192, 192, 192, 0.4)",
  goldBg: "rgba(255,215, 0, 0.4)",
};
